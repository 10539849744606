import {
  Badge,
  Button,
  Card,
  DatePicker,
  Drawer,
  Dropdown,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Tag,
  Tooltip,
  message,
} from 'antd';

import Text from 'antd/es/typography/Text';
import {
  CheckCircleOutlined,
  CloseOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  NodeIndexOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Droppable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { api } from '../../../../../api';
import { useBoardStore } from '../../../../../state/boardState';
import { useProjectStore } from '../../../../../state/projectState';
import CreateTaskModal from '../../../../../modals/createTaskModal';
import EditColumnModal from './EditCoulmn';
import TaskDetailsModal2 from '../../../../../modals/taskModal';
import TaskCard from './Task';
import { useUserStore } from '@/state/userState';
import { useMyTheme } from '@/hooks/useTheme';
import { useThemeModeStore } from '@/state/themeState';
import TaskDetailsMain from '@/components/TaskDetailsMain';
import { useOptionStore } from '@/state/options';
import Automations from './EditCoulmn/automations';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import dayjs from 'dayjs';

export const Column = ({
  column,
  assigneeFilter,
  priorityFilter,
  filterType,
  searchTerm,
  refetch,
  realTime,
  width,
  handle,
}: {
  column: any;
  assigneeFilter: any;
  priorityFilter: any;
  filterType: any;
  searchTerm: any;
  refetch: any;
  realTime: boolean;
  width: string;
  handle: any;
}) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [defaultColumnId, setDefaultColumnId] = useState('');

  const [showAutomations, setShowAutomations] = useState(false);

  const [showUpdateColumn, setShowUpdateColumn] = useState(false);

  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false);

  const { projectKey, boardKey, taskId } = useParams();

  const [showQuickAddTask, setShowQuickAddTask] = useState(false);
  const [quickAddTask, setQuickAddTask] = useState<{
    label: string;
    assigneeId: string | null;
    taskTypeId: string;
    priorityId: string;
    dueDate: string | null;
  }>({
    label: '',
    assigneeId: null,
    taskTypeId: 'ttkefwc4qtaaogcxrkyu98x0',
    priorityId: 'tdkcw9in2xgbq1lrpd4phsmk',
    dueDate: null,
  });

  const userStore = useUserStore();

  const optionStore = useOptionStore();

  useEffect(() => {
    if (projectKey && boardKey && taskId) {
      column.items.forEach((item: any) => {
        if (item.key === taskId) {
          navigate(`/project/${projectKey}/board/${boardKey}/task/${taskId}`);
          if (optionStore.options.openTaskInDrawer) {
            optionStore.setTaskOpen(true);
          }
          setShowTaskDetailsModal(true);
        }
      });
    }
  }, [taskId]);


  const filteredTasks = useMemo(() => {
    return column.items
      .sort((a: any, b: any) => a?.orderKey?.localeCompare(b.orderKey))
      .filter((i: any) => {
        if (assigneeFilter.length > 0 || priorityFilter.length > 0) {
          return (
            assigneeFilter.includes(i.assigneeId) ||
            priorityFilter.includes(i.priorityId)
          );
        }
        return true;
      })
      .filter((i: any) => {
        if (filterType === 'all') return true;
        return i.taskTypeId === filterType;
      })
      .filter((i: any) => {
        if (searchTerm === '') return true;
        return (
          i.label.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
          i.key.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
      });
  }, [column.items, assigneeFilter, priorityFilter, filterType, searchTerm]);


  const themeStore = useThemeModeStore();

  return (
    <div key={column.id}>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
        >
          <div
          >
            <Text
              style={{
                overflowX: 'scroll'
              }}
              strong
              {...(userStore.user?.isAdmin || userStore.user?.isManager
                ? handle
                : {})}
            >
              {column.label}
            </Text>
          </div>
          <Badge
            color={(() => {
              if (column.type === 'backlog') return 'blue';
              if (column.type === 'todo') return 'orange';
              if (column.type === 'in_progress') return 'purple';
              if (column.type === 'done') return 'green';
            })()}
            style={{
              color: 'white',
            }}
            count={
              filteredTasks.length
            }
            showZero
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.3rem',
          }}
        >
          {column.hasAutomation && (
            <Tooltip title='Has automation rules'>
              <Popover
                title=''
                trigger={['click']}
                content={
                  <Automations columnId={column.id} columnType={column.type} showOnlyAutomations={true} />
                }
              >

                <Tag
                  color='geekblue-inverse'
                  bordered={false}
                >
                  A
                </Tag>



              </Popover>
            </Tooltip>
          )}
          <Tooltip
            title='Create task with more details'
          >
            <Button
              onClick={async () => {
                setCreateTaskModalOpen(true);
                setDefaultColumnId(column.id);
              }}
              type='link'
              icon={<PlusOutlined />}
            />

          </Tooltip>
          {(userStore.user?.isAdmin || userStore.user?.isManager) && (
            <Dropdown
              menu={{
                onClick: (e) => {
                  e.domEvent.stopPropagation();
                },
                items: [
                  {
                    label: (
                      <Text>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.5rem',
                          }}
                        >
                          <SettingOutlined />
                          Edit
                        </div>
                      </Text>
                    ),
                    onClick: async () => {
                      setShowAutomations(false);
                      setShowUpdateColumn(true);
                    },
                    key: '1',
                  },
                  {
                    type: 'divider',
                  },
                  column.type === 'done'
                    ? {
                      label: (
                        <Popconfirm
                          title='Are you sure?'
                          onConfirm={async () => {
                            try {
                              await api.put(
                                `/api/columns/${column.id}/complete`
                              );

                              // await refetch();
                              messageApi.success('Tasks Completed!');
                            } catch (error) {
                              console.log(error, ':(');
                            }
                          }}
                        >
                          <Text
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: '0.5rem',
                              }}
                            >
                              <CheckCircleOutlined
                                style={{ color: 'green' }}
                              />
                              Complete Tasks
                            </div>
                          </Text>
                        </Popconfirm>
                      ),
                      key: '4',
                      onClick: async () => { },
                    }
                    : {
                      type: 'group',
                    },
                  {
                    label: (
                      <Popconfirm
                        title='Are you sure?'
                        onConfirm={async () => {
                          try {
                            await api.delete(`/api/columns/${column.id}`);

                            // await refetch();
                            messageApi.success('Column Deleted!');
                          } catch (error) {
                            console.log(error, ':(');
                          }
                        }}
                      >
                        <Text
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              gap: '0.5rem',
                            }}
                          >
                            <DeleteOutlined />
                            Delete
                          </div>
                        </Text>
                      </Popconfirm>
                    ),
                    danger: true,
                    key: '2',
                    onClick: async () => { },
                  }
                  ,
                ],
              }}
              trigger={['click']}
            >
              <Button
                onClick={(e) => e.stopPropagation()}
                type='link'
                size='small'
              >
                <SettingOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      </div>

      <Droppable droppableId={column.id}
      // mode='virtual'
      >
        {(provided, snapshot) => (
          <div
            className={`column ${snapshot.isDraggingOver ? 'draggingOver' : ''
              }`}
            ref={provided.innerRef}
            style={{
              background: snapshot.isDraggingOver
                ? column.type === 'done'
                  ? 'darkseagreen'
                  : undefined
                : undefined,
              width,
              minWidth: 180,
              maxWidth: 340,
              padding: 0,
              border: `1px solid ${themeStore.theme === 'dark' ? '#222' : 'lightgray'
                }`,
            }}
            {...provided.droppableProps}
          >
            <QuickAddTask columnId={column.id} projectKey={projectKey}
              createTaskAt='top'
            />
            {filteredTasks.map((item: any, index: number) => (
              <TaskCard
                item={item}
                index={index}
                key={item.id}
                setShowTaskDetailsModal={setShowTaskDetailsModal}
                refetch={refetch}
                messageApi={messageApi}
                column={column}
              />
            ))}
            {
              filteredTasks.length > 0 ? (
                <QuickAddTask
                  columnId={column.id}
                  projectKey={projectKey}
                  createTaskAt='bottom'
                />

              ) : (<></>)
            }

            {provided.placeholder}
          </div>
        )
        }
      </Droppable >
      {
        createTaskModalOpen && (
          <CreateTaskModal
            setShowNewTaskForm={setCreateTaskModalOpen}
            showNewTaskForm={createTaskModalOpen}
            defaultColumnId={defaultColumnId}
            onCreateTask={async () => {
              await refetch();
              setDefaultColumnId('');
              messageApi.success('Task Created');
            }}
          />
        )
      }
      {
        showTaskDetailsModal &&
        taskId &&
        optionStore.options.openTaskInDrawer && (
          <Drawer
            open={showTaskDetailsModal}
            width={'60vw'}
            mask={false}
            // getContainer={false}
            onClose={() => {
              setShowTaskDetailsModal(false);
              optionStore.setTaskOpen(false);
              navigate(`/project/${projectKey}/board/${boardKey}`);
            }}
          >
            <TaskDetailsMain
              taskKey={taskId}
              onUpdateTask={async () => {
                if (!realTime) {
                  await refetch();
                }
              }}
            />
          </Drawer>
        )
      }
      {
        showTaskDetailsModal &&
        taskId &&
        !optionStore.options.openTaskInDrawer && (
          <TaskDetailsModal2
            taskKey={taskId ?? ''}
            showTaskDetailsModal={showTaskDetailsModal}
            setShowTaskDetailsModal={setShowTaskDetailsModal}
            onUpdateTask={async () => {
              if (!realTime) {
                await refetch();
              }
            }}
            onCancel={() => {
              setShowTaskDetailsModal(false);
              navigate(`/project/${projectKey}/board/${boardKey}`);
            }}
          />
        )
      }
      {
        showUpdateColumn && (
          <EditColumnModal
            column={column}
            onClose={() => {
              setShowUpdateColumn(false);
              setShowAutomations(false);
            }}
            refetch={refetch}
            setShowNewColumnForm={setShowUpdateColumn}
            showNewColumnForm={showUpdateColumn}
            showOnlyAutomations={showAutomations}
          />
        )
      }
    </div >
  );
};


function QuickAddTask({ columnId, projectKey, createTaskAt }: {
  columnId: string;
  projectKey: string | undefined;
  createTaskAt: 'top' | 'bottom';
}) {

  const [showQuickAddTask, setShowQuickAddTask] = useState(false);
  const [quickAddTask, setQuickAddTask] = useState<{
    label: string;
    assigneeId: string | null;
    taskTypeId: string;
    priorityId: string;
    dueDate: string | null;
    position: 'top' | 'bottom';
  }>({
    label: '',
    assigneeId: null,
    taskTypeId: 'ttkefwc4qtaaogcxrkyu98x0',
    priorityId: 'tdkcw9in2xgbq1lrpd4phsmk',
    dueDate: null,
    position: createTaskAt,
  });

  return <>

    {!showQuickAddTask && (
      <Button type='text'
        style={{
          width: '100%',
          marginBottom: '.5rem',
        }}
        size='small'
        onClick={() => setShowQuickAddTask(true)}>
        <Text type='secondary'>
          +
        </Text>
      </Button>
    )
    }
    {
      showQuickAddTask && (
        <Button type='text'
          style={
            {
              width: '100%',
            }
          }
          size='small'
          onClick={() => {
            setShowQuickAddTask(false);
            setQuickAddTask({
              label: '',
              assigneeId: null,
              taskTypeId: 'ttkefwc4qtaaogcxrkyu98x0',
              priorityId: 'tdkcw9in2xgbq1lrpd4phsmk',
              dueDate: null,
              position: createTaskAt,
            })
          }}>
          <Text
            type='danger'
          >
            x
          </Text>
        </Button>
      )
    }
    {
      showQuickAddTask && (
        <div
          style={{
            margin: '.5rem 0',
          }}
        >
          <Card size='small'
          variant='borderless'
          >
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                marginBottom: ".5rem",
              }}>
              <div
                style={{
                  flex: 1,
                }}
              >
                <Input
                  placeholder='Label'
                  width={'100%'}
                  autoFocus
                  onChange={(e) => setQuickAddTask({
                    ...quickAddTask,
                    label: e.target.value,
                  })}
                  value={quickAddTask.label}
                  onPressEnter={async (e: any) => {
                    if (quickAddTask.label.trim() === '') return;
                    try {
                      await api.post(`/api/columns/${columnId}/tasks`, quickAddTask);
                      setQuickAddTask({
                        label: '',
                        assigneeId: null,
                        taskTypeId: 'ttkefwc4qtaaogcxrkyu98x0',
                        priorityId: 'tdkcw9in2xgbq1lrpd4phsmk',
                        dueDate: null,
                        position: createTaskAt,
                      })
                    } catch (error) {
                      console.log(error, ':(');
                    }
                  }}
                />
              </div>
              <div
              >
                <QuickChangeAssignee
                  currentAssigneeId={quickAddTask.assigneeId ?? ''}
                  projectKey={projectKey as string}
                  showToolTip
                  onChangeAssignee={async (option) => {
                    setQuickAddTask({
                      ...quickAddTask,
                      assigneeId: option ? option.value : null,
                    });
                  }}

                />

              </div>
            </div>
            <Space>
              <div>
                <Select
                  placeholder='Type'
                  size='small'
                  variant='borderless'
                  defaultValue={
                    {
                      label: 'Task',
                      value: 'ttkefwc4qtaaogcxrkyu98x0',
                    }
                  }
                  labelRender={(value: any) => {
                    return (
                      <div style={{ display: 'flex', gap: '.3rem' }}>
                        {getTaskTypeIcon(value.label?.toLowerCase() as any)}
                      </div>
                    );
                  }}
                  optionRender={(option: any) => (
                    <div>
                      {getTaskTypeIcon(option.label?.toLowerCase() as any)}
                      {option.label}
                    </div>
                  )}
                  dropdownStyle={{
                    width: '130px',
                  }}
                  value={quickAddTask.taskTypeId}
                  onChange={(value: any) => setQuickAddTask({
                    ...quickAddTask,
                    taskTypeId: value,
                  })}
                  options={[
                    {
                      label: 'Task',
                      value: 'ttkefwc4qtaaogcxrkyu98x0',
                    },
                    {
                      label: 'Bug',
                      value: 'tzz06j7svw51umjdd0ov5ey8',
                    },
                    {
                      label: 'Story',
                      value: 'w38qzcuzxm81h4jarp4aog9g',
                    },
                  ]}
                />
              </div>
              <div>
                <Select
                  placeholder='Priority'
                  size='small'
                  variant='borderless'
                  onChange={(value: any) => setQuickAddTask({
                    ...quickAddTask,
                    priorityId: value,
                  })}
                  defaultValue={{
                    label: 'Normal',
                    value: 'tdkcw9in2xgbq1lrpd4phsmk'
                  }}
                  dropdownStyle={{
                    width: '130px',
                  }}
                  value={quickAddTask.priorityId}
                  labelRender={(value: any) => {
                    return (
                      <div>
                        {getTaskPriorityIcon(value.label?.toLowerCase() as any)}
                      </div>
                    );
                  }}
                  optionRender={(option: any) => (
                    <div>
                      {getTaskPriorityIcon(option.label?.toLowerCase() as any)}
                      {option.label}
                    </div>
                  )}
                  options={[
                    {
                      label: 'Lowest',
                      value: 'dvxt57fk0jdot8hap0e8ypx2',
                    },
                    {
                      label: 'Low',
                      value: 'a7szfdlji72zq2cxi5wyu31h',
                    },
                    {
                      label: 'Normal',
                      value: 'tdkcw9in2xgbq1lrpd4phsmk',
                    },
                    {
                      label: 'High',
                      value: 'mdlejnloz445oinelehbnx90',
                    },
                    {
                      label: 'Highest',
                      value: 'xsuqk8z7qx3uoykyih8l2wzz',
                    },
                  ]}
                />
              </div>
              <div>
                <DatePicker
                  placeholder='Due Date'
                  variant={'borderless'}
                  size={'small'}
                  format={'DD MMM YYYY'}
                  value={
                    quickAddTask.dueDate
                      ? dayjs(quickAddTask.dueDate)
                      : undefined
                  }
                  onChange={(value) => {
                    setQuickAddTask({
                      ...quickAddTask,
                      dueDate: value?.format('YYYY-MM-DD'),
                    });
                  }}
                  showNow={false}
                  presets={[
                    {
                      label: 'Today',
                      value: dayjs(),
                    },
                    {
                      label: 'Tomorrow',
                      value: dayjs().add(1, 'day'),
                    },
                    {
                      label: 'Next week',
                      value: dayjs().add(1, 'week'),
                    },
                    {
                      label: '2 weeks',
                      value: dayjs().add(2, 'week'),
                    },
                    {
                      label: '1 month',
                      value: dayjs().add(1, 'month'),
                    },
                  ]}
                />
              </div>
            </Space>

          </Card>
        </div>

      )
    }
  </>;
}