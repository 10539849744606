import dayjs, { Dayjs } from 'dayjs';
import { QuestTask, TimeUnit, DateGroup, WeekGroup } from './types';

/**
 * Get the min/max date from tasks and return the range of days
 */
export function getDateRange(tasks: QuestTask[]): Dayjs[] {
  if (!tasks.length) return [];

  const startDates: Dayjs[] = [];
  const endDates: Dayjs[] = [];

  tasks.forEach((task) => {
    startDates.push(dayjs(task.start));
    endDates.push(dayjs(task.end));
  });

  const rangeStart = dayjs?.min(startDates)!.startOf('year');
  const rangeEnd = dayjs?.max(endDates)!.endOf('year');

  const dates: Dayjs[] = [];
  let currentDate = rangeStart;
  while (
    currentDate.isBefore(rangeEnd) ||
    currentDate.isSame(rangeEnd, 'day')
  ) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
}

/**
 * Group dates (dayjs[]) by day/week/month/quarter/year
 */
export function groupDatesByTimeUnit(dates: Dayjs[], timeUnit: TimeUnit): DateGroup[] {
  const today = dayjs();
  const groups: DateGroup[] = [];

  let currentGroup: DateGroup | null = null;

  dates.forEach((date) => {
    let groupKey: string;
    let groupLabel: string;
    let isCurrentGroup = false;

    switch (timeUnit) {
      case 'week': {
        const wYear = date.isoWeekYear();
        const wNum = date.isoWeek();
        groupKey = `${wYear}-W${wNum}`;
        groupLabel = `${date.startOf('isoWeek').format('D MMM')} - ${date
          .endOf('isoWeek')
          .format('D MMM YYYY')}`;
        if (wNum === today.isoWeek() && wYear === today.year()) {
          isCurrentGroup = true;
        }
        break;
      }
      case 'month': {
        const mYear = date.year();
        const mNum = date.month();
        groupKey = `${mYear}-${mNum}`;
        groupLabel = date.format('MMM YYYY');
        if (mYear === today.year() && mNum === today.month()) {
          isCurrentGroup = true;
        }
        break;
      }
      case 'quarter': {
        const qYear = date.year();
        const qNum = date.quarter();
        groupKey = `${qYear}-Q${qNum}`;
        groupLabel = `Q${qNum} ${qYear}`;
        if (qYear === today.year() && qNum === today.quarter()) {
          isCurrentGroup = true;
        }
        break;
      }
      case 'year': {
        const y = date.year();
        groupKey = `${y}`;
        groupLabel = `${y}`;
        if (y === today.year()) {
          isCurrentGroup = true;
        }
        break;
      }
      case 'day':
      default: {
        groupKey = date.format('YYYY-MM-DD');
        groupLabel = date.format('D MMM YYYY');
        if (date.isSame(today, 'day')) {
          isCurrentGroup = true;
        }
        break;
      }
    }

    if (!currentGroup || currentGroup.key !== groupKey) {
      currentGroup = {
        key: groupKey,
        label: groupLabel,
        dates: [],
        isCurrentGroup,
      };
      groups.push(currentGroup);
    }

    currentGroup.dates.push(date);
  });

  return groups;
}

/**
 * Group dates by week with additional week-specific metadata
 */
export function groupDatesByWeek(dateRange: Dayjs[]): WeekGroup[] {
  const today = dayjs();
  const weeks: WeekGroup[] = [];

  let currentWeek: WeekGroup | null = null;

  dateRange.forEach((date) => {
    const weekNumber = date.isoWeek();
    const weekYear = date.isoWeekYear();
    const weekKey = `${weekYear}-W${weekNumber}`;
    const weekMonth = date.format('MMM');
    const isCurrentWeek =
      weekNumber === today.isoWeek() && weekYear === today.year();

    if (!currentWeek || currentWeek.key !== weekKey) {
      currentWeek = {
        key: weekKey,
        weekNumber,
        weekYear,
        date,
        weekMonth,
        dates: [],
        isCurrentWeek,
        isCurrentGroup: false,
        label: `${date.startOf('isoWeek').format('D MMM')} - ${date
          .endOf('isoWeek')
          .format('D MMM YYYY')}`,
      };
      weeks.push(currentWeek);
    }
    currentWeek.dates.push(date);
  });

  return weeks;
}

/**
 * Calculate new dates for task movements
 */
export function calculateNewDates(
  start: Dayjs,
  end: Dayjs,
  deltaDays: number,
  direction: 'drag' | 'left' | 'right'
) {
  let newStart = start;
  let newEnd = end;

  if (direction === 'drag') {
    newStart = start.add(deltaDays, 'day');
    newEnd = end.add(deltaDays, 'day');
  } else if (direction === 'left') {
    newStart = start.add(-deltaDays, 'day');
    if (newEnd.diff(newStart, 'day') < 1) {
      newStart = newEnd.add(-1, 'day');
    }
  } else if (direction === 'right') {
    newEnd = end.add(deltaDays, 'day');
    if (newEnd.diff(newStart, 'day') < 1) {
      newEnd = newStart.add(1, 'day');
    }
  }

  const totalDuration = newEnd.diff(newStart, 'day') + 1;
  return { newStart, newEnd, totalDuration };
}
