import { useProjectStore } from '@/state/projectState';
import { Activity } from './Activity';
import { Button, Card, Col, Collapse, Divider, Popover, Row, Skeleton, Statistic, Tag } from 'antd';
import { Created } from './Created';
import { Completed } from './Completed';
import { Updated } from './Updated';
import { Due } from './Due';
import { getTaskTypeIcon } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@/api';
import Text from 'antd/es/typography/Text';
import { SyncOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import { useNavigate } from 'react-router-dom';
import { sseClient } from '@/api/realtime';

export default function Summary() {
  const project = useProjectStore((state) => state.project);
  if (!project) return null;

  return (
    <div style={{ overflow: 'auto', }}>
      <AtAGlance />
      <br />

      <div style={{ display: "flex", gap: "1rem", flexWrap: 'wrap' }}>
        <div
          style={{ flex: 1 }}
        >
          <Completed projectId={project?.id} />
        </div>
        <div style={{ flex: 1 }}>
          <Due projectId={project?.id} />
        </div>
        <div style={{ flex: 1 }}>
          <Created projectId={project?.id} />
        </div>
      </div>

      <Divider />
      <Row>
        <Col span={24}>
          <Activity projectId={project?.id} projectKey={project.key} />
        </Col>
      </Row>
    </div>
  );
}

function AtAGlance() {


  const project = useProjectStore((state) => state.project);


  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['project-at-a-glance', project?.id],
    queryFn: async () => {
      const result = await api.get(`/api/projects/${project?.id}/at-a-glance`);
      return result.data;
    }
  })

  return (
    <div>

      <Card
        variant='borderless'
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Text>Tasks At A Glance</Text>
            </div>
            <div>
              <Button
                size='small'
                type='text'
                onClick={() => {
                  refetch();
                }}
              >
                <SyncOutlined />
              </Button>
            </div>
          </div>
        }
      // loading={isLoading || isFetching}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >


          <StatisticCard title='Backlog' taskKey='backlog' value={data?.backlog} color='blue' icon={<></>} isLoading={isLoading || isFetching} />


          <StatisticCard title='Todo' taskKey='todo' value={data?.todo} color='orange' icon={<></>} isLoading={isLoading || isFetching} />

          <StatisticCard title='In Progress' taskKey='in_progress' value={data?.inProgress} color='purple' icon={<></>} isLoading={isLoading || isFetching} />


          <StatisticCard title='Done' taskKey='done' value={data?.done} color='green' icon={<></>} isLoading={isLoading || isFetching} />
          |
          <StatisticCard title='Task' taskKey='task' value={data?.task} icon={getTaskTypeIcon('task')} isLoading={isLoading || isFetching} />
          <StatisticCard title='Story' taskKey='story' value={data?.story} icon={getTaskTypeIcon('story')} isLoading={isLoading || isFetching} />
          <StatisticCard title='Bug' taskKey='bug' value={data?.bug} icon={getTaskTypeIcon('bug')} isLoading={isLoading || isFetching} />



        </div>
      </Card>
    </div>
  );
}

function StatisticCard({ title, value, color = null, icon = null, isLoading, taskKey }: {
  title: string;
  taskKey: 'backlog' | 'todo' | 'in_progress' | 'done' | 'task' | 'story' | 'bug';
  value: number;
  color?: string | null;
  isLoading: boolean;
  icon: JSX.Element | null;
}) {

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  return (
    <Popover
      zIndex={1000}
      content={
        <TypeTasks type={taskKey} />
      }
      // open={isPopoverVisible}
      trigger={['click']}
    >
      <Button
        style={{
          padding: 35,
        }}
        type='text'
      >
        <Statistic
          title={
            <><Tag bordered={false} color={color ?? undefined}> {icon ?? <></>} {title}</Tag></>
          }
          loading={isLoading}
          value={value}
        // valueRender={(value) => (
        //   <Button type='link'
        //     // onBlur={() => {
        //     //   setIsPopoverVisible(false);
        //     // }}
        //     onClick={() => {
        //       setIsPopoverVisible(!isPopoverVisible);
        //     }}
        //   >
        //     {value}
        //   </Button>
        // )}
        />

      </Button>
    </Popover>

  );
}

function TypeTasks({ type }: {
  type: 'task' | 'story' | 'bug' | 'todo' | 'in_progress' | 'done' | 'backlog';
}) {
  const project = useProjectStore((state) => state.project);
  const { data: tasks, isLoading, isFetching, refetch } = useQuery({
    queryKey: [project?.id, 'at-a-glance-tasks', type],
    queryFn: async () => {
      const result = await api.get(`/api/projects/${project?.id}/at-a-glance/tasks/${type}`);
      return result.data;
    }
  })


  const queryClient = useQueryClient();

  useEffect(() => {
    function handleTaskUpdated(event: any) {
      queryClient.setQueryData([project?.id, 'at-a-glance-tasks', type], (oldData: any) => {
        const updatedTask = JSON.parse(JSON.parse(event.data)).payload;
        const dataCopy = [...oldData];

        const updatedTaskIndex = oldData.findIndex(
          (task: any) => task.id === updatedTask.id
        );
        if (updatedTaskIndex > -1) {
          // overwrite the old task keys with the updated task key by key
          const taskCopy = { ...oldData[updatedTaskIndex] };
          for (const key in updatedTask) {
            taskCopy[key] = updatedTask[key];
          }
          dataCopy[updatedTaskIndex] = taskCopy;

        }

        return dataCopy; // return a new array to trigger re-render?;
      });
    };

    sseClient.addEventListener('task_updated', handleTaskUpdated);

    return () => {
      sseClient.removeEventListener('task_updated', handleTaskUpdated);
    };

  }, []);

  const navigate = useNavigate();

  if (isLoading || isFetching) return <Skeleton active />

  // First, get your boardKeys and groupByBoard as you've shown
  const boardKeys = tasks.map((task: { boardKey: any; boardLabel: any; }) => ({
    key: task.boardKey,
    label: task.boardLabel
  }))
    .filter((value: { key: any; label: any; }, index: any, self: any[]) =>
      self.findIndex((t: { key: any; label: any; }) => t.key === value.key && t.label === value.label) === index
    );

  // Sort boardKeys by the key property if needed
  // boardKeys.sort((a, b) => a.key.localeCompare(b.key));

  const groupByBoard = Object.groupBy(tasks, (task: any) => task.boardLabel);

  // Create a map of label to key for quick lookup
  const labelToKeyMap: any = {};
  boardKeys.forEach((boardKey: { label: string, key: string }) => {
    labelToKeyMap[boardKey.label] = boardKey.key;
  });

  // Get the entries from groupByBoard
  const entries = Object.entries(groupByBoard);

  // Sort the entries based on the corresponding key in boardKeys
  entries.sort((a, b) => {
    const keyA = labelToKeyMap[a[0]];
    const keyB = labelToKeyMap[b[0]];
    return keyA - keyB;
  });

  // Create a new object with sorted entries
  const sortedGroupByBoard = Object.fromEntries(entries);


  // sort groupByBoard by boardKeys

  const elements = Object.entries(sortedGroupByBoard).map(([boardLabel, tasks]) => {
    return <Collapse key={boardLabel}
      bordered={false}
      style={{
        marginBottom: '.5rem'
      }}
    >
      <Collapse.Panel header={<>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            {boardLabel}
          </div>
          <div>
            <Tag bordered={false}>{tasks?.length}</Tag>

          </div>
        </div>
      </>} key='1'>
        {tasks?.map((task: any) => (
          <Card
            style={{ marginBottom: '.5rem', cursor: 'pointer' }}
            variant='borderless'
            size='small'
          >
            <div key={task.id}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: ".2rem",
                }}
                onClick={() => {
                  navigate(`?openTask=${task.key}`);
                }}
              >
                <div>
                  <Tag bordered={false} color='blue'>{task.key}</Tag>
                </div>
                <div style={{ flex: 2 }}>
                  <Text>
                    {task.label}
                  </Text>
                </div>
                <div>
                  <Tag bordered={false}>
                    {task.columnLabel}
                  </Tag>
                  <QuickChangeAssignee disabled currentAssigneeId={task.assigneeId} projectKey={
                    project?.key ?? ""} taskId={task.id} showToolTip />
                </div>
              </div>
            </div>
          </Card>
        ))}
      </Collapse.Panel>
    </Collapse>
  }
  );



  return (
    <div
      style={{
        width: '600px',
        maxHeight: '400px',
        overflow: 'auto',
      }}
    >
      {...elements}
    </div>
  );

}
