import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { TimeUnit } from './types';
import { groupDatesByTimeUnit, groupDatesByWeek } from './dateUtils';

const { Text } = Typography;

// Constants
const TODAY_MARKER_COLOR = 'darkred';

interface TimelineHeaderProps {
  dateRange: Dayjs[];
  dayWidth: number;
  timeUnit: TimeUnit;
  todayOffset: number;
}

/**
 * Renders the timeline header cells (day/week/month/quarter/year).
 * Draws the vertical "today" line for time units >= week.
 */
export default function TimelineHeader({
  dateRange,
  dayWidth,
  timeUnit,
  todayOffset
}: TimelineHeaderProps) {
  const today = dayjs();

  if (timeUnit === 'day') {
    // For 'day' time unit, show a top row with weeks, bottom row with days
    const weeks = groupDatesByWeek(dateRange);

    return (
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        {/* Top header: Weekly grouping */}
        <div style={{ display: 'flex' }}>
          {weeks.map((week) => (
            <div
              key={week.key}
              style={{
                width: `${week.dates.length * dayWidth}px`,
                textAlign: 'center',
                borderRight: '1px solid #f0f0f0',
                borderBottom: '1px solid #f0f0f0',
              }}
            >
              <Text
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: week.isCurrentWeek ? '#fff' : 'inherit',
                }}
              >
                {week.weekMonth} '{week.date.format('YY')}
              </Text>
            </div>
          ))}
        </div>
        {/* Bottom header: Days */}
        <div style={{ display: 'flex' }}>
          {dateRange.map((date) => (
            <div
              key={date.format('YYYY-MM-DD')}
              style={{
                width: `${dayWidth}px`,
                textAlign: 'center',
                borderRight: '1px solid #f0f0f0',
                backgroundColor: date.isSame(today, 'day')
                  ? '#505050'
                  : 'transparent',
              }}
            >
              <Text
                style={{
                  fontSize: '10px',
                  color: date.isSame(today, 'day') ? '#fff' : 'inherit',
                }}
              >
                {date.format('DD')}
              </Text>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // For other units: week/month/quarter/year
  const groups = groupDatesByTimeUnit(dateRange, timeUnit);

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      {/* Grouped headers */}
      {groups.map((group) => (
        <div
          key={group.key}
          style={{
            width: `${group.dates.length * dayWidth}px`,
            textAlign: 'center',
            borderRight: '1px solid #f0f0f0',
            borderBottom: '1px solid #f0f0f0',
          }}
        >
          <Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
            {group.label}
          </Text>
        </div>
      ))}
      {/* Vertical line for 'today' */}
      <div
        style={{
          position: 'absolute',
          left: `${todayOffset}px`,
          top: 30,
          bottom: -10,
          width: '3px',
          backgroundColor: TODAY_MARKER_COLOR,
          pointerEvents: 'none',
        }}
      />
    </div>
  );
}
