import { useEffect, useState } from 'react';
import Editor from '../../components/Editor/index3';
import ProjectPagesList from './components/List';
import Text from 'antd/es/typography/Text';
import { api } from '../../api';
import Title from 'antd/es/typography/Title';
import { useProjectStore } from '../../state/projectState';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Card, Divider, Spin, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { mutateContent } from '@/utils/uploadUtils';
import { RelativeTime } from '@/components/RelativeTime';
import Avatar from 'antd/es/avatar/Avatar';

export default function ProjectPages() {
  const [selectedPage, setSelectedPage] = useState<any>(null);
  const [editable, setEditable] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { pageId } = useParams();

  const projectStore = useProjectStore();

  const fetchPages = async () => {
    const { data } = await api.get(
      `/api/projects/${projectStore.project?.id}/pages?status=${activeButton}`
    );
    return data;
  };

  const [activeButton, setActiveButton] = useState('active');

  const {
    data,
    isLoading,
    refetch: refetchTree,
  }: any = useQuery({
    queryKey: ['pages', projectStore.project?.id],
    enabled: !!projectStore.project?.id,
    queryFn: fetchPages,
  });

  useEffect(() => {

    if (projectStore.project?.id) {
      refetchTree();
    }

  }, [activeButton]);

  useEffect(() => {
    if (pageId && data && data.length) {
      // recursive find
      const findPage = (tree: any[]) => {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].key === pageId) {
            setSelectedPage(tree[i]);
            return;
          }
          if (tree[i].children) {
            findPage(tree[i].children);
          }
        }
      };

      findPage(data);
    }
  }, [pageId, data]);

  return (
    <div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div>
          <ProjectPagesList
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            treeData={data}
            isLoading={isLoading}
            data={data}
            refetchTree={refetchTree}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          // addToTree={addToTree}
          // removeFromTree={removeFromTree}
          />
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          {selectedPage && (
            <Card variant='borderless'>
              {selectedPage.archivedAt && (
                <Alert message={`This page is archived`} type='warning' banner />
              )}
              <Title
                onClick={(e) => {
                  e.stopPropagation();
                }}
                editable={!selectedPage.archivedAt ? {
                  async onChange(value) {
                    if (!value) return;
                    if (value === selectedPage.label) return;
                    if (value.trim() === selectedPage.label.trim()) return;
                    await api.put(`/api/pages/${selectedPage.key}`, {
                      label: value,
                    });

                    setSelectedPage({
                      ...selectedPage,
                      label: value,
                      updatedAt: new Date(),
                    });

                    refetchTree();
                  },
                } : false}
                level={3}
              >
                {selectedPage?.label}
              </Title>
              <Text type='secondary'>
                created {" "}
                <RelativeTime
                  date={selectedPage?.createdAt}
                /> by  <Tooltip
                  title={selectedPage.createdBy}
                >
                  <Avatar src={`https://questiro.eu-central-1.linodeobjects.com/user-avatar/${selectedPage.createdByAvatar}`} />
                </Tooltip>
              </Text>
              {selectedPage?.updatedAt && !selectedPage.archivedAt && (
                <Text type='secondary'>
                  - updated{' '}
                  <RelativeTime
                    date={selectedPage?.updatedAt}
                  /> by  <Tooltip
                    title={selectedPage.updatedBy}
                  >
                    <Avatar src={`https://questiro.eu-central-1.linodeobjects.com/user-avatar/${selectedPage.updatedByAvatar}`} /> {' '}
                  </Tooltip>
                </Text>
              )}
              {
                selectedPage?.archivedAt && (
                  <Text type='secondary'>
                    - archived{' '}
                    <RelativeTime
                      date={selectedPage?.archivedAt}
                    /> by  <Tooltip
                      title={selectedPage.updatedBy}
                    >
                      <Avatar src={`https://questiro.eu-central-1.linodeobjects.com/user-avatar/${selectedPage.updatedByAvatar}`} /> {' '}
                    </Tooltip>
                  </Text>
                )
              }
              <Divider />
              <Spin spinning={isUpdating}>
                <div
                  style={{
                    height: '75vh',
                    overflow: 'auto',
                    marginTop: ".5rem"
                    // border: '1px solid #f0f0f0',
                    // borderRadius: '4px',
                    // padding: '.5rem',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditable(true);
                  }}
                >
                  <Editor
                    defaultContent={selectedPage?.content}
                    maxHeight='67vh'
                    disableMentions
                    projectKey={null}
                    editable={selectedPage.archivedAt ? false : editable}
                    onSave={async (c: any) => {
                      try {
                        setEditable(false);
                        setIsUpdating(true);
                        await api.put(`/api/pages/${selectedPage.key}`, {
                          content: await mutateContent(c),
                        });
                        setSelectedPage({
                          ...selectedPage,
                          content: c,
                          updatedAt: new Date(),
                        });
                        setIsUpdating(false);

                      } catch (e) {
                        setIsUpdating(false);
                        throw e
                      }
                    }}
                    onCancel={() => {
                      setEditable(false);
                    }}
                  />
                </div>
              </Spin>
            </Card>
          )}
          {!selectedPage && <Card variant='borderless'>Select or create a new page</Card>}
        </div>
      </div>
    </div>
  );
}
