import React, { useState, useRef, useMemo } from 'react';
import { Table, Select, Skeleton, Tooltip, Button, Typography } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import QuestModal from '@/modals/QuestModal';
import { TimeUnit, QuestTask, SortBy } from './types';
import { getDateRange } from './dateUtils';
import TimelineHeader from './TimelineHeader';
import TimelineBar from './TimelineBar';
import useQuestData from './useQuestData';
import { TIME_UNIT_WIDTH, COLUMN_WIDTHS, UI } from './constants';

const { Option } = Select;
const { Text } = Typography;

/**
 * GanttChart component:
 *  - Fetches data (quest tasks)
 *  - Allows user to change time unit & sort
 *  - Renders the table with timeline
 */
export default function GanttChart() {
  const [timeUnit, setTimeUnit] = useState<TimeUnit>('month');
  const { projectKey } = useParams();
  const [questModalVisible, setQuestModalVisible] = useState<boolean>(false);
  const [selectedQuest, setSelectedQuest] = useState<QuestTask | null>(null);

  const scrollRef = useRef<HTMLDivElement>(null);

  // Get tasks data using the custom hook
  const {
    tasks,
    isLoading,
    refetch,
    sortBy,
    setSortBy,
    updateTaskDates
  } = useQuestData(projectKey);

  // Get day width based on time unit
  const dayWidth = useMemo(() => {
    return TIME_UNIT_WIDTH[timeUnit];
  }, [timeUnit]);

  // Memoized date range
  const dateRange = useMemo(() => {
    return tasks.length ? getDateRange(tasks) : [];
  }, [tasks]);

  // For the table's total scroll width
  const totalTimelineWidth = dateRange.length * dayWidth + COLUMN_WIDTHS.EXTRA_COLUMN_WIDTH;

  // The offset position for today's date line
  const today = dayjs().startOf('day');
  const minDate = dateRange[0];
  const todayOffset = minDate ? today.diff(minDate, 'day') * dayWidth : 0;

  // Table columns
  const columns = [
    {
      title: 'Quest',
      dataIndex: 'name',
      key: 'name',
      width: COLUMN_WIDTHS.QUEST_NAME,
      fixed: 'left' as const,
      render: (_text: string, record: QuestTask) => {
        return (
          <div>
            <Button
              onClick={() => {
                setSelectedQuest(record);
                setQuestModalVisible(true);
              }}
              size='small'
              type='link'
            >
              {record.name}
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Due In',
      width: COLUMN_WIDTHS.DUE_IN,
      fixed: 'left' as const,
      render: (_: unknown, record: QuestTask) => {
        return record.dueIn ? `${record.dueIn}d` : null;
      },
    },
    {
      title: 'Completed',
      dataIndex: 'progress',
      key: 'progress',
      width: COLUMN_WIDTHS.PROGRESS,
      fixed: 'left' as const,
      render: (text: number, record: QuestTask) => {
        return (
          <div>
            <Text>
              {record.completed}/{record.allTasks}
            </Text>{' '}
            <Text type='secondary'>({record.progress}%)</Text>
          </div>
        );
      },
    },
    {
      title: <TimelineHeader
        dateRange={dateRange}
        dayWidth={dayWidth}
        timeUnit={timeUnit}
        todayOffset={todayOffset}
      />,
      dataIndex: 'timeline',
      key: 'timeline',
      render: (_: unknown, record: QuestTask) => (
        <div style={{ position: 'relative' }}>
          <TimelineBar
            task={record}
            dateRange={dateRange}
            dayWidth={dayWidth}
            todayOffset={todayOffset}
            onTaskUpdate={updateTaskDates}
            scrollRef={scrollRef}
          />
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div>
      {/* Controls */}
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <div style={{ display: 'flex', gap: '1rem' }}>
          {/* Time unit selector */}
          <div>
            View by{' '}
            <Select<TimeUnit>
              value={timeUnit}
              onChange={(value) => setTimeUnit(value)}
              style={{ width: 100 }}
              size='small'
            >
              <Option value='day'>Day</Option>
              <Option value='week'>Week</Option>
              <Option value='month'>Month</Option>
              <Option value='quarter'>Quarter</Option>
              <Option value='year'>Year</Option>
            </Select>
          </div>
          {/* Sort selector */}
          <div>
            Sort by{' '}
            <Select<SortBy>
              value={sortBy}
              onChange={(value) => setSortBy(value)}
              style={{ width: 120 }}
              size='small'
            >
              <Option value='none'>No Sort</Option>
              <Option value='start'>Start Date</Option>
              <Option value='end'>End Date</Option>
            </Select>
          </div>
          {/* Go to Today */}
          <div>
            <Button
              size='small'
              type='text'
              onClick={() => {
                if (scrollRef.current) {
                  scrollRef.current.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center',
                  });
                }
              }}
            >
              Go To Today
            </Button>
          </div>
        </div>
      </div>

      {/* Table */}
      <Table<QuestTask>
        columns={columns}
        dataSource={tasks}
        size='small'
        rowKey='id'
        pagination={false}
        scroll={{ x: totalTimelineWidth + 250, y: UI.VISIBLE_VIEWPORT_HEIGHT }}
        bordered
      />

      {/* Quest Modal */}
      {selectedQuest && (
        <QuestModal
          open={questModalVisible}
          quest={selectedQuest}
          setOpen={setQuestModalVisible}
          onClose={async () => {
            await refetch();
            setSelectedQuest(null);
          }}
        />
      )}
    </div>
  );
}
