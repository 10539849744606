import { api } from '@/api';
import { RelativeTime } from '@/components/RelativeTime';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Table } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import Text from 'antd/es/typography/Text';
import { useNavigate } from 'react-router-dom';

export default function SpecificAdminTimesheet({
  userId,
  start,
  end,
  selectedUserName,
}: {
  userId: string;
  start: string;
  end: string;
  selectedUserName: string;
}) {
  async function getTimesheets() {
    const response = await api.get(
      `/api/admin/timesheets/${userId}?startDate=${start}&endDate=${end}`
    );
    return response.data;
  }

  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['timesheets', 'admin', start, end, userId],
    queryFn: getTimesheets,
  });

  const columns = [
    {
      title: 'Task Key',
      dataIndex: 'taskKey',
      key: 'taskKey',
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: 'Status',
      dataIndex: 'columnLabel',
      key: 'columnLabel',
    },
    {
      title: 'In status since',
      dataIndex: 'inStatus',
      key: 'inStatus',
      render: (value: string) => <RelativeTime date={value} />,
    },
    {
      title: 'Hours',
      dataIndex: 'hoursWorked',
      key: 'hoursWorked',
      render: (value: string) =>
        `${value?.endsWith(':0h') ? value.replace('0h', '00h') : value}`,
    },
  ];

  return (
    <Card
    variant='borderless'
    >
      <Table
        title={() => <>Timesheet for: <strong>{selectedUserName}</strong></>}
        dataSource={data}
        columns={columns}
        rowKey='id'
        loading={isLoading}
        onRow={(record: any) => {
          return {
            onClick: () => {
              navigate(`?openTask=${record.taskKey}`);
            },
          };
        }}
      />
    </Card>
  );
}
