// Timesheet.jsx

import React, { useState } from 'react';
import { Button, Card, Modal, Popconfirm, Table, Tag, Tooltip } from 'antd';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { api } from '@/api';
import Title from 'antd/es/typography/Title';
import AddTimesheet from './addTimesheet';
import Text from 'antd/es/typography/Text';
import { ColumnProps } from 'antd/es/table';

// Extend Day.js with the isoWeek plugin
dayjs.extend(isoWeek);

const Timesheet = () => {
  // Get the current week starting from Monday
  const [startOfWeek, setStartOfWeek] = useState(dayjs().startOf('isoWeek'));
  const daysOfWeek = [];

  const [visible, setVisible] = useState(false);

  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  for (let i = 0; i < 7; i++) {
    daysOfWeek.push(startOfWeek.add(i, 'day'));
  }

  const handleNextWeek = () => {
    setStartOfWeek(startOfWeek.add(1, 'week'));
  };

  const handlePreviousWeek = () => {
    setStartOfWeek(startOfWeek.subtract(1, 'week'));
  };

  const handleGoToToday = () => {
    setStartOfWeek(dayjs().startOf('isoWeek'));
  };

  // Define columns dynamically based on days of the week
  const columns: ColumnProps[] = daysOfWeek.map((day, i) => ({
    title: () => {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {day.format('ddd')}, {day.format('DD MMM')}
          </div>
        </div>
      );
    },
    dataIndex: i,
    key: i,
    align: 'left',
    render: (text, row) => {
      const isTask = !!row.task;
      return (
        <div
          onClick={
            isTask
              ? () => {
                const currentDay = startOfWeek
                  .add(i, 'day')
                  .format('YYYY-MM-DD');

                setSelectedDate(currentDay);
                setSelectedTask(row);
                setSelectedTime(text ?? undefined);
                setVisible(true);
              }
              : undefined
          }
          style={{ textAlign: 'center' }}
        >
          {text || ''} {isTask && <EditOutlined />}
        </div>
      );
    },
  }));

  columns.unshift({
    title: 'Task',
    dataIndex: 'task',
    key: 'task',
    fixed: 'left',
    align: 'left',
    render: (text) => <div>{text}</div>,
  });

  // Add a fixed column for task names
  columns.unshift({
    title: 'Task Key',
    dataIndex: 'taskKey',
    key: 'taskKey',
    fixed: 'left',
    align: 'left',
    render: (text) => <div>{text}</div>,
  });

  // Sample data (replace with your actual data)

  const fetchTimesheet = async () => {
    const tasks = await api.get(
      `/api/timesheet?startDate=${startOfWeek.format(
        'YYYY-MM-DD'
      )}&endDate=${startOfWeek.add(6, 'day').format('YYYY-MM-DD')}`
    );
    return tasks.data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'timesheet',
      startOfWeek.format('YYYY-MM-DD'),
      startOfWeek.add(6, 'day').format('YYYY-MM-DD'),
    ],
    queryFn: fetchTimesheet,
  });

  // const generateEmptyData = () => {
  //   const row: any = {
  //     taskKey: null,
  //     task: null,
  //   };

  //   for (let i = 0; i < 7; i++) {
  //     row[i] = (
  //       <div
  //         onClick={() => {
  //           setSelectedDate(startOfWeek.add(i, 'day').format('YYYY-MM-DD'));
  //           setVisible(true);
  //         }}
  //         style={{ textAlign: 'center' }}
  //       >
  //         <PlusCircleTwoTone />
  //       </div>
  //     );
  //   }
  //   return row;
  // };

  // const generatePerDay = () => {
  //   const row: any = {
  //     taskKey: 'Total',
  //     task: null,
  //   };

  //   for (let i = 0; i < 7; i++) {
  //     row[i] = (
  //       <div style={{ textAlign: 'center' }}>{data?.hoursPerDay[i] ?? '/'}</div>
  //     );
  //   }
  //   return row;
  // };

  const today = dayjs().format('YYYY-MM-DD');

  return (
    <div>
      <div></div>
      <div style={{ textAlign: 'center' }}></div>
      <Card
        variant='borderless'
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Title level={4}>
              {startOfWeek.format('DD MMM YYYY')} -{' '}
              {startOfWeek.add(6, 'day').format('DD MMM YYYY')}{' '}
            </Title>
            <div
              style={{
                display: 'flex',
                gap: '.5rem',
                justifyContent: 'center',
              }}
            >
              <Button size='small' onClick={handlePreviousWeek}>
                <ArrowLeftOutlined /> Previous Week
              </Button>
              <Button type='primary' size='small' onClick={handleGoToToday}>
                This Week
              </Button>
              <Button size='small' onClick={handleNextWeek}>
                Next Week <ArrowRightOutlined />
              </Button>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Title level={4}>Total: {data?.totalForPeriod}h</Title>
          </div>
        </div>
      </Card>
      <br />
      {/* <Table
        size='small'
        loading={isLoading}
        style={{ margin: '0 .5rem' }}
        columns={columns}
        dataSource={[
          ...(data?.hours ?? []),
          generateEmptyData(),
          generatePerDay(),
        ]}
        pagination={false}
        scroll={{ x: 'max-content' }}
        bordered
        footer={() => <div>Total: {data?.totalHoursWorked}</div>}
      /> */}

      <>
        <div style={{ display: 'flex', gap: '.5rem' }}>
          {daysOfWeek.map((day, i) => {
            const total = data?.totalByDate[day.format('YYYY-MM-DD')];
            return (
              <>
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1,
                  }}
                >
                  <Card
                  variant='borderless'
                    style={{
                      width: '100%',
                      border:
                        today === day.format('YYYY-MM-DD')
                          ? '1px solid #1890ff'
                          : '',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '.5rem',
                      }}
                    >
                      <div>
                        {day.format('ddd')}, {day.format('DD MMM')}{' '}
                      </div>
                      <div>{total ? `${total}h` : ''}</div>
                    </div>
                    <hr />
                    <div>
                      {data?.timesheet?.map((item: any) => {
                        if (
                          dayjs(item.date).format('YYYY-MM-DD') ===
                          day.format('YYYY-MM-DD')
                        ) {
                          return (
                            <div
                              style={{
                                marginBottom: '1rem',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                const currentDay = dayjs(item.date).format(
                                  'YYYY-MM-DD'
                                );

                                setSelectedDate(currentDay);
                                setSelectedTask(item);
                                setSelectedTime(item.hours);
                                setVisible(true);
                              }}
                            >
                              <Card key={item.id}
                              variant='borderless'
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    paddingBottom: '1rem',
                                  }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <div>
                                      <Tooltip title='Copy to previous day'>
                                        <Button
                                          size='small'
                                          type='text'
                                          onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            await api.post('/api/timesheet', {
                                              taskId: item.key,
                                              date: dayjs(item.date)
                                                .subtract(1, 'day')
                                                .format('YYYY-MM-DD'),
                                              minutes: item.minutes,
                                              isOvertime: false,
                                            });
                                            await refetch();
                                          }}
                                        >
                                          <ArrowLeftOutlined />
                                        </Button>
                                      </Tooltip>
                                    </div>
                                    <div>
                                      <Tooltip title='Copy to next day'>
                                        <Button
                                          size='small'
                                          type='text'
                                          onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            await api.post('/api/timesheet', {
                                              taskId: item.key,
                                              date: dayjs(item.date)
                                                .add(1, 'day')
                                                .format('YYYY-MM-DD'),
                                              minutes: item.minutes,
                                              isOvertime: false,
                                            });
                                            await refetch();
                                          }}
                                        >
                                          <ArrowRightOutlined />
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div>
                                    <Popconfirm
                                      title='Are you sure you want to remove selected timesheet?'
                                      onConfirm={async (e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        await api.post('/api/timesheet', {
                                          taskId: item.key,
                                          date: dayjs(item.date).format(
                                            'YYYY-MM-DD'
                                          ),
                                          minutes: 0,
                                          isOvertime: false,
                                        });
                                        await refetch();
                                      }}
                                    >
                                      <Button
                                        size='small'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                        danger
                                        type='text'
                                      >
                                        <DeleteOutlined />
                                      </Button>
                                    </Popconfirm>
                                  </div>
                                </div>

                                <div>{item.task} </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '.5rem',
                                  }}
                                >
                                  <div>
                                    <Tag>{item.taskKey}</Tag>
                                  </div>
                                  <div style={{ textAlign: 'right' }}>
                                    <Tag>{item.hours}h</Tag>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          );
                        }
                      })}
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          type='text'
                          size='small'
                          onClick={() => {
                            setSelectedDate(day.format('YYYY-MM-DD'));
                            setVisible(true);
                          }}
                        >
                          <PlusCircleFilled /> Add
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </>
            );
          })}
        </div>
      </>

      {visible && selectedDate && (
        <Modal
          open={visible}
          onCancel={() => {
            setVisible(false);
            setSelectedDate(null);
            setSelectedTask(null);
            setSelectedTime(null);
          }}
          onClose={() => {
            setVisible(false);
            setSelectedDate(null);
            setSelectedTask(null);
            setSelectedTime(null);
          }}
          footer={null}
        >
          <AddTimesheet
            selectedTask={selectedTask}
            selectedTime={selectedTime}
            defaultDate={selectedDate}
            setVisible={setVisible}
            refetch={refetch}
            onCleanup={() => {
              setSelectedDate(null);
              setSelectedTask(null);
              setSelectedTime(null);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Timesheet;
