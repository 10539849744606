import { useState, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@/api';
import dayjs, { Dayjs } from 'dayjs';
import { QuestTask, SortBy } from './types';

export default function useQuestData(projectKey: string | undefined) {
  const [sortBy, setSortBy] = useState<SortBy>('none');
  const queryClient = useQueryClient();

  // Fetch quest data
  const {
    data: tasks = [],
    refetch,
    isLoading,
    isFetching,
  } = useQuery<QuestTask[]>({
    queryKey: ['questsGantt', projectKey],
    queryFn: async () => {
      if (!projectKey) return [];
      const { data } = await api.get(`/api/projects/${projectKey}/quests/gant`);
      return data;
    },
    // Consider adding error handling and retry options here
  });

  // Optimistic update function
  const updateTaskDates = async (
    task: QuestTask,
    newStartDate: Dayjs,
    newEndDate: Dayjs
  ) => {
    // Update client cache immediately (optimistic)
    queryClient.setQueryData<QuestTask[]>(
      ['questsGantt', projectKey],
      (data = []) =>
        data.map((t) =>
          t.id === task.id
            ? {
              ...t,
              start: newStartDate.toISOString(),
              end: newEndDate.toISOString(),
            }
            : t
        )
    );

    // Also update on the server
    try {
      await api.put(`/api/quests/${task.id}`, {
        label: task.name,
        description: task.description,
        startDate: dayjs(newStartDate).format('YYYY-MM-DD'),
        endDate: dayjs(newEndDate).format('YYYY-MM-DD'),
      });
      // Optionally refetch if necessary
      // await refetch();
    } catch (error) {
      console.error('Failed to update task:', error);
      // Rollback on error by refetching from server
      await refetch();
    }
  };

  // Sort tasks based on user selection
  const sortedTasks = useMemo(() => {
    const tasksCopy = [...tasks];
    switch (sortBy) {
      case 'start':
        return tasksCopy.sort((a, b) =>
          dayjs(a.start).diff(dayjs(b.start), 'millisecond')
        );
      case 'end':
        return tasksCopy.sort((a, b) =>
          dayjs(a.end).diff(dayjs(b.end), 'millisecond')
        );
      case 'none':
      default:
        return tasksCopy;
    }
  }, [tasks, sortBy]);

  return {
    tasks: sortedTasks,
    isLoading,
    isFetching,
    refetch,
    sortBy,
    setSortBy,
    updateTaskDates,
  };
}
