import React from 'react';
import type { FormProps } from 'antd';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Switch,
  message,
} from 'antd';
import { api } from '../../../api';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import encodeQR from '@paulmillr/qr';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const Security: React.FC<any> = ({ totpEnabled }: { totpEnabled: boolean }) => {
  const [form] = Form.useForm();

  const [has2Fa, setHas2Fa] = React.useState(totpEnabled);

  const [mfaSetup, setMfaSetup] = React.useState<null | {
    secret: string;
    uri: string;
  }>(null);

  const [mfaConfirmCode, setMfaConfirmCode] = React.useState<string | null>(
    null
  );

  const [recoveryCode, setRecoveryCode] = React.useState<string | null>(null);

  const submitConfirmCode = async () => {
    try {
      const res = await api.post('/api/me/mfa/confirm', {
        code: mfaConfirmCode,
      });
      if (!res.data.success) {
        messageApi.error(res.data.message);
        return;
      }
      setRecoveryCode(res.data.totpRecovery);
      messageApi.success('MFA enabled successfully');
      setHas2Fa(true);
    } catch (error) {
      messageApi.error('Failed to enable MFA');
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      await api.put('/api/me', { password: values.password });
      messageApi.success('Password updated successfully');
    } catch (error) {
      messageApi.error('Failed to update password');
    }
  };

  const initSetupMFA = async () => {
    try {
      const response = await api.get('/api/me/mfa/setup');
      setMfaSetup(response.data);
    } catch (error) {
      messageApi.error('Failed to get MFA status');
    }
  };

  return (
    <div>
      {contextHolder}
      <Card>
        <Form
          name='basic'
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete='off'
          layout='vertical'
        >
          <div>
            <Form.Item
              label='Password'
              name={'password'}
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label='Confirm Password'
              name={'confirm-password'}
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: (rule, value, callback) => {
                    if (value !== form.getFieldValue('password')) {
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>

          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Title level={4}>
              MFA
              {has2Fa && <span style={{ color: 'green' }}> Enabled</span>}
              {!has2Fa && <span style={{ color: 'red' }}> Disabled</span>}
            </Title>
            {has2Fa && (
              <div>
                <Text>
                  You have enabled MFA on your account. To disable it, click the
                  button below. (not recommended, lowered security)
                </Text>
              </div>
            )}
            {!has2Fa && (
              <div>
                <Text>
                  Enable MFA to add an extra layer of security to your account
                  (TOTP)
                </Text>
              </div>
            )}
          </div>
          <div>
            {!has2Fa && (
              <Button
                type='primary'
                onClick={() => {
                  initSetupMFA();
                }}
              >
                Setup MFA
              </Button>
            )}
            {has2Fa && (
              <Popconfirm
                title='Are you sure you want to disable MFA?'
                onConfirm={async () => {
                  try {
                    await api.delete('/api/me/mfa/disable');
                    setHas2Fa(false);
                    messageApi.success('MFA disabled successfully');
                  } catch (error) {
                    messageApi.error('Failed to disable MFA');
                  }
                }}
                okText='Yes'
                cancelText='No'
              >
                <Button type='primary' danger>
                  Disable MFA
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </Card>
      {mfaSetup && !recoveryCode && (
        <div>
          <p>Code: {mfaSetup.secret}</p>
          <p>Scan the QR code below to setup MFA</p>
          <div style={{ display: 'flex' }}>
            <div
              style={{ width: '180px', height: '180px' }}
              dangerouslySetInnerHTML={{
                __html: encodeQR(mfaSetup.uri, 'svg'),
              }}
            ></div>
            <div>
              <Text>Enter the 6 digits code from your auth app</Text>
              <Input.OTP onChange={(value) => setMfaConfirmCode(value)} />
              <Button
                onClick={submitConfirmCode}
                style={{ marginLeft: '1rem' }}
                type='primary'
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
      {recoveryCode && (
        <div>
          <p>Recovery code: {recoveryCode}</p>
          <Text>
            Save this code somewhere safe. You can use this code to login if you
            lose access to your MFA device
          </Text>
        </div>
      )}
    </div>
  );
};

export default Security;
