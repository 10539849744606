import { NodeViewWrapper } from '@tiptap/react';
import React, { useEffect } from 'react';
import { api } from '../../../../api';
import { Button, Popover, Progress, Spin, Tooltip } from 'antd';
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  FileTwoTone,
} from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import { base64ToBlob } from '@/utils';
import { fileTypeFromBlob } from 'file-type';

import { throttle } from 'lodash';
import axios from 'axios';

export default (props: any) => {
  const [blob, setBlob]: any = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingPercent, setLoadingPercent] = React.useState(0);
  const [error, setError] = React.useState(false);
  const [contentType, setContentType] = React.useState('');
  const [fileSize, setFileSize] = React.useState(0);


  const throtleLoading = throttle((percent: number) => {

    setLoadingPercent(percent)

  }, 300, { leading: true, trailing: true })


  const generateFileSizeString = (size: number) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)}KB`;
    } else {
      return `${(size / 1024 / 1024).toFixed(2)}MB`;
    }
  }

  async function getBlob() {
    try {
      if (props.node.attrs.base64) {
        const type = props.node.attrs.base64.split("/")[0].split(":")[1]

        if (type === 'video') {
          setContentType('video');
        }

        const blb = base64ToBlob(
          props.node.attrs.base64.split(",")[1],
          props.node.attrs.base64.split(":")[1].split(";")[0]
        )

        setFileSize(
          blb.size
        )

        setBlob(
          URL.createObjectURL(
            blb
          )
        );
        setLoading(false);
      } else {

        // old way
        // const response = await api.get(
        //   `${import.meta.env.VITE_API_URL}/media/${props.node.attrs.fileKey}`,
        //   {
        //     onDownloadProgress: (progressEvent) => {
        //       setFileSize(
        //         progressEvent.total
        //       )
        //       const percent = (parseFloat(progressEvent.loaded / progressEvent.total).toFixed(2) ?? 0) * 100
        //       throtleLoading(percent)

        //     },
        //     responseType: 'blob',
        //   }
        // );

        const responsePresignedUrl = await api.get(
          `${import.meta.env.VITE_API_URL}/media/${props.node.attrs.fileKey}/presigned`,
        );


        const response: any = await axios.get(
          responsePresignedUrl.data,
          {
            onDownloadProgress: (progressEvent) => {
              setFileSize(
                progressEvent?.total || 0
              )
              const percent = +(parseFloat(`${progressEvent.loaded / (progressEvent?.total ?? 0)}`).toFixed(2) ?? 0) * 100
              throtleLoading(percent)

            },
            responseType: 'blob',
          }
        );


        const blob = new Blob([response.data], { type: response?.headers?.get('Content-Type')?.toString() ?? "" });
        const type = await fileTypeFromBlob(blob);

        if (response.status === 404) {
          setBlob(null);
          setError(true);
          setLoading(false);
          return;
        }



        const url = URL.createObjectURL(blob);
        setBlob(url);
        setLoading(false);
        if (type?.mime.startsWith('video/')) {
          setContentType('video');
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        setBlob(null);
        setError(true);
        setLoading(false);
        return;
      }
      console.log(error.toString())
      console.log(error);
    }
  }

  useEffect(() => {
    if (!blob) {
      getBlob();
    }
  }, []);

  return (
    <NodeViewWrapper className='auth-file'>
      {loading && !error && <div style={{
        border: '1px solid gray',
        borderRadius: '4px',
        padding: '.3rem',
        margin: '.3rem 0',
      }}>
        <Text
          type='secondary'>
          Downloading File...
        </Text>
        <Progress percent={+loadingPercent.toFixed(2)} status='active' size={'small'} />
        <Text
          type='secondary'>
          {generateFileSizeString(fileSize)}
        </Text>
      </div>}
      {error && (
        <div style={{ color: 'orange' }}>
          <ExclamationCircleOutlined /> File not found
        </div>
      )}
      {blob && !loading && !error && (
        <>
          {contentType === 'video' ? (
            <video
              controls
              width={400}
              style={{ borderRadius: '4px', margin: '.5rem 0' }}
              src={blob}
              onLoad={() => {
                URL.revokeObjectURL(blob);
              }}
            ></video>
          ) : (
            <Popover
              trigger={'click'}
              content={
                <div style={{ display: 'flex', gap: '.5rem' }}>
                  <Tooltip title='Download File'>
                    <Button
                      size='small'
                      type='primary'
                      title='Download'
                      onClick={(e) => {
                        e.stopPropagation();
                        const link = document.createElement('a');
                        link.href = blob;
                        link.download = `file-${props.node.attrs.fileName}`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      <DownloadOutlined />
                    </Button>
                  </Tooltip>
                </div>
              }
            >
              <div
                style={{
                  display: 'flex',
                  gap: '.2rem',
                  width: 'fit-content',
                  margin: '.3rem 0',
                  border: '1px solid gray',
                  borderRadius: '4px',
                  padding: '.3rem',
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <FileTwoTone />
                <Button type='link' size='small'>
                  {props.node.attrs.fileName}
                </Button>
                <Text
                  type='secondary'
                >
                  {
                    generateFileSizeString(fileSize)
                  }
                </Text>
              </div>
            </Popover>
          )}
        </>
      )}
    </NodeViewWrapper>
  );
};
