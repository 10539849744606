import { useEffect, useState } from 'react';
import { Card, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../api';
import { Button, ConfigProvider, Input, Space, theme } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { ArrowLeftOutlined, LoginOutlined } from '@ant-design/icons';

export default function Login() {
  // const userStore = useUserStore();
  const navigate = useNavigate();
  const [subdomain, setSubdomain] = useState('');

  const [loginInProgress, setLoginInProgress] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [requireTotp, setRequireTotp] = useState(false);
  const [tempToken, setTempToken] = useState<string | null>(null);
  const [otpToken, setOtpToken] = useState('');
  const [otp, setOtp] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginInProgress(true);
    const data = new FormData(event.currentTarget);

    const email = data.get('email') as string;
    const password = data.get('password') as string;

    try {
      const response = await api.post(
        '/api/login',
        { email, password },
        { withCredentials: true }
      );

      if (response.data?.requireTotp) {
        setRequireTotp(true);
        setTempToken(response.data.tempToken);
        setLoginInProgress(false);
        return;
      }
      localStorage.setItem('token', response.data);
      messageApi.success('Logged in');
      return navigate('/');
    } catch (error: any) {
      messageApi.error(error.response.data.message);
      console.log(error, error.response);
    }
    setLoginInProgress(false);
  };

  const handleSubmitOtp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await api.post(
        '/api/login/totp',
        { code: otp, tempToken },
        { withCredentials: true }
      );

      localStorage.setItem('token', response.data);
      messageApi.success('Logged in');
      return navigate('/');
    } catch (error) {
      messageApi.error('Invalid OTP');
      console.log(error);
    }
  };

  const savedTheme = localStorage.getItem('theme');

  let themeToSelect = theme.defaultAlgorithm;

  switch (savedTheme) {
    case 'dark': {
      themeToSelect = theme.darkAlgorithm;
      break;
    }
    default: {
      themeToSelect = theme.defaultAlgorithm;
    }
  }

  useEffect(() => {
    const subdomain = window.location.host.split('.')[0];
    setSubdomain(subdomain);
  }, []);

  // const isLogedIn = async () => {

  //   try {
  //     // const token = await api.get(
  //     //   '/api/me',
  //     //   { withCredentials: true }
  //     // );

  //     // return navigate('/');

  //   } catch (error: any) {
  //     localStorage.removeItem('token')
  //     console.log(error, error.response);
  //   }

  // }

  // useEffect(() => {

  //   isLogedIn()

  // }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.darkAlgorithm],
      }}
    >
      {contextHolder}
      <Space
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Title level={1}>{subdomain}</Title>
        {requireTotp ? (
          <Card style={{ padding: '1rem' }} variant='borderless'>
            <div style={{ textAlign: 'center' }}>
              <Title level={3}>Enter OTP</Title>
            </div>
            <form onSubmit={handleSubmitOtp}>
              <div style={{ textAlign: 'center' }}>
                <Input.OTP
                  value={otp}
                  onChange={(value) => setOtp(value)}
                  formatter={(value) => value.replace(/\D/g, '')}
                />
              </div>

              <div style={{ marginTop: '1rem' }}></div>

              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <div>
                  <Button
                    loading={loginInProgress}
                    htmlType='submit'
                    type='primary'
                    disabled={loginInProgress}
                    style={{ width: '100%' }}
                  >
                    <LoginOutlined />
                    Submit
                  </Button>
                  <br />
                  <br />
                  <Button
                    type='primary'
                    danger
                    style={{ width: '100%' }}
                    onClick={() => setRequireTotp(false)}
                  >
                    <ArrowLeftOutlined />
                    Back to login
                  </Button>
                </div>
              </div>
            </form>
          </Card>
        ) : (
          <Card style={{ padding: '1rem' }} variant='borderless'>
            <div style={{ textAlign: 'center' }}>
              <Title level={3}>Log in</Title>
            </div>
            <form onSubmit={handleSubmit}>
              <Input name='email' required placeholder='Email' />
              <div style={{ marginTop: '1rem' }}></div>

              <Input.Password
                name='password'
                required
                placeholder='Password'
                type='password'
              />

              <div style={{ marginTop: '1rem' }}></div>

              <div style={{ textAlign: 'center', width: "100%" }}>
                <Button
                  style={{ width: "100%" }}
                  loading={loginInProgress}
                  htmlType='submit'
                  type='primary'
                  disabled={loginInProgress}
                >
                  <LoginOutlined />
                  Log In
                </Button>

                {/* <div>
                  <Text>Forgot password?</Text>
                  <Button disabled={loginInProgress} href='#' type='link'>
                    Reset
                  </Button>
                </div> */}
              </div>
            </form>
          </Card>
        )}
      </Space>
    </ConfigProvider>
  );
}
