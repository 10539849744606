const permissions = {
  pro: {
    timesheets: true,
    quests: true,
    departments: true,
  },
  free: {
    timesheets: true,
    quests: true,
    departments: true,
  },
  standard: {
    timesheets: true,
    quests: true,
    departments: false,
  },
};

export const getPermissions = (key: 'pro' | 'free' | 'standard') => {
  return permissions[key];
};
