import { useEffect, useCallback, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import CodeBlockLowLight from '@tiptap/extension-code-block-lowlight';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import Dropcursor from '@tiptap/extension-dropcursor';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Strike from '@tiptap/extension-strike';
import Highlight from '@tiptap/extension-highlight';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import suggestions from './editor3Tools/suggestions';
import { api } from '../../api';
import TipTapEditorToolbar from './editor3components/toolbar';
import { App, Button, Tag } from 'antd';
import DownloadNode from './ext/Download';
import CustomLink from './ext/govno';
import ImageResize from 'tiptap-extension-resize-image';
import mentionSuggestions from './mentions';
import Text from 'antd/es/typography/Text';

import * as lowlight from 'lowlight';
import { AuthImage } from './CustomNodes/ImageNode';
import { AuthFile } from './CustomNodes/FileNode';
import useMessage from 'antd/es/message/useMessage';

const lw = lowlight.createLowlight(lowlight.all);

export const getExtensions = (
  projectKey: string,
  {
    disableMentions,
  }: {
    disableMentions: boolean;
  }
): any => {
  const extensions = [
    StarterKit,
    Underline,
    Highlight,
    Superscript,
    Subscript,
    TextStyle,
    Color,
    CodeBlockLowLight.configure({
      lowlight: lw,
    }),
    TaskList,
    TaskItem,
    ImageResize,
    AuthImage,
    AuthFile,
    Table.configure({
      HTMLAttributes: {
        class: 'table',
      },
    }),
    TableRow.configure({
      HTMLAttributes: {
        class: 'table-row',
      },
    }),
    TableHeader.configure({
      HTMLAttributes: {
        class: 'table-header',
      },
    }),
    TableCell.configure({
      HTMLAttributes: {
        class: 'table-cell',
      },
    }),
    Emoji.configure({
      emojis: gitHubEmojis,
      suggestion: suggestions,
      enableEmoticons: true,
    }),
    DownloadNode,
    CustomLink,
  ];

  if (!disableMentions) {
    extensions.push(
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        renderHTML(props) {
          return [
            'a',
            { class: 'mention', 'data-mention-id': props.node.attrs.id },
            `@${props.node.attrs.label}`,
          ];
        },
        suggestion: {
          ...mentionSuggestions,
          items: async ({ query }) => {
            const response = await api.get(`/api/projects/${projectKey}/users`);

            return response.data
              .map((user: any) => ({
                id: user.id,
                label: user.fullName,
              }))
              .filter((user: any) =>
                user.label.toLowerCase().includes(query.toLowerCase())
              );
          },
        },
      })
    );
  }

  return extensions;
};

const Editor = ({
  onSave,
  onChange,
  defaultContent,
  saveText = 'Save',
  clearOnSave = false,
  editable = true,
  onCancel,
  projectKey,
  disableMentions = false,
  maxHeight,
}: {
  onSave?: any;
  onChange?: any;
  defaultContent: any;
  saveText?: any;
  clearOnSave?: any;
  editable?: any;
  onCancel?: any;
  projectKey?: any;
  disableMentions?: any;
  maxHeight?: any;
}) => {
  const [selectedHeading, setSelectedHeading]: any = useState(null);
  const [content, setContent] = useState(null);

  const [messageApi, contextHolder] = useMessage();

  const { message, modal, notification } = App.useApp();

  // const fetchSuggestions = async (): Promise<any> => {
  //   if (!projectKey) return [];
  //   const response = await api.get(`/api/projects/${projectKey}/users`);

  //   return response.data.map((user: any) => ({
  //     id: user.id,
  //     label: user.fullName,
  //   }));
  // };

  // const { data, isLoading } = useQuery({
  //   queryKey: ['suggestions', projectKey],
  //   queryFn: fetchSuggestions,
  // });

  const editor = useEditor({
    ...(onChange && {
      onUpdate: ({ editor }) => {
        try {
          const content = JSON.stringify(editor.getJSON());
          onChange(content);
        } catch (error) {
          console.error('Error updating content:', error);
        }
      },
    }),
    extensions: getExtensions(projectKey, { disableMentions }),
    onSelectionUpdate(props) {
      if (props.editor.getAttributes('heading')) {
        setSelectedHeading(props.editor.getAttributes('heading'));
      }
    },
    parseOptions: {
      to: 'json',
    },
    content,
  });

  const handlePaste = async (event: any) => {
    event.preventDefault();
    const items = event.clipboardData.items;

    for (const item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file.size > 1024 * 1024 * 100) {
          modal.warning({
            title: 'File too large',
            content: 'File size must be less than 100 MB',
            zIndex: 9999999999,
          });
          return;
        }
        const reader = new FileReader();
        reader.onload = async (event) => {
          const fileType = file.type.split('/')[0];
          if (fileType === 'image') {
            editor
              ?.chain()
              .focus()
              .setAuthImage(null, event.target?.result)
              .run();
            editor?.chain().focus().enter().run();
            editor?.chain().focus().run();
          } else {
            const fileName = file.name;
            editor
              ?.chain()
              .focus()
              .setAuthFile(null, fileName, event.target?.result)
              .run();
            editor?.chain().focus().enter().run();
            editor?.chain().focus().run();
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      const files = event.dataTransfer.files;

      for (const file of files) {
        if (file.size > 1024 * 1024 * 100) {
          modal.warning({
            title: 'File too large',
            content: 'File size must be less than 100 MB',
            zIndex: 9999999999,
          });
          return;
        }
        const reader = new FileReader();
        reader.onload = async () => {
          const base64 = reader.result;
          const fileType = file.type.split('/')[0];

          if (fileType === 'image') {
            editor?.chain().focus().setAuthImage(null, base64).run();
            editor?.chain().focus().enter().run();
            editor?.chain().focus().run();
          } else {
            const fileName = file.name;
            editor?.chain().focus().setAuthFile(null, fileName, base64).run();
            editor?.chain().focus().enter().run();
            editor?.chain().focus().run();
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [editor]
  );

  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.setEditable(editable);
  }, [editor, editable]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    if (defaultContent) {
      try {
        const content = JSON.parse(defaultContent);
        setContent(content);
      } catch (error) {
        const content = '';
        editor.commands.setContent(content);
      }
    }
  }, [editor, defaultContent]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.commands.setContent(content, true);
  }, [editor, content]);

  if (!editor) {
    return null;
  }
  // do this last
  editor.options.editorProps = {
    attributes: {
      class: editable ? 'editor' : '',
    },
    handlePaste: (view, event, slice) => {
      handlePaste(event);
      return false;
    },
    handleDrop: (view, event) => {
      handleDrop(event);
      return false;
    },
    // add cmd + enter to save
    handleDOMEvents: {
      keydown: (view, event) => {
        if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
          event.preventDefault();
          if (onSave) {
            if (editor.isEmpty) {
              return
            }
            const content = JSON.stringify(editor.getJSON());
            onSave(content);
            if (clearOnSave) {
              editor.commands.clearContent();
            }
          }
        }
      },
    }
  };

  return (
    <div>
      {contextHolder}
      {editable && (
        <TipTapEditorToolbar
          editor={editor}
          selectedHeading={selectedHeading}
          setSelectedHeading={setSelectedHeading}
        />
      )}
      <EditorContent
        editor={editor}
        style={{ maxHeight: maxHeight ?? '55vh', overflow: 'auto' }}
      />
      <div

        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '.5rem',
        }}
      >

        {editable && onSave && (
          <div
          >
            {
              <Text style={{
                fontSize: '12px',
              }} type='secondary'>Tip:
                <Tag
                  style={{
                    marginLeft: '5px',
                    fontSize: '12px',
                    color: 'gray',
                  }}
                  bordered={false}>
                  {(navigator.platform.includes('Mac') ? '⌘' : 'Ctrl') + ' + Enter'}
                </Tag> to {saveText}
              </Text>
            }
          </div>)
        }
        {editable && (
          <div style={{ display: 'flex', gap: '.5rem', justifyContent: 'end' }}>
            {onCancel && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (defaultContent) {
                      const dc = JSON.parse(defaultContent);
                      editor.commands.setContent(dc);
                    }
                    onCancel(e);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {onSave && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  onClick={async (e) => {
                    try {
                      e.stopPropagation();
                      if (editor.isEmpty) {
                        return
                      }
                      const content = JSON.stringify(editor.getJSON());
                      await onSave(content);
                      if (clearOnSave) {
                        editor.commands.clearContent();
                      }
                    } catch (error) {
                      console.error('Error saving content:', error);
                    }
                  }}
                >
                  {saveText}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Editor;
