import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  App,
  Avatar,
  Button,
  Card,
  Collapse,
  CollapseProps,
  Upload,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { UploadChangeParam } from 'antd/es/upload';
import { useState } from 'react';
import { api } from '../../api';
import UserAvatar from '../../components/UserAvatar';
import { useQuery } from '@tanstack/react-query';
import Text from 'antd/es/typography/Text';
import BasicInfo from './components/BasicInfo';
import Security from './components/Security';
import { NotificationSettings } from './components/NotificationSettings';
import axios from 'axios';

export default function AccountSettings() {
  const [loading, setLoading] = useState(false);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);

  const { modal } = App.useApp();

  const getMe = async () => {
    try {
      const response = await api.get(`/api/me`);
      setImageKey(response.data.avatar);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data,
    isLoading: isLoadingUser,
    error,
  } = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
  });

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Basic Information',
      children: <BasicInfo fullName={data.fullName} />,
    },
    {
      key: '2',
      label: 'Security',
      children: <Security totpEnabled={data.totpEnabled} />,
    },
    {
      key: '3',
      label: 'Notification Settings',
      children: <NotificationSettings />,
    },
    {
      key: '4',
      label: 'Danger Zone',
      children: (
        <div>
          <Button type='primary' danger>
            Deactivate Account
          </Button>
        </div>
      ),
    },
  ];

  const handleUploadFile = async (file: any) => {
    const reader = new FileReader();
    reader.onload = async () => {
      // Upload the file to the backend using Axios
      const formData = new FormData();

      try {
        const response = await api.post('/upload/presigned-url/avatar');

        Object.entries(response.data.fields).forEach(([key, value]) => {
          formData.append(key, value as string);
        });

        formData.append('file', file);

        await axios.post(`${response.data.url}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const key = response.data.fileKey;

        setImageKey(key);

        await api.post('/upload/complete/avatar', {
          fileKey: key,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleChange = async (info: UploadChangeParam) => {
    try {
      await handleUploadFile(info.file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <Text style={{ marginTop: 8 }}>Upload</Text>
    </button>
  );

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      modal.error({
        title: 'Error',
        content: 'You can only upload JPG/PNG file!',
      });
      return;
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      modal.error({
        title: 'Error',
        content: 'Image must smaller than 5MB!',
      });
    }
    return false;
  };

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Title level={4}>User Settings</Title>
      </div>
      <Card style={{ textAlign: 'center' }}
        variant='borderless'
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '.5rem',
          }}
        >
          {imageKey && (
            <div style={{ textAlign: 'center' }}>
              <UserAvatar token={imageKey} size={124} />
              <br />
              <Text type='secondary'>max. 2MB</Text>
              <div style={{ marginTop: '.5rem' }}></div>
              <Upload
                name='avatar'
                listType='text'
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                accept='image/*'
              >
                <Button size='small' icon={<UploadOutlined />}>
                  Change
                </Button>
              </Upload>
            </div>
          )}
          {!imageKey && (
            <div style={{ textAlign: 'center' }}>
              <Upload
                name='avatar'
                listType='picture-circle'
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                accept='image/*'
              >
                {uploadButton}
              </Upload>
              <Text type='secondary'>max. 2MB</Text>
            </div>
          )}
        </div>

        <Title level={4}>{data.fullName}</Title>
      </Card>
      <br />
      <Collapse items={items} bordered={false} />
    </div>
  );
}
