import React from 'react';
import {
  Button,
  Card,
  Dropdown,
  Input,
  Modal,
  Popconfirm,
  Skeleton,
  Tree,
  message,
  Tooltip,
} from 'antd';
import type { TreeDataNode, TreeProps } from 'antd';
import { api } from '../../../../api';
import { useProjectStore } from '../../../../state/projectState';
import Text from 'antd/es/typography/Text';
import { DatabaseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '@/state/userState';
import { CustomIcon } from '@/components/Icon';
import Editor from '@/components/Editor/index3';
import { AxiosError } from 'axios';

const ProjectPagesTree = ({
  setSelectedPage,
  treeData,
  isLoading,
  data,
  refetchTree,
  activeButton,
  setActiveButton,
}: {
  setSelectedPage: any;
  selectedPage: any;
  treeData: TreeDataNode[];
  isLoading: boolean;
  data: any;
  refetchTree: any;
  activeButton: string;
  setActiveButton: any;
}) => {
  const projectStore = useProjectStore();
  const [createPageModalVisible, setCreatePageModalVisible] =
    React.useState(false);

  const navigate = useNavigate();

  const { pageId } = useParams();

  const [label, setLabel] = React.useState('');
  const [newContent, setNewContent] = React.useState('');

  const [parentId, setParentId] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');

  const [messageApi, contextHolder] = message.useMessage();

  const { user } = useUserStore();

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    setSelectedPage(info.node);
  };

  // New function to handle drag and drop
  const onDrop: TreeProps['onDrop'] = async (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const isSameLevel = info.dropToGap;
    const isFirst = info.dropPosition === -1;
    try {
      if (isSameLevel) {
        await api.put(`/api/pages/${dragKey}/move`, {
          parentId: info.node?.parentId,
          beforeId: isFirst ? dropKey : null,
          afterId: isFirst ? null : dropKey,
        });
      } else {
        await api.put(`/api/pages/${dragKey}/move`, {
          parentId: dropKey,
          beforeId: null,
          afterId: dropKey,
        });
      }

      // Refresh the tree data
      refetchTree(); // TODO: This should be done optimistically
      messageApi.success('Page order updated successfully');
    } catch (error) {
      console.error('Failed to update page order:', error);
      messageApi.error('Failed to update page order');
    }
  };

  if (isLoading) return <Skeleton style={{ minWidth: 220 }} active />;

  const searchPages = (treeData: TreeDataNode[], searchText: string): any => {
    if (!searchText || searchText === "") return treeData;
    return treeData
      .filter((node) => {
        // Check if the node's title matches the search text
        const title = node.title?.toLowerCase();
        const search = searchText.toLowerCase();
        if (title && title.includes(search)) return true;
        // If the title doesn't match, check the children

        if (node.children) {
          // Recursively search the children
          const children = searchPages(node.children, searchText);
          // If any of the children match the search text, return the parent node
          if (children.length) return true;
        }
      }
      )
      .map((node) => {
        return {
          ...node,
          children: searchPages(node.children, searchText),
        };
      });
  }

  const tree = searchPages(data, searchText);

  return (
    <div>
      {contextHolder}
      <div
        style={{
          marginBottom: ".5rem"
        }}
      >
        <Button.Group
          style={{ width: "100%", marginBottom: '.5rem' }}
        >
          <Button
            style={{ width: "50%" }}
            color={
              activeButton === 'active'
                ? 'primary'
                : 'default'
            }
            variant='filled'
            onClick={() => {
              setActiveButton('active');
            }} >Active</Button>
          <Button
            color={
              activeButton === 'archived'
                ? 'primary'
                : 'default'
            }
            variant='filled'
            style={{ width: "50%" }}
            onClick={() => {
              setActiveButton('archived');
            }} >Archived</Button>
        </Button.Group>
        <div style={{ display: "flex", gap: '.5rem' }}>
          <div
            style={{ flex: 1 }}>
            <Input placeholder='Search pages'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
              variant='filled'
            />
          </div>
        </div>
      </div>

      {tree?.length === 0 && (
        <div>
          <Card variant='borderless' style={{ textAlign: 'center', padding: '1rem', minWidth: 250 }}>
            <Text>No pages</Text>
          </Card>
          <Button
            style={{ width: "100%", marginTop: ".5rem" }}
            variant='text'
            type='text'
            onClick={() => {
              setCreatePageModalVisible(true);
            }}
          >
            <PlusOutlined /> Add page
          </Button>
        </div>
      )}
      {
        tree?.length > 0 && (
          <>
            <Tree
              style={{ minWidth: 250, padding: '.3rem' }}
              onSelect={onSelect}
              treeData={tree}
              blockNode
              defaultSelectedKeys={pageId ? [pageId] : undefined}
              defaultExpandedKeys={pageId ? [pageId] : undefined}
              draggable={true}
              onDrop={onDrop} // Handle drop events
              titleRender={(nodeData: any) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setSelectedPage(nodeData);
                      navigate(
                        `/project/${projectStore.project?.key}/pages/${nodeData.key}`
                      );
                    }}
                  >
                    <Text>{nodeData.title ?? ''}</Text>
                    <Dropdown
                      trigger={['click']}
                      menu={{
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                        },
                        items: [
                          !nodeData.archivedAt ? {
                            key: 'edit',
                            label: 'Add child page',
                            icon: <PlusOutlined />,
                            onClick: () => {
                              setParentId(nodeData.key);
                              setCreatePageModalVisible(true);
                            },
                          } : undefined!,
                          user?.isAdmin || user?.isManager
                            ? {
                              label: (
                                <Popconfirm
                                  title={`Are you sure? all child pages will be ${nodeData.archivedAt ? 'un' : ''}archived too.`}
                                  onConfirm={async () => {
                                    try {
                                      await api.put(
                                        `/api/pages/${nodeData.key}/archive`
                                      );
                                      refetchTree();
                                      messageApi.success('Page Archived!');
                                    } catch (error) {
                                      console.log(error, ':(');
                                    }
                                  }}
                                  okText='Yes'
                                  cancelText='No'
                                  okType={nodeData.archivedAt ? 'primary' : 'danger'}
                                >
                                  <Text
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                      }}
                                    >
                                      <DatabaseOutlined />
                                      {nodeData.archivedAt ? 'Unarchive' : 'Archive'}
                                    </div>
                                  </Text>
                                </Popconfirm>
                              ),
                              danger: Boolean(!nodeData.archivedAt),
                              key: '3',
                              onClick: async () => { },
                            }
                            : undefined!,
                          user?.isAdmin || user?.isManager
                            ? {
                              label: (
                                <Popconfirm
                                  title='Are you sure? all child pages will be deleted too.'
                                  onConfirm={async () => {
                                    try {
                                      await api.delete(
                                        `/api/pages/${nodeData.key}`
                                      );
                                      refetchTree();
                                      messageApi.success('Page Deleted!');
                                    } catch (error) {
                                      console.log(error, ':(');
                                    }
                                  }}
                                  okText='Yes'
                                  cancelText='No'
                                  okType='danger'
                                >
                                  <Text
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                      }}
                                    >
                                      <DeleteOutlined />
                                      Delete
                                    </div>
                                  </Text>
                                </Popconfirm>
                              ),
                              danger: true,
                              key: '4',
                              onClick: async () => { },
                            }
                            : undefined!,
                        ],
                      }}
                    >
                      <Button
                        type='text'
                        // size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <CustomIcon icon='material-symbols:more-horiz' />
                      </Button>
                    </Dropdown>
                  </div>
                );
              }}
            />

            <div style={{ textAlign: 'right', marginTop: ".5rem" }}>
              <Button
                style={{ width: "100%" }}
                variant='text'
                type='text'
                onClick={() => {
                  setCreatePageModalVisible(true);
                }}
              >
                <PlusOutlined /> Add page
              </Button>
            </div>
          </>
        )}
      {
        createPageModalVisible && (
          <Modal
            open={createPageModalVisible}
            footer={null}
            width={"55vw"}
            style={{ top: 20 }}
            onCancel={() => {
              setCreatePageModalVisible(false);
            }}
            onClose={() => {
              setCreatePageModalVisible(false);
            }}
          >
            <div>Create {parentId ? 'child' : ''} page</div>
            <br />
            <Text>Label</Text>
            <Input
              value={label}
              autoFocus={true}
              variant='filled'
              placeholder='Page label'
              onChange={(e) => {
                setLabel(e.target.value);
              }}
            />
            <br />
            <br />
            <Editor
              disableMentions={true}
              defaultContent={newContent}
              onChange={(content) => setNewContent(content)}
            />
            <br />
            <Button
              onClick={async () => {
                try {

                  const response = await api.post(
                    `/api/projects/${projectStore.project?.id}/pages`,
                    {
                      label,
                      parentId,
                      content: newContent,
                    }
                  );

                  messageApi.success('Page Created!');
                  refetchTree();
                  setLabel('');
                  setNewContent('');
                  setParentId(null);
                  setCreatePageModalVisible(false);

                } catch (e) {
                  if (e instanceof AxiosError) {
                    messageApi.error(e.response?.data?.message ?? 'Failed to create page');
                    return;
                  }
                  messageApi.error('Failed to create page, we are working on it');
                }
              }}
              type='primary'
            >
              Create
            </Button>
          </Modal>
        )
      }

    </div>
  );
};

export default ProjectPagesTree;
