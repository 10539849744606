import { COLUMN_TYPE_IDS } from "./columnType";

const random3Letters = () =>
  Math.random().toString(36).substring(2, 5).toUpperCase();

export function getProjectTemplates() {
  return {
    marketing: {
      label: "Marketing Agency",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Strategy",
          columns: [
            {
              label: "Research",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Campaign Planning",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Published",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Execution",
          columns: [
            {
              label: "Content Ideas",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Drafting",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Scheduling",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Live",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Analysis",
          columns: [
            {
              label: "Past Campaigns",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Data Collection",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Performance Review",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Report Sent",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    sofwareDevelopemnt: {
      label: "Software Development",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Product Development",
          columns: [
            {
              label: "Backlog",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Todo",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "In Progress",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Done",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Testing & QA",
          columns: [
            {
              label: "Backlog",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "To Test",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Testing",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Passed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Support",
          columns: [
            {
              label: "User Reports",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "To Reproduce",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Fix & Verify",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Resolved",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    creativeDesign: {
      label: "Creative Design",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Design",
          columns: [
            {
              label: "Ideas",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Sketch",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Designing",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Finalized",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Client Review",
          columns: [
            {
              label: "Pending",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Review",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Feedback",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Approved",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Delivery",
          columns: [
            {
              label: "Packaging",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Shipping",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Delivery",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Delivered",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    consulting: {
      label: "Consulting",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Client Management",
          columns: [
            {
              label: "Onboarding",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Active",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Review",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Consulting",
          columns: [
            {
              label: "Research",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Consulting",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Implementation",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Billing",
          columns: [
            {
              label: "Pending",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Invoiced",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Paid",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    eventPlaning: {
      label: "Event Planing",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Planning",
          columns: [
            {
              label: "Ideas",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Planning",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Logistics",
          columns: [
            {
              label: "Venue",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Catering",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Transport",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Marketing",
          columns: [
            {
              label: "Promotion",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Marketing",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    publicRelations: {
      label: "Public Relations",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Media Relations",
          columns: [
            {
              label: "Press Releases",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Media Outreach",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Interviews",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Published",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Events",
          columns: [
            {
              label: "Planning",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Crisis Management",
          columns: [
            {
              label: "Risk Assessment",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Response Plan",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Resolved",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    humanResources: {
      label: "Human Resources",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Recruitment",
          columns: [
            {
              label: "Job Posting",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Interviews",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Hiring",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Onboarding",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Training",
          columns: [
            {
              label: "Needs Assessment",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Training Plan",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Employee Relations",
          columns: [
            {
              label: "Feedback",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Resolution",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    finance: {
      label: "Finance",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Budgeting",
          columns: [
            {
              label: "Forecasting",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Planning",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Accounts",
          columns: [
            {
              label: "Receivable",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Payable",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Reporting",
          columns: [
            {
              label: "Data Collection",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Analysis",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Report",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    sales: {
      label: "Sales",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Leads",
          columns: [
            {
              label: "Prospects",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Contacted",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Negotiation",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Closed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Follow Up",
          columns: [
            {
              label: "Pending",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Contacted",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Support",
          columns: [
            {
              label: "Customer Requests",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "In Progress",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    customerService: {
      label: "Customer Service",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Tickets",
          columns: [
            {
              label: "New",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "In Progress",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Resolved",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Feedback",
          columns: [
            {
              label: "Survey",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Review",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Support",
          columns: [
            {
              label: "Customer Requests",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "In Progress",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },

    projectManagement: {
      label: "Project Management",
      description: "This is a template project",
      key: `${random3Letters()}`,
      isPublic: true,
      slackChannelId: null,
      userIds: [],
      boards: [
        {
          label: "Planning",
          columns: [
            {
              label: "Ideas",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Planning",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Execution",
              typeId: COLUMN_TYPE_IDS.in_progress,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Execution",
          columns: [
            {
              label: "Tasks",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "In Progress",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
        {
          label: "Review",
          columns: [
            {
              label: "Feedback",
              typeId: COLUMN_TYPE_IDS.backlog,
            },
            {
              label: "Review",
              typeId: COLUMN_TYPE_IDS.todo,
            },
            {
              label: "Completed",
              typeId: COLUMN_TYPE_IDS.done,
            },
          ],
        },
      ],
    },
  };
}
