import { Button, Cascader, Popconfirm, Select, Table, Tooltip } from 'antd';
import { api } from '@/api';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import { ColumnType } from 'antd/es/table';
import { useSearchParams } from 'react-router-dom';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import { MinusSquareOutlined } from '@ant-design/icons';

export default function SubtaskTable({
  task,
  refetch,
  columns,
  priorities,
  data,
  messageApi,
  availableStatuses,
}: {
  task: any;
  refetch: any;
  columns: any;
  priorities: any;
  data: any;
  messageApi: any;
  availableStatuses: any[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const tableColumns: ColumnType<any>[] = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      render(value, record, index) {
        return (
          <Button
            type='link'
            onClick={() => {
              searchParams.append('openTask', record.key);
              setSearchParams(searchParams);
            }}
          >
            {value}
          </Button>
        );
      },
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      // width: 250,
    },
    {
      render: (value, record, index) => {
        const board = availableStatuses?.find((board: any) =>
          board.statuses.find((column: any) => column.id === record.columnId)
        );

        return (
          <Cascader
            defaultValue={[board?.boardLabel, record.columnId]}
            style={{
              width: '100%',
            }}
            showSearch
            variant='borderless'
            allowClear={false}
            onClick={(e) => {
              e.stopPropagation();
            }}
            placeholder='Move to'
            options={
              availableStatuses?.map((board: any) => ({
                value: board.boardLabel,
                label: board.boardLabel,
                children: board.statuses.map((column: any) => ({
                  value: column.id,
                  label: column.label,
                })),
              })) ?? []
            }
            onChange={async (value) => {
              try {
                await api.post(`/api/tasks/${record.id}/move`, {
                  newColumnId: value[1],
                  beforeId: 'first',
                });
                messageApi.success('Task Moved!');
              } catch (error) {
                console.log(error, ':(');
              }
            }}
          />
        );
      },

      key: 'columnId',
      dataIndex: 'columnId',
    },

    {
      title: 'Type',
      dataIndex: 'taskTypeId',
      key: 'taskTypeId',
      // width: 150,
      render(value, record, index) {
        // get all types from backend
        return (
          <Select
            value={value}
            size='small'
            variant='borderless'
            disabled={!!record.archivedAt}
            style={{
              width: '100%',
            }}
            onChange={async (value) => {
              await api.put(`/api/tasks/${record.id}`, {
                taskTypeId: value,
              });
              messageApi.success('Task type updated!');
              refetch();
            }}
            labelRender={(value: any) => {
              return <>{getTaskTypeIcon(value.label?.toLowerCase() as any)} </>;
            }}
            optionRender={(option: any) => (
              <div style={{ display: 'flex', gap: '.3rem' }}>
                {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
            options={[
              {
                value: 'ttkefwc4qtaaogcxrkyu98x0',
                label: 'Task',
              },
              {
                value: 'tzz06j7svw51umjdd0ov5ey8',
                label: 'Bug',
              },
              {
                value: 'w38qzcuzxm81h4jarp4aog9g',
                label: 'Story',
              },
            ]}
          />
        );
      },
    },
    {
      title: 'Priority',
      dataIndex: 'priorityId',
      key: 'priorityId',
      // width: 150,
      render(value, record, index) {
        // get all types from backend
        return (
          <Select
            value={value}
            variant='borderless'
            size='small'
            disabled={!!record.archivedAt}
            style={{
              width: '100%',
            }}
            onChange={async (value) => {
              await api.put(`/api/tasks/${record.id}`, {
                priorityId: value,
              });
              messageApi.success('Task priority updated!');
              refetch();
            }}
            labelRender={(value: any) => {
              return (
                <>{getTaskPriorityIcon(value.label?.toLowerCase() as any)} </>
              );
            }}
            optionRender={(option: any) => (
              <div>
                {getTaskPriorityIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
            options={priorities.map((p: any) => ({
              value: p.id,
              label: p.label,
            }))}
          />
        );
      },
    },
    {
      title: 'Assignee',
      dataIndex: 'assigneeId',
      key: 'assigneeId',
      // width: 50,
      render(value, record, index) {
        return (
          <div style={{ textAlign: 'center' }}>
            <QuickChangeAssignee
              disabled={!!record.archivedAt}
              taskId={record.id}
              currentAssigneeId={record.assigneeId}
              projectKey={task.projectKey}
              avatarSize={33}
              showToolTip={true}
              onChangeAssignee={() => {
                messageApi.success('Task reassigned!');
                refetch();
              }}
              tooltipName={record.assigneeFullName ?? 'Unassigned'}
            />
          </div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      // width: 50,
      render(value, record, index) {
        return (
          <Tooltip title='Detach subtask'>
            <Popconfirm
              title='Are you sure you want to detach this subtask?'
              onConfirm={async () => {
                await api.delete(`/api/tasks/${record.id}/detach`);
                messageApi.success('Subtask detached');
                refetch();
              }}
              okText='Yes'
              cancelText='No'
            >
              <Button size='small' danger type='link'>
                <MinusSquareOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      {data?.length > 0 && (
        <Table
          showHeader={false}
          size='small'
          columns={tableColumns}
          dataSource={data}
          bordered
          pagination={false}
        />
      )}
    </div>
  );
}
