import React, { useEffect } from 'react';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  MoreOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Progress,
  Select,
  Skeleton,
  Space,
  TableColumnsType,
  Tag,
} from 'antd';
import { Button, Card, DatePicker, Input, Modal, Table } from 'antd';
import Text from 'antd/es/typography/Text';
import Editor from '@/components/Editor/index3';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import AddOrCreateTask from './addOrCreateTask';
import { useUserStore } from '@/state/userState';
import useApp from 'antd/es/app/useApp';
import { AxiosError } from 'axios';

const Quests: React.FC = () => {
  const [showCreateTaskModal, setShowCreateTaskModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { projectKey } = useParams();

  const navigate = useNavigate();

  const [selectedQuestId, setSelectedQuestId] = React.useState(null);
  const [label, setLabel] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [descriptionOne, setDescriptionOne] = React.useState('');
  const [startDate, setStartDate]: any = React.useState();
  const [endDate, setEndDate]: any = React.useState();
  const [questTasks, setQuestTasks] = React.useState([] as any[]);
  const [showAddTask, setShowAddTask] = React.useState(false);
  const [showAddOrCreateTask, setShowAddOrCreateTask] = React.useState(false);

  const [filter, setFilter] = React.useState('active');

  const [editorOpen, setEditorOpen] = React.useState(false);

  const queryClient = useQueryClient();

  const { user } = useUserStore();

  const { data, refetch, isFetching, isLoading } = useQuery({
    queryKey: ['quests', projectKey],
    queryFn: async () => {
      const { data } = await api.get(
        `/api/projects/${projectKey}/quests?filter=${filter}`
      );
      return data;
    },
  });

  const getQuestTasks = async (questId: string) => {
    const { data } = await api.get(`/api/quests/${questId}/tasks`);
    setQuestTasks(data);
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const createQuest = async () => {
    setLoading(true);
    try {
      if (selectedQuestId) {
        await api.put(`/api/quests/${selectedQuestId}`, {
          label,
          description: descriptionOne,
          startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
          endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        });
        messageApi.success('Quest updated successfully');
      } else {
        await api.post(`/api/projects/${projectKey}/quests`, {
          label,
          description,
          startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
          endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        });
        messageApi.success('Quest created successfully');
      }
      await refetch();
      setLabel('');
      setDescription('');
      setStartDate(undefined);
      setEndDate(undefined);
      setQuestTasks([]);
      setShowCreateTaskModal(false);
      setSelectedQuestId(null);
    } catch (e) {
      messageApi.error('Failed to create quest');
      console.log(e);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Label', dataIndex: 'label', key: 'label',
      render: (text, record) => {
        return (
          <div>
            <strong>{text}</strong>
          </div>
        );
      },
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      render(value, record, index) {
        return (
          <>
            <Progress percent={
              +(((value / record.allTasks) * 100).toFixed(2))
            }
              percentPosition={{ align: 'center', type: 'inner' }}
              size={['100%', 15]}
            />
          </>
        );
      },
    },
    {
      title: 'Due In',
      dataIndex: 'dueIn',
      sorter: (a, b) => a.dueIn - b.dueIn,
      key: 'dueIn',
      render(value, record, index) {
        if (record.endDate) {
          return <>{value} days</>;
        }
        return <>/</>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text) => {
        if (text) {
          return <>{dayjs(text).format('DD.MM.YYYY')}</>;
        }
        return <>/</>;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => {
        if (text) {
          return <>{dayjs(text).format('DD.MM.YYYY')}</>;
        }
        return <>/</>;
      },
    },
    filter === 'completed' || filter === 'all'
      ? {
        title: 'Completed',
        sorter: (a, b) =>
          new Date(a.completedAt).getTime() -
          new Date(b.completedAt).getTime(),
        dataIndex: 'completedAt',
        key: 'completedAt',
        render: (text) => {
          if (text) {
            return <>{dayjs(text).format('DD.MM.YYYY')}</>;
          }
          return <>/</>;
        },
      }
      : {},
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   render: () => (
    //     <Space size='middle'>
    //       <Dropdown menu={{ items: [] }}>
    //         <a>
    //           Add <DownOutlined />
    //         </a>
    //       </Dropdown>
    //       <Dropdown menu={{ items: [] }}>
    //         <a>
    //           More <DownOutlined />
    //         </a>
    //       </Dropdown>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    if (selectedQuestId) {
      getQuestTasks(selectedQuestId);
    }
  }, [selectedQuestId]);

  const questPerms = {
    createOrUpdateOrDelete: user?.isAdmin || user?.isManager,
    read: true,
  };

  const data2 = Object.groupBy(questTasks, (t) => t.columnType);

  return (
    <>
      {contextHolder}

      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Space>
          Show:
          <Select
            defaultValue={'active'}
            value={filter}
            style={{ width: '150px' }}
            onChange={(value) => {
              setFilter(value);
            }}
          >
            <Select.Option value='all'>All</Select.Option>
            <Select.Option value='active'>Active</Select.Option>
            <Select.Option value='completed'>Completed</Select.Option>
          </Select>
          {questPerms.createOrUpdateOrDelete && (
            <Button
              onClick={() => {
                setShowCreateTaskModal(true);
              }}
              icon={<PlusCircleOutlined />}
            >
              Create new quest
            </Button>
          )}
        </Space>
      </div>
      <br />
      <Table
        loading={isFetching || isLoading}
        rowKey={(record) => record.id}
        columns={columns}
        size='small'
        dataSource={data}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div
                key={record.id}
                style={{
                  marginLeft: '1rem',
                  border: '1px solid #212121',
                  borderRadius: '4px',
                  margin: '1rem',

                }}
              >
                <QuestTasksTable questId={record.id} projectKey={
                  projectKey as string
                }
                  refetch={refetch}
                />
              </div>
            );
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelectedQuestId(record.id);
              setLabel(record.label);
              setDescription(record.description);
              setDescriptionOne(record.description);
              setStartDate(
                record.startDate
                  ? dayjs(record.startDate, 'YYYY-MM-DD')
                  : undefined
              );
              setEndDate(
                record.endDate ? dayjs(record.endDate, 'YYYY-MM-DD') : undefined
              );
              setShowCreateTaskModal(true);
              setShowAddTask(true);
            },
          };
        }}
      />
      {showCreateTaskModal && (
        <Modal
          open={showCreateTaskModal}
          width={'60vw'}
          title={<>{selectedQuestId ? 'Update' : 'Create new'} quest</>}
          style={{ top: 20 }}
          confirmLoading={loading}
          onCancel={() => {
            setLabel('');
            setDescription('');
            setDescriptionOne('');
            setStartDate(undefined);
            setEndDate(undefined);
            setQuestTasks([]);
            setShowCreateTaskModal(false);
            setSelectedQuestId(null);
          }}
          onClose={() => {
            setLabel('');
            setDescription('');
            setDescriptionOne('');
            setStartDate(undefined);
            setEndDate(undefined);
            setQuestTasks([]);
            setShowCreateTaskModal(false);
          }}
          footer={false}
        >
          <div style={{ marginTop: '1rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div></div>
              <div>
                {selectedQuestId && questPerms.createOrUpdateOrDelete && (
                  <Space>
                    <Popconfirm
                      title='Are you sure you want to complete this quest?'
                      onConfirm={async () => {
                        await api.put(
                          `/api/quests/${selectedQuestId}/complete`
                        );
                        await refetch();
                        setLabel('');
                        setDescription('');
                        setStartDate(undefined);
                        setEndDate(undefined);
                        setQuestTasks([]);
                        setShowCreateTaskModal(false);
                        setSelectedQuestId(null);
                      }}
                    >
                      <Button size='small'>
                        <CheckCircleOutlined />
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title='Are you sure you want to delete this quest?'
                      description="This action can't be undone"
                      onConfirm={async () => {
                        await api.delete(`/api/quests/${selectedQuestId}`);
                        await refetch();
                        setLabel('');
                        setDescription('');
                        setDescriptionOne('');
                        setStartDate(undefined);
                        setEndDate(undefined);
                        setQuestTasks([]);
                        setShowCreateTaskModal(false);
                        setSelectedQuestId(null);
                      }}
                    >
                      <Button danger size='small'>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </Space>
                )}
              </div>
            </div>
            <div>
              <Input
                value={label}
                disabled={!questPerms.createOrUpdateOrDelete}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
                placeholder='Quest name'
              />
            </div>
            <br />
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div>
                <DatePicker
                  format={{
                    format: 'DD.MM.YYYY',
                    type: 'mask',
                  }}
                  value={startDate ? dayjs(startDate) : null}
                  placeholder='Start Date'
                  disabled={!questPerms.createOrUpdateOrDelete}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  maxDate={endDate ? endDate : undefined}
                />
              </div>
              <div>
                <DatePicker
                  format={{
                    format: 'DD.MM.YYYY',
                    type: 'mask',
                  }}
                  minDate={startDate ? startDate : undefined}
                  disabled={!questPerms.createOrUpdateOrDelete}
                  value={endDate ? dayjs(endDate) : null}
                  placeholder='End Date'
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </div>
            </div>
            <br />
            <p>Summary</p>
            <div
              onClick={() => {
                setEditorOpen(true);
              }}
              onBlur={() => {
                setEditorOpen(false);
              }}
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                padding: '5px',
              }}
            >
              <Editor
                defaultContent={description}
                onChange={setDescriptionOne}
                editable={editorOpen && questPerms.createOrUpdateOrDelete}
                disableMentions
              />


            </div>
            <div style={{
              textAlign: 'right',
              marginTop: '1rem'
            }}>
              <Button
                type='primary'
                onClick={
                  questPerms.createOrUpdateOrDelete
                    ? () => {
                      createQuest();
                    }
                    : () => {
                      setLabel('');
                      setDescription('');
                      setDescriptionOne('');
                      setStartDate(undefined);
                      setEndDate(undefined);
                      setQuestTasks([]);
                      setShowCreateTaskModal(false);
                    }
                }
              >
                {selectedQuestId ? 'Update' : 'Create'}
              </Button>
            </div>
            <br />
            {/* <br />
            {showAddTask &&
              questPerms.createOrUpdateOrDelete &&
              selectedQuestId && (
                <Button
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    setShowAddOrCreateTask(!showAddOrCreateTask);
                    // show create or add new task;
                  }}
                >
                  Add task
                </Button>
              )} */}
            {/* {showAddTask && questPerms.createOrUpdateOrDelete && <br />}
            {showAddOrCreateTask &&
              selectedQuestId &&
              questPerms.createOrUpdateOrDelete && (
                <>
                  <br />
                  <AddOrCreateTask
                    questId={selectedQuestId}
                    refetch={getQuestTasks}
                  />
                  <br />
                </>
              )} */}
            {showAddTask && questPerms.createOrUpdateOrDelete && <br />}
            {selectedQuestId && (
              <QuestTasksTable questId={selectedQuestId} projectKey={
                projectKey as string
              }
                refetch={refetch}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export function QuestTasksTable({ questId, projectKey, refetch }: { questId: string, projectKey: string, refetch?: () => void }) {

  const { message } = useApp();

  const getQuestTasks = async (questId: string) => {
    const { data } = await api.get(`/api/quests/${questId}/tasks`);
    return data;
  };

  const { data: questTasks, refetch: refetchTasks, isLoading } = useQuery({
    queryKey: ['questTasks', questId],
    queryFn: () => getQuestTasks(questId),
  });

  const navigate = useNavigate();

  if (isLoading) {
    return <>
      <Skeleton />
      <br />
      <Skeleton />
      <br />
      <Skeleton />
    </>
  }

  const groupedTasks = Object.groupBy(questTasks, (t: any) => t.columnType);

  const columns2 = [
    {
      title: 'Task',
      width: 350,
      dataIndex: 'label',
      key: 'taskLabel',
      render: (text: string, record: any) => {
        return (
          <div>
            <Tag>{record.key}</Tag> {record.label}
          </div>
        );
      },
    },
    {
      title: 'Status',
      width: 350,
      dataIndex: 'columnLabel',
      key: 'dueDate',
      render(value: any, record: { boardLabel: any; }, index: any) {
        return (
          <>
            {record.boardLabel} {' - '} {value}
          </>
        );
      },
    },
    {
      title: 'Asignee',
      dataIndex: 'assigneeId',
      key: 'assigneeId',
      render(value: any, record: { taskId: string; assigneeId: string; }, index: any) {
        return (
          <div style={{ textAlign: 'center' }}>
            <QuickChangeAssignee
              taskId={record.taskId}
              currentAssigneeId={record.assigneeId}
              projectKey={projectKey}
              avatarSize={33}
              disabled
              showToolTip={true}
            />
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render(value: any, record: { id: any; }, index: any) {
        return (
          <Popconfirm
            title='Are you sure you want to detach this task?'
            onConfirm={async (e) => {
              try {
                e?.stopPropagation();
                await api.put(`/api/tasks/${record.id}`, {
                  questId: 'remove',
                });
                message.success('Task detached from quest');
                await refetchTasks();
                if (refetch) {
                  refetch();
                }
              } catch (error) {
                console.error(error);
                if (error instanceof AxiosError) {
                  if (error?.response?.data?.message) {
                    message.error(error.response.data.message);
                    return;
                  }
                }
                message.error('Failed to detach task from quest');
              }
            }}
            okText='Yes'
            cancelText='No'
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
              }}
              type='link'
              danger
            >
              Detach
            </Button>
          </Popconfirm>
        );
      },
    },
  ]

  const onRow = (record: any, rowIndex: any) => {
    return {
      onClick: (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(`?openTask=${record.key}`);
      }
    };
  }

  return (
    <>
      <div
        style={{
          marginBottom: '1rem'
        }}
      >
        <AddOrCreateTask
          questId={questId}
          refetch={() => {
            refetchTasks();
            if (refetch) {
              refetch();
            }
          }}
        />
      </div>
      <Table
        size='small'
        title={() => (
          <Text strong>
            <Tag color='blue'>
              Todo
            </Tag>{groupedTasks['To Do']?.length ?? 0}
          </Text>
        )}
        columns={columns2}
        onRow={onRow}
        bordered
        dataSource={groupedTasks['To Do'] || []}
      />
      <Table
        size='small'
        onRow={onRow}
        title={() => (
          <Text strong>
            <Tag
              color='orange'
            >
              In Progress
            </Tag>{groupedTasks['In Progress']?.length ?? 0}
          </Text>
        )}
        columns={columns2}
        bordered
        dataSource={groupedTasks['In Progress'] || []}
      />
      <Table
        size='small'
        onRow={onRow}
        title={() => (
          <Text strong>
            <Tag color='green'>
              Done
            </Tag>{groupedTasks['Done']?.length ?? 0}
          </Text>
        )}
        columns={columns2}
        bordered
        dataSource={groupedTasks['Done'] || []}
      />
    </>
  );

}

export default Quests;
