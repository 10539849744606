import { useShowUpgradeModal } from "@/state/options";
import axios, { AxiosError } from "axios";
import { sseClient } from "./realtime";

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

// Store the promise for refreshing tokens to prevent multiple refreshes
let isRefreshing = null as any;

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // Wait for the current refresh to finish
        await isRefreshing;
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
        return axios(originalRequest); // retry the request with the new token
      }

      originalRequest._retry = true; // mark the request as retried
      isRefreshing = api
        .get("/api/refreshToken", { withCredentials: true })
        .then((accessToken) => {
          if (accessToken?.data) {
            localStorage.setItem("token", accessToken.data);
            sseClient.setJwtToken(accessToken.data);
            originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
              "token"
            )}`;
          }
          isRefreshing = null; // reset the refreshing flag
          return accessToken;
        })
        .catch((refreshError) => {
          isRefreshing = null; // reset the refreshing flag
          location.href = "/login"; // redirect to login on failure
          return Promise.reject(refreshError);
        });

      await isRefreshing;
      return axios(originalRequest); // retry the request with the new token
    }

    if (
      error?.response?.status === 404 &&
      error.config.url === "/api/refreshToken"
    ) {
      location.href = "/login"; // redirect to login if refresh token endpoint is missing
    }

    if (error?.response?.status === 403) {
      location.href = "/"; // redirect to login if user is not authorized
    }

    if (error?.response?.status === 401) {
      location.href = "/login"; // redirect to login if user is not authenticated
    }
    if (
      error?.response?.status === 400 &&
      error?.response?.data?.showUpgradeModal
    ) {
      useShowUpgradeModal
        .getState()
        .setShowModal(true, error.response.data.message);
    }

    return Promise.reject(error); // handle other errors as usual
  }
);
