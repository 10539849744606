import {
  AutoComplete,
  Badge,
  Button,
  Card,
  Select,
  Skeleton,
  Table,
} from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { api } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RelativeTime } from '@/components/RelativeTime';
import dayjs from 'dayjs';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import TableHeader from './tableHeader';
import { sseClient } from '@/api/realtime';

export default function Assigned() {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<any>(null);

  const getAssignedTasks = async () => {
    const qs = encodeURIComponent(JSON.stringify(filters));
    const tasks = await api.get(`/api/me/assigned-tasks?filters=${qs}`);
    return tasks.data.tasks;
  };

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ['assignedTasks'],
    queryFn: getAssignedTasks,
  });

  useEffect(() => {
    refetch();
  }, [filters]);

  const queryClient = useQueryClient();

  useEffect(() => {

    function handleTaskUpdated(event: any) {
      queryClient.setQueryData(['assignedTasks'], (oldData: any) => {
        const updatedTask = JSON.parse(JSON.parse(event.data)).payload;
        const dataCopy = [...oldData];

        const updatedTaskIndex = oldData.findIndex(
          (task: any) => task.id === updatedTask.id
        );
        if (updatedTaskIndex > -1) {
          // overwrite the old task keys with the updated task key by key
          const taskCopy = { ...oldData[updatedTaskIndex] };
          for (const key in updatedTask) {
            taskCopy[key] = updatedTask[key];
          }
          dataCopy[updatedTaskIndex] = taskCopy;

        }

        return dataCopy; // return a new array to trigger re-render?;
      });
    };

    sseClient.addEventListener('task_updated', handleTaskUpdated);

    return () => {
      sseClient.removeEventListener('task_updated', handleTaskUpdated);
    };

  }, []);

  return (
    <Table
      size='small'
      loading={isLoading || isFetching}
      tableLayout={'auto'}
      bordered
      showSorterTooltip={true}
      title={() => (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text strong>
                Assigned tasks
                <Badge
                  count={data?.length}
                  color='blue'
                  style={{ color: 'white' }}
                />
              </Text>
            </div>
            <div>
              <Button
                disabled={isLoading || isFetching}
                loading={isFetching}
                size='small'
                onClick={() => refetch()}
              >
                Refresh
              </Button>
            </div>
          </div>
          <TableHeader
            onFilterChange={(filters: any) => {
              setFilters(filters);
            }}
          />
        </div>
      )}
      // pagination={false}
      columns={[
        // {
        //   title: 'Project',
        //   dataIndex: 'projectLabel',
        //   key: 'projectLabel',
        // },
        // {
        //   title: 'Board',
        //   dataIndex: 'boardLabel',
        //   key: 'boardLabel',
        // },

        {
          title: 'Key',
          dataIndex: 'key',
          key: 'key',
          sorter: (a: any, b: any) => a.key.localeCompare(b.key),
        },
        {
          title: 'Label',
          width: '30%',
          fixed: 'left',
          dataIndex: 'label',
          key: 'label',
        },
        {
          title: 'Due In',
          dataIndex: 'dueDate',
          key: 'dueDate',
          defaultSortOrder: 'ascend',
          sorter: (a: any, b: any) =>
            new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
          render: (dueDate: any) =>
            dueDate ? <RelativeTime date={dueDate} /> : '/',
        },
        {
          title: 'Status',
          dataIndex: 'columnLabel',
          key: 'columnLabel',
          sorter: (a: any, b: any) =>
            a.columnLabel.localeCompare(b.columnLabel),
        },
        {
          title: 'In Status',
          dataIndex: 'transferedAt',
          key: 'transferedAt',
          sorter: (a: any, b: any) =>
            new Date(a.transferedAt).getTime() -
            new Date(b.transferedAt).getTime(),
          render: (transferedAt: any) => (
            <RelativeTime date={transferedAt} showHoursAndMinutes from />
          ),
        },
        {
          title: 'Priority',
          dataIndex: 'priorityLabel',
          key: 'priorityLabel',
          sorter: (a: any, b: any) => a.priorityLabel.localeCompare(b.priorityLabel),
          render: (priority: any) => (
            <>{getTaskPriorityIcon(priority.toLowerCase() as any)}</>
          ),
        },
        {
          title: 'Type',
          dataIndex: 'taskTypeLabel',
          key: 'taskTypeLabel',
          sorter: (a: any, b: any) => a.taskTypeLabel.localeCompare(b.taskTypeLabel),
          render: (type: any) => (
            <>{getTaskTypeIcon(type.toLowerCase() as any)}</>
          ),
        },
        {
          title: 'Assigned',
          dataIndex: 'assignedAt',
          key: 'assignedAt',
          sorter: (a: any, b: any) =>
            new Date(a.assignedAt).getTime() - new Date(b.assignedAt).getTime(),
          render: (assignedAt: any) => <RelativeTime date={assignedAt} />,
        },
      ]}
      onRow={(record: any, rowIndex) => {
        return {
          onClick: (event) => {
            navigate(`?openTask=${record.key}`);
          },
        };
      }}
      dataSource={data}
    />
  );
}
