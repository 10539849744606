import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { Select, Tag } from 'antd';
import { useEffect, useState } from 'react';
import Text from 'antd/es/typography/Text';

export default function ExistingTasksDropdown({
  taskId,
  onAttachComplete,
}: {
  taskId: string;
  onAttachComplete: () => void;
}) {
  const [selectedTask, setSelectedTask] = useState<any>();

  async function getExistingTasks() {
    const response = await api.get(`/api/tasks/${taskId}/available-subtasks`);
    return response.data;
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['existingTasks', taskId],
    queryFn: getExistingTasks,
  });

  const attachTask = async (taskToAttachId: string) => {
    try {
      await api.post(`/api/tasks/${taskId}/attach/${taskToAttachId}`);
      setSelectedTask(undefined);
      refetch();
      onAttachComplete();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedTask) {
      attachTask(selectedTask);
    }
  }, [selectedTask]);

  return (
    <Select
      showSearch
      loading={isLoading}
      style={{ width: '100%' }}
      value={selectedTask}
      options={data?.map((task: any) => ({
        ...task,
        value: task.id,
        search:`${task.key} ${task.label}`,
        label: <>
          <Tag color='blue' bordered={false}>{task.key}</Tag> {task.label}
        </>
      }))}
      placeholder='Select existing task'
      optionFilterProp='children'
      onSelect={(value) => setSelectedTask(value)}
      filterOption={(input, option: any) =>
        option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
}
