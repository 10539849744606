import { create } from 'zustand';

interface Options {
  openTaskInDrawer: boolean;
  fontSize: number;
  compactMode: boolean;
}

interface OptionsStore {
  options: Options;
  taskOpen: boolean;
  setOption: (user: Options) => void;
  setTaskOpen: (taskOpen: boolean) => void;
  setFontSize: (fontSize: number) => void;
  setCompactMode: (compactMode: boolean) => void;

}

export const useOptionStore = create<OptionsStore>()((set) => ({
  options: {
    openTaskInDrawer: localStorage.getItem('openTaskInDrawer') === 'true',
    fontSize: 15,
    compactMode: localStorage.getItem('compactMode') === 'true'
  },
  taskOpen: false,
  setOption: (options: Options) => set(() => ({ options })),
  setTaskOpen: (taskOpen: boolean) => set(() => ({ taskOpen })),
  setFontSize: (fontSize: number) => set((o) => ({ ...o, fontSize })),
  setCompactMode: (compactMode: boolean) => set((o) => ({ ...o, compactMode })),
}));

interface ShowModal {
  showModal: boolean;
  message: string;
  setShowModal: (show: boolean, message?: string) => void
}

export const useShowUpgradeModal = create<ShowModal>((set) => ({
  showModal: false,
  message: '',
  setShowModal(show, message?: string) {
    set((s) => ({
      ...s,
      showModal: show,
      message
    }))
  },

}))