import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Input, Segmented, Select } from 'antd';
import Text from 'antd/es/typography/Text';
import { useBoardStore } from '../../../../../state/boardState';
import { useProjectStore } from '../../../../../state/projectState';
import UserAvatar from '../../../../../components/UserAvatar';
import { getTaskPriorityIcon } from '@/utils';

export const BoardHeader = ({
  isFetching,
  refetch,
  currentColumns,
  realTime,
  setRealTime,
  assigneeFilter,
  setAssigneeFilter,
  users,
  usersIsLoading,
  filterType,
  setFilterType,
  setSearchTerm,
  searchTerm,
  currentPriorities,
  priorityFilter,
  setPriorityFilter,
}: {
  isFetching: any;
  refetch: any;
  currentColumns: any;
  realTime: any;
  setRealTime: any;
  assigneeFilter: any;
  currentPriorities: any;
  priorityFilter: any;
  setPriorityFilter: any;
  setAssigneeFilter: any;
  users: any;
  usersIsLoading: any;
  filterType: any;
  setFilterType: any;
  setSearchTerm: any;
  searchTerm: any;
}) => {
  const projectStore = useProjectStore();
  const boardStore = useBoardStore();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '.5rem',
        }}
      >
        <div>
          <Text> {boardStore.board?.label}</Text><Text type='secondary'> <small>{boardStore.board?.description}</small></Text>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '1rem', flexWrap: "wrap" }}>
        <div>
          <Input.Search
            allowClear
            placeholder='Search'
            style={{ width: '200px' }}
            onChange={(e) => {
              if (e.target.value.trim() === '') {
                setSearchTerm('');
                return;
              }
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <div>
          <Select
            mode='multiple'
            placeholder='Assignees'
            filterOption={(input, option: any) =>
              (option?.label ?? '')
                .toLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            style={{ width: '200px' }}
            loading={usersIsLoading}
            optionRender={
              users
                ? (user: any) => (
                  <span style={{ display: 'flex', gap: '.5rem' }}>
                    {user.value === null && (
                      <Avatar
                        size={25}
                        style={{
                          backgroundColor: 'orange',
                        }}
                        icon={<CloseCircleOutlined />}
                      />
                    )}
                    {user.value !== null && (
                      <UserAvatar token={user.data.avatar} size={25} />
                    )}
                    {user.label}
                  </span>
                )
                : undefined
            }
            onChange={(value) => {
              setAssigneeFilter(value);
            }}
            options={[
              { label: 'Unassigned', value: null },
              ...(users
                ? users
                  // .filter((u: any) => {
                  //   return availableAssignees.includes(u.id);
                  // })
                  ?.map((user: any) => ({
                    label: user.fullName,
                    value: `${user.id}`,
                    avatar: user.avatar,
                  }))
                : []),
            ]}
          />
        </div>

        <div>
          <Select
            mode='multiple'
            placeholder='Priority'
            filterOption={(input, option: any) =>
              (option?.label ?? '')
                .toLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            style={{ width: '200px' }}
            optionRender={(priority: any) => (
              <>
                {getTaskPriorityIcon(priority.label.toLowerCase())}
                {priority.label}
              </>
            )}
            loading={usersIsLoading}
            onChange={(value) => {
              setPriorityFilter(value);
            }}
            options={currentPriorities?.map((p: any) => ({
              label: p.label,
              value: p.id,
            }))}
          />
        </div>

        <div>
          <Segmented
            defaultValue={'all'}
            value={filterType}
            options={[
              {
                label: 'All',
                value: 'all',
                icon: (
                  <Badge
                    showZero
                    color='blue'
                    style={{
                      color: 'white',
                    }}
                    count={
                      currentColumns?.reduce(
                        (acc: any, col: any) =>
                          acc +
                          col?.items
                            ?.filter((i: any) => {
                              //assignee filter
                              if (
                                assigneeFilter.length > 0 ||
                                priorityFilter.length > 0
                              ) {
                                return (
                                  assigneeFilter.includes(i.assigneeId) ||
                                  priorityFilter.includes(i.priorityId)
                                );
                              }
                              return true;
                            })
                            .filter((i: any) => {
                              if (searchTerm === '') return true;
                              return (
                                i.label
                                  .toLowerCase()
                                  .includes(searchTerm.toLocaleLowerCase()) ||
                                i.key
                                  .toLowerCase()
                                  .includes(searchTerm.toLocaleLowerCase())
                              );
                            }).length,
                        0
                      )}
                  />
                ),
              },
              {
                label: 'Task',
                value: 'ttkefwc4qtaaogcxrkyu98x0',
                icon: (
                  <Badge
                    color='gray'
                    style={{
                      color: 'white',
                    }}
                    showZero
                    count={currentColumns.reduce(
                      (acc: any, col: any) =>
                        acc +
                        col.items
                          .filter((i: any) => {
                            //assignee filter
                            if (
                              assigneeFilter.length > 0 ||
                              priorityFilter.length > 0
                            ) {
                              return (
                                assigneeFilter.includes(i.assigneeId) ||
                                priorityFilter.includes(i.priorityId)
                              );
                            }
                            return true;
                          })
                          .filter((i: any) => {
                            if (searchTerm === '') return true;
                            return (
                              i.label
                                .toLowerCase()
                                .includes(searchTerm.toLocaleLowerCase()) ||
                              i.key
                                .toLowerCase()
                                .includes(searchTerm.toLocaleLowerCase())
                            );
                          })
                          .filter(
                            (i: any) =>
                              i.taskTypeId === 'ttkefwc4qtaaogcxrkyu98x0'
                          ).length,
                      0
                    )}
                  />
                ),
              },
              {
                label: 'Bug',
                value: 'tzz06j7svw51umjdd0ov5ey8',
                icon: (
                  <Badge
                    color='red'
                    style={{
                      color: 'white',
                    }}
                    showZero
                    count={currentColumns.reduce(
                      (acc: any, col: any) =>
                        acc +
                        col.items
                          .filter((i: any) => {
                            //assignee filter
                            if (
                              assigneeFilter.length > 0 ||
                              priorityFilter.length > 0
                            ) {
                              return (
                                assigneeFilter.includes(i.assigneeId) ||
                                priorityFilter.includes(i.priorityId)
                              );
                            }
                            return true;
                          })
                          .filter((i: any) => {
                            if (searchTerm === '') return true;
                            return (
                              i.label
                                .toLowerCase()
                                .includes(searchTerm.toLocaleLowerCase()) ||
                              i.key
                                .toLowerCase()
                                .includes(searchTerm.toLocaleLowerCase())
                            );
                          })
                          .filter(
                            (i: any) =>
                              i.taskTypeId === 'tzz06j7svw51umjdd0ov5ey8'
                          ).length,
                      0
                    )}
                  />
                ),
              },
              {
                label: 'Story',
                value: 'w38qzcuzxm81h4jarp4aog9g',
                icon: (
                  <Badge
                    color='#111a2c'
                    style={{
                      color: 'white',
                    }}
                    showZero
                    count={currentColumns.reduce(
                      (acc: any, col: any) =>
                        acc +
                        col.items
                          .filter((i: any) => {
                            //assignee filter
                            if (
                              assigneeFilter.length > 0 ||
                              priorityFilter.length > 0
                            ) {
                              return (
                                assigneeFilter.includes(i.assigneeId) ||
                                priorityFilter.includes(i.priorityId)
                              );
                            }
                            return true;
                          })
                          .filter((i: any) => {
                            if (searchTerm === '') return true;
                            return (
                              i.label
                                .toLowerCase()
                                .includes(searchTerm.toLocaleLowerCase()) ||
                              i.key
                                .toLowerCase()
                                .includes(searchTerm.toLocaleLowerCase())
                            );
                          })
                          .filter(
                            (i: any) =>
                              i.taskTypeId === 'w38qzcuzxm81h4jarp4aog9g'
                          ).length,
                      0
                    )}
                  />
                ),
              },
            ]}
            onChange={(value) => {
              setFilterType(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
