import { EventSource } from "extended-eventsource";
const apiUrl = import.meta.env.VITE_API_REALTIME_URL as string;

interface SSEListener {
  eventName: string;
  handler: (event: MessageEvent) => void;
}

let token = localStorage.getItem("token");

class SSEClient {
  url: string;
  options: { [key: string]: any };
  eventSource: EventSource | null = null;
  listeners: SSEListener[] = [];

  constructor(url: string, options: { [key: string]: any }) {
    this.url = url;
    this.options = options || {};
  }

  // Connect (or reconnect) to the SSE endpoint
  connect() {
    this.eventSource?.close();

    this.eventSource = new EventSource(this.url, this.options);

    this.eventSource.onopen = () => {
      // Reattach all stored listeners
      console.info("Connected :)");
      const copyListeners = [...this.listeners];
      this.listeners = []; // Clear the stored listeners
      copyListeners.forEach(({ eventName, handler }) => {
        this.eventSource?.addEventListener(eventName, handler);
      });
    };

    this.eventSource.onmessage = (event) => {};

    this.eventSource.onerror = (error) => {
      // dont  do anything, system will auto reconnect
      // maybe log the error and send it to the server somewhere
    };

    return this;
  }

  // Add a listener and store it for future reconnections
  addEventListener(
    eventName: string,
    handler: (event: MessageEvent) => void,
    options?: AddEventListenerOptions
  ) {
    this.listeners.push({ eventName, handler });
    if (this.eventSource) {
      this.eventSource.addEventListener(eventName, handler, options);
    }
  }

  // Remove a listener and update the stored list
  removeEventListener(
    eventName: string,
    handler: (event: MessageEvent) => void
  ) {
    this.listeners = this.listeners.filter(
      (listener) =>
        !(listener.eventName === eventName && listener.handler === handler)
    );
    if (this.eventSource) {
      this.eventSource.removeEventListener(eventName, handler);
    }
  }

  setJwtToken(_token: string) {
    token = _token;
  }

}

// Create a singleton instance
const sseClientInstance = new SSEClient(`${apiUrl}/sse/events`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

// Establish the connection
sseClientInstance.connect();

export const sseClient = sseClientInstance;
