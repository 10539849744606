import {
  Button,
  Checkbox,
  CheckboxProps,
  Collapse,
  CollapseProps,
  Input,
  Modal,
  Popconfirm,
  Switch,
  Tooltip,
  message,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { api } from '../../api';
import Text from 'antd/es/typography/Text';
import { useProjectStore } from '../../state/projectState';
import InfoTooltip from '../../components/Tooltip';
import SlackChannelsDropdown from '@/components/SlackChannelsDropdown';
import AccessTree from '@/components/Access';

export default function UpdateProjectModal({
  open,
  handleOk,
  handleCancel,
}: {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) {
  const [isPublic, setIsPublic] = useState(true);
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [key, setKey] = useState('');
  const [userIds, setUserIds] = useState<string[]>([]);
  const [slackChannelId, setSlackChannelId] = useState<string | null>(null);

  const [updateKeyDisabled, setUpdateKeyDisabled] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const [keyAlreadyExists, setKeyAlreadyExists] = useState(false);

  const projectStore = useProjectStore();

  const getProjectDetails = async () => {
    const project = await api.get(
      `/api/projects/${projectStore?.project?.key}`
    );
    setLabel(project.data.label);
    setDescription(project.data.description);
    setKey(project.data.key);
    setIsPublic(project.data.isPublic);
    if (project?.data?.access?.length) {
      setUserIds(project.data.access.map((a: any) => a.userId));
    }
    setSlackChannelId(project.data.slackChannelId);
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const onChange: CheckboxProps['onChange'] = (e) => {
    setIsPublic(e.target.checked);
  };

  const updateProject = async () => {
    const data = {
      label,
      description,
      key,
      userIds,
      isPublic,
      slackChannelId: slackChannelId ?? null,
    };

    if (!isPublic && !userIds.length) {
      alert('Please select at least one user');
      return;
    }

    try {
      await api.put(`/api/projects/${projectStore?.project?.key}`, data);
      handleOk();
    } catch (error) {
      messageApi.error('Error updating project');
    }
  };


  function clearUpLastSelectedProject() {
    const lastSelectedProject = localStorage.getItem('lastSelectedProject') ? JSON.parse(localStorage.getItem('lastSelectedProject') as string) : null;
    if (lastSelectedProject && lastSelectedProject?.id === projectStore?.project?.key) {
      localStorage.removeItem('lastSelectedProject');
    }
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'More Settings',
      children: (
        <div>
          <div>
            <Popconfirm
              title='Are you sure?'
              okText='Yes'
              okButtonProps={{ type: 'primary', danger: true }}
              onConfirm={async () => {
                await api.delete(`/api/projects/${projectStore?.project?.key}`);
                clearUpLastSelectedProject();
                handleOk();
              }}
            >
              <Button type='primary' danger>
                Delete project
              </Button>
            </Popconfirm>
          </div>
          <Text type='danger'>*This action is not reversible*</Text>
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      title='Update Project'
      okText='Update'
      style={{ top: 20 }}
      cancelText='Cancel'
      onOk={updateProject}
      onCancel={handleCancel}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      )}
    >
      {contextHolder}
      <Text>Label</Text>
      <Input
        placeholder='Project Name'
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />
      <div style={{ marginTop: '1rem' }}></div>
      <Text>Description</Text>
      <Input
        placeholder='Project Description'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div style={{ marginTop: '1rem' }}></div>
      <div style={{ display: 'flex', gap: '.5rem' }}>
        <InfoTooltip
          color='red'
          text='Changing the key will disable any existing shared URLs that are pointing to a task in this project.'
        />
        <Text>Key</Text>
      </div>
      <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
        <Input
          placeholder='Key'
          value={key}
          disabled={updateKeyDisabled}
          onChange={(e) => setKey(e.target.value)}
        />
        <Switch
          title='Enable Change key'
          onChange={() => setUpdateKeyDisabled(!updateKeyDisabled)}
          checked={!updateKeyDisabled}
          size='small'
        />
      </div>
      {!updateKeyDisabled && (
        <Text type='warning'>
          Changing the key will disable any existing shared URLs that are
          pointing to a task in this project.
        </Text>
      )}
      {keyAlreadyExists && (
        <Text style={{ color: 'red' }} strong>
          Key already in use, please pick another one.
        </Text>
      )}

      <Title level={5}>Access</Title>
      <Tooltip title='All users within organization have access to the project'>
        <Checkbox onChange={onChange} value={isPublic} checked={isPublic}>
          Public
        </Checkbox>
      </Tooltip>

      <div style={{ marginTop: '1rem' }}></div>
      {!isPublic && (
        <AccessTree
          userIds={userIds}
          onSelected={(selectedValues: any) => {
            setUserIds(selectedValues);
          }}
        />
      )}
      <br />
      <SlackChannelsDropdown
        onSelectChange={(slackChannelId) => {
          setSlackChannelId(slackChannelId);
        }}
        defaultChannel={slackChannelId}
        hideLabel={false}
      />
      <div style={{ marginTop: '1rem' }}></div>
      <Collapse size='small' items={items} />
    </Modal>
  );
}
