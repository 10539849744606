import React, { useState, useEffect } from 'react';
import { Divider, Tag, Tooltip, Typography } from 'antd';
import { Rnd, RndResizeCallback, RndDragCallback } from 'react-rnd';
import dayjs, { Dayjs } from 'dayjs';
import { QuestTask } from './types';
import { calculateNewDates } from './dateUtils';

const { Text } = Typography;

// Constants
const TODAY_MARKER_COLOR = 'darkred';
const PROGRESS_COLOR = '#1890ff';

interface TimelineBarProps {
  task: QuestTask;
  dateRange: Dayjs[];
  dayWidth: number;
  todayOffset: number;
  onTaskUpdate: (
    task: QuestTask,
    newStartDate: Dayjs,
    newEndDate: Dayjs
  ) => Promise<void>;
  scrollRef: React.RefObject<HTMLDivElement>;
}

interface DynamicDates {
  start: Dayjs;
  end: Dayjs;
  totalDuration: number;
}

export default function TimelineBar({
  task,
  dateRange,
  dayWidth,
  todayOffset,
  onTaskUpdate,
  scrollRef,
}: TimelineBarProps) {
  const minDate = dateRange[0];
  const [showToolTip, setShowToolTip] = useState(false);

  // Task start/end as Dayjs
  const taskStart = dayjs(task.start).startOf('day');
  const taskEnd = dayjs(task.end).endOf('day');

  // Offsets in days from the earliest date in the range
  const startOffset = taskStart.diff(minDate, 'day');
  const taskDuration = taskEnd.diff(taskStart, 'day') + 1;

  // Initial pixel-based position/width
  const initialLeft = startOffset * dayWidth;
  const initialWidth = taskDuration * dayWidth;

  // For dynamic tooltip date display
  const [dynamicDates, setDynamicDates] = useState<DynamicDates>(() => ({
    start: taskStart,
    end: taskEnd,
    totalDuration: taskDuration,
  }));

  // Controlled RND position/size
  const [controlledPosition, setControlledPosition] = useState({
    x: initialLeft,
    y: 0,
  });
  const [width, setWidth] = useState(initialWidth);

  useEffect(() => {
    // If external changes update the task's start/end, re-sync the position/width
    const newTaskStart = dayjs(task.start).startOf('day');
    const newTaskEnd = dayjs(task.end).endOf('day');
    const newStartOffset = newTaskStart.diff(minDate, 'day');
    const newDuration = newTaskEnd.diff(newTaskStart, 'day') + 1;

    setControlledPosition({ x: newStartOffset * dayWidth, y: 0 });
    setWidth(newDuration * dayWidth);
  }, [task.start, task.end, minDate, dayWidth]);

  // Drag end callback
  const onDragStop: RndDragCallback = (e, d) => {
    e.stopPropagation();
    const newX = d.x;
    // Convert new x to day offset
    const newOffsetInDays = Math.round(newX / dayWidth);
    // Original offset was startOffset; the difference is how many days we moved
    const deltaDays = newOffsetInDays - startOffset;
    if (deltaDays === 0) return;

    const { newStart, newEnd, totalDuration } = calculateNewDates(
      taskStart,
      taskEnd,
      deltaDays,
      'drag'
    );
    setDynamicDates({ start: newStart, end: newEnd, totalDuration });
    onTaskUpdate(task, newStart, newEnd);

    // Update local state
    setControlledPosition({ x: newX, y: 0 });
  };

  // Resize end callback
  const onResizeStop: RndResizeCallback = (
    e,
    direction,
    ref,
    delta,
    position
  ) => {
    e.stopPropagation();
    const deltaWidth = delta.width;
    const deltaDays = Math.round(deltaWidth / dayWidth);
    if (deltaDays === 0) return;

    const { newStart, newEnd, totalDuration } = calculateNewDates(
      taskStart,
      taskEnd,
      deltaDays,
      direction as 'left' | 'right'
    );
    setDynamicDates({ start: newStart, end: newEnd, totalDuration });
    onTaskUpdate(task, newStart, newEnd);

    // Update local RND states
    setControlledPosition({ x: position.x, y: 0 });
    setWidth(ref.offsetWidth);
  };

  return (
    <div style={{ position: 'relative', height: '40px' }}>
      {/* "Today" vertical line */}
      <div
        id='today-marker'
        ref={scrollRef}
        style={{
          position: 'absolute',
          left: `${todayOffset}px`,
          top: -20,
          bottom: -5,
          width: '3px',
          backgroundColor: TODAY_MARKER_COLOR,
          pointerEvents: 'none',
          zIndex: 1,
        }}
      />
      {/* RND Container */}
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: '10px',
          width: `${dateRange.length * dayWidth}px`,
          height: '20px',
        }}
      >
        <Tooltip
          open={showToolTip}
          title={<TaskTooltip task={task} dynamicDates={dynamicDates} />}
        >
          <Rnd
            size={{ width, height: 25 }}
            position={controlledPosition}
            onDragStop={onDragStop}
            onResizeStop={onResizeStop}
            bounds='parent'
            resizeHandleStyles={{
              left: { paddingRight: '1rem' },
            }}
            enableResizing={{
              left: true,
              right: true,
              top: false,
              bottom: false,
              topLeft: false,
              topRight: false,
              bottomLeft: false,
              bottomRight: false,
            }}
            dragAxis='x'
            resizeGrid={[dayWidth, dayWidth]}
            dragGrid={[dayWidth, dayWidth]}
            minWidth={dayWidth}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
            onResize={(e, dir, ref, delta, pos) => {
              // Live-resizing updates tooltip
              const deltaDays = Math.round(delta.width / dayWidth);
              const { newStart, newEnd, totalDuration } = calculateNewDates(
                taskStart,
                taskEnd,
                deltaDays,
                dir as 'left' | 'right'
              );
              setDynamicDates({
                start: newStart,
                end: newEnd,
                totalDuration,
              });
              setControlledPosition({ x: pos.x, y: pos.y });
            }}
            onDrag={(e, d) => {
              // Live-drag updates tooltip
              const newX = d.x;
              const newOffsetInDays = Math.round(newX / dayWidth);
              const deltaDays = newOffsetInDays - startOffset;
              if (deltaDays === 0) return;
              const { newStart, newEnd, totalDuration } = calculateNewDates(
                taskStart,
                taskEnd,
                deltaDays,
                'drag'
              );
              setDynamicDates({ start: newStart, end: newEnd, totalDuration });
            }}
            style={{ zIndex: 1 }}
          >
            <TaskBar task={task} width={width} />
          </Rnd>
        </Tooltip>
      </div>
    </div>
  );
}

interface TaskBarProps {
  task: QuestTask;
  width: number;
}

// Task bar component (the visual bar with progress)
function TaskBar({ task, width }: TaskBarProps) {
  return (
    <div
      style={{
        width: '100%',
        height: '80%',
        backgroundColor: 'gray',
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'move',
        position: 'relative',
      }}
    >
      {/* Progress fill */}
      <div
        style={{
          width: `${task.progress}%`,
          height: '100%',
          backgroundColor: PROGRESS_COLOR,
        }}
      />
      {/* Progress text (optional) */}
      {width > 80 && (
        <Text
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '12px',
          }}
        >
          {`${task.progress}%`}
        </Text>
      )}
    </div>
  );
}

interface TaskTooltipProps {
  task: QuestTask;
  dynamicDates: DynamicDates;
}

// Tooltip content component
function TaskTooltip({ task, dynamicDates }: TaskTooltipProps) {
  return (
    <div>
      <div>
        <Text>{task.name}</Text>
      </div>
      <div>
        <Tag color={'blue-inverse'}>
          {dynamicDates.start.format('DD MMM YYYY')} -{' '}
          {dynamicDates.end.format('DD MMM YYYY')}
        </Tag><Tag>
          {dynamicDates.totalDuration}d
        </Tag>
      </div>
      {/* <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            Starts in:
          </div>
          <div>
            <Tag> {dayjs().diff(dynamicDates.start, 'day') * -1}d</Tag>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            Duration:
          </div>
          <div>
            <Tag> {dynamicDates.totalDuration}d</Tag>
          </div>
        </div>
      </div> */}
    </div>
  );
}
