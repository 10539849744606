import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Steps,
  Tabs,
  TabsProps,
  Tooltip,
  message,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { useState } from 'react';
import { api } from '../../api';
import Text from 'antd/es/typography/Text';
import SlackChannelsDropdown from '@/components/SlackChannelsDropdown';
import { useForm } from 'antd/es/form/Form';
import AccessTree from '@/components/Access';
import TabPane from 'antd/es/tabs/TabPane';
import { ArrowLeftOutlined, DeleteFilled } from '@ant-design/icons';
import { COLUMN_TYPES, COLUMN_TYPE_IDS } from '@/constants/columnType';
import { getProjectTemplates } from '@/constants/projectTemplates';
import { AxiosError } from 'axios';


export default function CreateProjectModal({
  open,
  handleOk,
  handleCancel,
}: {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) {
  const [userIds, setUserIds] = useState<string[]>([]);
  const [slackChannelId, setSlackChannelId] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(0);

  const [activeTab, setActiveTab] = useState('1');

  const [keyAlreadyExists, setKeyAlreadyExists] = useState(false);

  const [form] = useForm();

  const [form2] = useForm();

  const PROJECT_TEMPLATES = getProjectTemplates();

  const checkIfKeyIsUnique = async (key: string) => {
    setKeyAlreadyExists(false);
    // Make a request to the backend to check if the key is unique
    // If it is not unique, show an alert

    try {
      await api.post(`/api/projects/${key}/check`, {
        key,
      });
    } catch (error) {
      setKeyAlreadyExists(true);
    }
  };

  const createProject = async (data: any) => {
    if (keyAlreadyExists) {
      alert('Change the key');
      return;
    }

    const { label, description = '', key, isPublic, slackChannelId } = data;

    if (!isPublic && !userIds.length) {
      alert('Please select at least one user');
      return;
    }

    await api.post('/api/projects', {
      label,
      description,
      key,
      isPublic,
      slackChannelId,
      userIds,
    });

    handleOk();
  };
  const validateInput = (_: any, value: any) => {
    const regex = /^(?!-)[a-zA-Z0-9-]+(?<!-)$/;
    if (value && !regex.test(value)) {
      return Promise.reject(
        new Error(
          'Key must include letters and/or numbers, can include hyphens, but cannot start or end with hyphens.'
        )
      );
    }
    return Promise.resolve();
  };

  const [newData, setNewData] = useState<any>({});

  const createFullProject = async () => {
    try {

      await api.post('/api/projects/full', newData);
      handleOk();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.error(error.response?.data.message);
        return;
      }
      message.error('An error occurred');
    }
  }

  const steps = [
    {
      title: 'Project Info',
      content: <div>
        <Form
          form={form}
          layout='vertical'
          onFinish={async (data) => {

            await checkIfKeyIsUnique(data.key);
            if (keyAlreadyExists) {
              return;
            }

            setNewData({ ...data });
            setActiveStep(1);
          }}
        >
          <Form.Item
            label='Label'
            name={'label'}
            rules={[{ required: true, message: 'Please input your label' }]}
          >
            <Input placeholder='Project Name' />
          </Form.Item>
          <Form.Item label='Description' name={'description'}>
            <Input placeholder='Description' />
          </Form.Item>
          <Form.Item
            label='Key'
            name={'key'}
            rules={[
              { required: true, message: 'Please input your key!' },
              { validator: validateInput },
            ]}
          >
            <Input
              onBlur={async (e) => {
                if (e.target.value.trim() === '') return;
                await checkIfKeyIsUnique(e.target.value);
              }}
            />
            {keyAlreadyExists && (
              <Text style={{ color: 'red' }} strong>
                Key already in use, please pick another one.
              </Text>
            )}
          </Form.Item>
          <Title level={5}>Access</Title>
          <Tooltip title='All users within organization have access to the project'>
            <Form.Item
              name={'isPublic'}
              valuePropName='checked'
              initialValue={true}
            >
              <Checkbox>Public</Checkbox>
            </Form.Item>
          </Tooltip>
          <div style={{ marginTop: '.5rem' }}></div>
          {!Form.useWatch('isPublic', form) && (
            <AccessTree
              onSelected={(selectedValues: any) => {
                setUserIds(selectedValues);
              }}
              userIds={userIds}
            />
          )}
          <br />
          <SlackChannelsDropdown
            onSelectChange={(channelId: string) => {
              setSlackChannelId(channelId);
            }}
            defaultChannel={slackChannelId}
          />
          <br />
          <div style={{ textAlign: 'right' }}>
            <Button type='primary' onClick={form.submit}>
              <Text>Next</Text>
            </Button>
          </div>
        </Form>

      </div>,
    },
    {
      title: 'Boards & Columns',
      content: <Card
        variant='borderless'
        style={{ padding: 10 }}
      >
        {/* {Each project can have murliple boarads and each board multiple cilumns, create dynamic form so that i can add multiple boards and nuested mutliple columns for each board} */}
        <Form
          form={form2}
          layout='vertical'
          onFinish={async (data) => {
            setNewData({ ...newData, ...data });
            setActiveStep(2);
          }}
        >
          <Form.List name='boards'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div>

                    <Space
                      align='baseline'
                      style={{ display: 'flex', marginBottom: 1 }}
                    >
                      <Form.Item
                        name={[name, 'label']}
                        rules={[{ required: true, message: 'Missing board name' }]}
                      >
                        <Input placeholder='Board Name' />

                      </Form.Item>
                      <Button
                        onClick={() => {
                          remove(name);
                        }}
                      >
                        Remove
                      </Button>

                    </Space>
                    <Form.List name={[name, 'columns']}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }) => (

                            <Space
                              key={key}
                              align='baseline'
                              style={{ display: 'flex', marginBottom: 1, marginLeft: 20 }}
                            >
                              <Form.Item
                                // label='Column Name'
                                name={[name, 'label']}
                                rules={[
                                  { required: true, message: 'Missing column name' },
                                ]}
                              >
                                <Input placeholder='Column Name' />
                              </Form.Item>
                              <Form.Item
                                name={[name, 'typeId']}
                                rules={[
                                  { required: true, message: 'Missing column type' },
                                ]}
                              >
                                <Select
                                  placeholder='Column Type'
                                  style={{ width: 120 }}
                                >
                                  <Select.Option value={COLUMN_TYPE_IDS.backlog}>Backlog</Select.Option>
                                  <Select.Option value={
                                    COLUMN_TYPE_IDS.todo
                                  }>Todo</Select.Option>
                                  <Select.Option value={
                                    COLUMN_TYPE_IDS.in_progress
                                  }>In Progress</Select.Option>
                                  <Select.Option value={
                                    COLUMN_TYPE_IDS.done
                                  }>Done</Select.Option>
                                </Select>
                              </Form.Item>
                              <Button
                                size='small'
                                onClick={() => {
                                  remove(name);
                                }}
                              >
                                <DeleteFilled />
                              </Button>
                            </Space>

                          ))}
                          <Form.Item>
                            <Button
                              style={{ marginLeft: 20 }}
                              type='dashed'
                              onClick={() => {
                                add();
                              }}
                            >
                              Add Column
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>

                ))}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => {
                      add({
                        label: '',
                        columns: [
                          { label: 'Backlog', typeId: COLUMN_TYPE_IDS.backlog },
                          { label: 'Todo', typeId: COLUMN_TYPE_IDS.todo },
                          { label: 'In Progress', typeId: COLUMN_TYPE_IDS.in_progress },
                          { label: 'Done', typeId: COLUMN_TYPE_IDS.done },
                        ],
                      });

                    }}
                  >
                    Add Board
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Space
            style={{ display: "flex", justifyContent: 'space-between' }}
          >
            <Button
              type='primary'
              onClick={() => {
                setActiveStep(0);
              }}
            >
              <ArrowLeftOutlined />
              Back
            </Button>
            <Button type='primary' onClick={form2.submit}>
              <Text>Next</Text>
            </Button>
          </Space>
        </Form>
      </Card >,
    },
    {
      title: 'Review & Create',
      content: <Card variant='borderless'>

        <Title level={4}>Project Info</Title>
        <Text strong>Label:</Text> {newData.label} <br />
        <Text strong>Description:</Text> {newData.description} <br />
        <Text strong>Key:</Text> {newData.key} <br />
        <Text strong>Access:</Text> {newData.isPublic ? 'Public' : 'Private'} <br />
        <Text strong>Users:</Text> {userIds.join(', ')} <br />
        <Text strong>Slack Channel:</Text> {slackChannelId} <br />
        <Title level={4}>Boards & Columns</Title>
        {newData.boards?.map((board: any, index: number) => (
          <div key={index}>
            <Text strong>Board Name:</Text> {board.label} <br />
            <Text strong>Columns:</Text> <br />
            <ul>
              {board.columns.map((column: any, index: number) => (
                <li key={index}>
                  {column.label}
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div
          style={{ display: "flex", justifyContent: 'space-between' }}
        >

          <Button
            type='primary'
            onClick={() => {
              setActiveStep(1);
            }}
          >
            <ArrowLeftOutlined />
            Back
          </Button>

          <Button
            type='primary'
            onClick={
              createFullProject
            }
          >
            Create Project
          </Button>

        </div>
      </Card>,
    }
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Custom Project',
      children: <Card style={{ padding: 10 }} variant='borderless'>
        <Steps current={
          activeStep
        } items={steps} />
        <div
          style={{ marginTop: "1rem" }}
        >{
            steps[activeStep].content
          }</div>
      </Card>,
    },
    {
      key: '2',
      label: 'From Template',
      children: <Card style={{ padding: 10 }} variant='borderless'>
        <Space
          style={{ display: 'flex', flexWrap: "wrap", gap: ".5rem" }}
        >
          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.marketing);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.marketing.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Marketing Agency
          </Button>
          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.sofwareDevelopemnt);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.sofwareDevelopemnt.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Software Development
          </Button>
          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.creativeDesign);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.creativeDesign.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Creative Design
          </Button>
          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.consulting);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.consulting.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Consulting
          </Button>
          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.eventPlaning);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.eventPlaning.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Event Planing
          </Button>

          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.publicRelations);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.publicRelations.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Public Relations
          </Button>

          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.humanResources);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.humanResources.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Human Resources
          </Button>

          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.finance);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.finance.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Finance
          </Button>

          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.sales);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.sales.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}

          >
            Sales
          </Button>

          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.customerService);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.customerService.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          >
            Customer Service
          </Button>

          <Button
            onClick={() => {
              form.setFieldsValue(PROJECT_TEMPLATES.projectManagement);
              form2.setFieldsValue({ boards: PROJECT_TEMPLATES.projectManagement.boards });
              setActiveStep(0)
              setActiveTab('1')
            }}
          > Project Management

          </Button>



        </Space>
      </Card>,
    },
  ];


  return (
    <Modal
      open={open}
      title='Create Project'
      okText='Create'
      style={{ top: 20 }}
      width={600}
      cancelText='Cancel'
      onOk={createProject}
      onCancel={handleCancel}
      footer={(_, { OkBtn, CancelBtn }) => <></>}
    >

      <Tabs items={items}
        activeKey={activeTab}
        onTabClick={(key) => {
          setActiveTab(key);
        }}
      />


    </Modal>
  );
}
