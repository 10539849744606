import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { Button, Divider, Input, Skeleton, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import SubtaskTable from './table';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import ExistingTasksDropdown from './existingTasks';
import Text from 'antd/es/typography/Text';
import { EventSource } from 'extended-eventsource';

export const Subtasks = ({
  task,
  columnId,
  columns,
  priorities,
  messageApi,
  disabled,
}: {
  task: any;
  columnId: string;
  columns: any;
  priorities: any;
  messageApi: any;
  disabled: boolean;
}) => {
  const [showCreateSubtask, setShowCreateSubtask] = useState(false);
  const [newTaskLabel, setNewTaskLabel] = useState('');

  const getSubtasks = async () => {
    const { data } = await api.get(`/api/tasks/${task.id}/subtasks`);
    return data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['subtasks', task.id],
    queryFn: getSubtasks,
  });

  const getAvailableColumns = async () => {
    const { data } = await api.get(
      `/api/projects/${task.projectId}/available-columns`
    );
    return data.boards;
  };

  const { data: availableColumns, isLoading: isLoadingColumns } = useQuery({
    queryKey: ['available-columns', task.projectId],
    queryFn: getAvailableColumns,
  });

  const createSubtask = async () => {
    await api.post(`/api/columns/${columnId}/tasks`, {
      label: newTaskLabel,
      taskTypeId: 'ttkefwc4qtaaogcxrkyu98x0',
      priorityId: 'tdkcw9in2xgbq1lrpd4phsmk',
      parentId: task.id,
      description: '',
    });
    messageApi.success('Subtask created');
    setNewTaskLabel('');
    // refetch();
  };

  return (
    <div>
      <div>
        {data?.subtasks.length === 0 && (
          <Button
            size='small'
            type='text'
            disabled={disabled}
            onClick={() => setShowCreateSubtask(!showCreateSubtask)}
          >
            + Add subtask
          </Button>
        )}
        {data?.subtasks.length > 0 && (
          <div style={{display:"flex",justifyContent:"space-between"}}>
            <Text>
              Subtasks <Tag>{data?.subtasks.length}</Tag>
            </Text>
            <Button
              size='small'
              type='link'
              disabled={disabled}
              onClick={() => setShowCreateSubtask(!showCreateSubtask)}
            >
              {showCreateSubtask ? <MinusOutlined /> : <PlusOutlined />}
            </Button>
          </div>
        )}
        {(data?.subtasks.length > 0 || showCreateSubtask) && (
          <Divider style={{ margin: '0.5rem 0' }} />
        )}
        {showCreateSubtask && (
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
              <Input
                placeholder='Create new'
                value={newTaskLabel}
                onPressEnter={createSubtask}
                autoFocus
                onChange={(e) => setNewTaskLabel(e.target.value)}
              />
            </div>
            <div>or</div>
            <div style={{ width: '100%' }}>
              <ExistingTasksDropdown
                taskId={task.id}
                onAttachComplete={refetch}
              />
            </div>
          </div>
        )}
        {showCreateSubtask && <br />}
        {isLoading && isLoadingColumns && <Skeleton />}
        {!isLoading && !isLoadingColumns && (
          <SubtaskTable
            data={data.subtasks}
            availableStatuses={availableColumns ?? []}
            refetch={refetch}
            columns={columns}
            priorities={priorities}
            task={task}
            messageApi={messageApi}
          />
        )}
      </div>
    </div>
  );
};
