import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Select, Form, Typography, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; // Import react-beautiful-dnd components
import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useMessage from 'antd/es/message/useMessage';
import { COLUMN_TYPES, COLUMN_TYPE_IDS } from '@/constants/columnType';

const { Text } = Typography;

export default function Automations({
  columnId,
  showOnlyAutomations,
  columnType
}: {
  columnId: string;
  columnType: string;
  showOnlyAutomations?: boolean;
}) {
  const [createdAutomationForm] = Form.useForm();
  const [movedAutomationForm] = Form.useForm();
  const [message, contextHolder] = useMessage();

  const fetchColumnAutomations = async () => {
    const result = await api.get(`/api/columns/${columnId}/automations`);
    return result.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['columnAutomations', columnId],
    queryFn: fetchColumnAutomations,
  });

  useEffect(() => {
    if (data) {
      createdAutomationForm.setFieldsValue({
        rules: data['task_created'] ?? [],
      });

      movedAutomationForm.setFieldsValue({
        rules: data['task_moved'] ?? [],
      });
    }
  }, [data]);

  if (isLoading) return <div>Loading...</div>;

  const disabledFields = showOnlyAutomations;


  // Handle form submission
  const handleCreatedAutomationFormSubmit = async (values: any) => {
    const { rules } = values;
    const body = {
      triggerKey: 'task_created',
      rules: rules.map((rule: any, i: number) => {
        let field;
        let value;

        switch (rule.action) {
          case 'change':
            field = rule.field;
            value = rule.value;
            break;
          case 'move':
            field = 'columnId';
            value = rule.columnId;
            break;
          case 'complete':
            field = 'complete';
            value = 'complete';
            break;
          case 'archive':
            field = 'archive';
            value = 'archive';
            break;
          default:
            field = 'columnId';
            break;

        }
        return {
          orderKey: i,
          action: rule.action,
          field: field,
          value: value,
        }
      }),
    };

    try {
      const response = await api.post(
        `/api/columns/${columnId}/automations`,
        body
      );
      message.success('Automation rules saved');
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  const handleMovedAutomationFormSubmit = async (values: any) => {
    const { rules } = values;
    const body = {
      triggerKey: 'task_moved',
      rules: rules.map((rule: any, i: number) => {
        let field;
        let value;

        switch (rule.action) {
          case 'change':
            field = rule.field;
            value = rule.value;
            break;
          case 'move':
            field = 'columnId';
            value = rule.columnId;
            break;
          case 'complete':
            field = 'complete';
            value = 'complete';
            break;
          case 'archive':
            field = 'archive';
            value = 'archive';
            break;
          default:
            field = 'columnId';
            break;

        }
        return {
          orderKey: i,
          action: rule.action,
          field: field,
          value: value,
        }
      }),
    };

    try {
      const response = await api.post(
        `/api/columns/${columnId}/automations`,
        body
      );
      message.success('Automation rules saved');
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Text>Automations</Text>
        </div>
      </div>
      <Divider style={{ margin: '.3rem 0' }} />

      {/* First Automation Card */}
      <Card variant='borderless'>
        <Form
          form={createdAutomationForm}
          layout='vertical'
          onFinish={handleCreatedAutomationFormSubmit}
          initialValues={{ rules: [] }}
        >
          <div style={{ marginBottom: '16px' }}>
            <span>
              When task is <Tag>created</Tag>in this column
            </span>
          </div>

          {/* Wrap Form.List with DragDropContext and Droppable */}
          <Form.List name='rules'>
            {(fields, { add, remove, move }) => (
              <DragDropContext
                onDragEnd={(result) => {
                  if (!result.destination) return;

                  const { source, destination } = result;
                  // Use the move function provided by Form.List
                  move(source.index, destination.index);
                }}
              >
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {fields.map((field, index) => (
                        <Draggable
                          key={field.key}
                          draggableId={`item-${field.key}`}
                          index={index}
                          isDragDisabled={disabledFields}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                                marginBottom: '8px',
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div
                                {...provided.dragHandleProps}
                                style={{ cursor: 'move', padding: '0 8px' }}
                              >
                                {!disabledFields && <>☰</>}
                              </div>
                              <RuleItem
                                key={field.key}
                                field={field}
                                remove={remove}
                                form={createdAutomationForm}
                                disabledFields={disabledFields}
                                columnTypeId={columnType}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      {!disabledFields && (
                        <>
                          <Form.Item>
                            <Button
                              type='dashed'
                              size='small'
                              onClick={() => add()}
                            >
                              + Add Rule
                            </Button>
                          </Form.Item>
                          <Form.Item style={{ textAlign: 'right' }}>
                            <Button
                              type='primary'
                              size='small'
                              htmlType='submit'
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Form.List>
        </Form>
      </Card>
      <br />
      {/* Second Automation Card */}
      <Card variant='borderless'>
        <Form
          form={movedAutomationForm}
          layout='vertical'
          onFinish={handleMovedAutomationFormSubmit}
          initialValues={{ rules: [] }}
        >
          <div style={{ marginBottom: '16px' }}>
            <span>
              When task is <Tag>moved</Tag>to this column
            </span>
          </div>

          {/* Wrap Form.List with DragDropContext and Droppable */}
          <Form.List name='rules'>
            {(fields, { add, remove, move }) => (
              <DragDropContext
                onDragEnd={(result) => {
                  if (!result.destination) return;

                  const { source, destination } = result;
                  // Use the move function provided by Form.List
                  move(source.index, destination.index);
                }}
              >
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {fields.map((field, index) => (
                        <Draggable
                          key={field.key}
                          draggableId={`item-${field.key}`}
                          index={index}
                          isDragDisabled={disabledFields}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                                marginBottom: '8px',
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div
                                {...provided.dragHandleProps}
                                style={{ cursor: 'move', padding: '0 8px' }}
                              >
                                {!disabledFields && <>☰</>}
                              </div>
                              <RuleItem
                                key={field.key}
                                field={field}
                                remove={remove}
                                form={movedAutomationForm}
                                disabledFields={disabledFields}
                                columnTypeId={columnType}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      {!disabledFields && (
                        <div>
                          <Form.Item>
                            <Button
                              type='dashed'
                              size='small'
                              onClick={() => add()}
                            >
                              + Add Rule
                            </Button>
                          </Form.Item>
                          <Form.Item style={{ textAlign: 'right' }}>
                            <Button
                              type='primary'
                              size='small'
                              htmlType='submit'
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </div>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Form.List>
        </Form>
      </Card>
    </>
  );
}

// Helper Components remain unchanged

function RuleItem({ field, remove, form, disabledFields, columnTypeId }: any) {
  const action = Form.useWatch(['rules', field.name, 'action'], form);
  const fieldType = Form.useWatch(['rules', field.name, 'field'], form);
  const boardId = Form.useWatch(['rules', field.name, 'boardId'], form);

  // Function to reset dependent fields
  const resetFields = (fieldsToReset: any) => {
    form.setFields(
      fieldsToReset.map((name: any) => ({
        name: ['rules', field.name, name],
        value: undefined,
      }))
    );
  };

  return (
    <>
      {/* <div style={{ marginRight: '8px' }}>
      </div> */}
        <span>Then:</span>
      <br />
      <br />
      <div
        key={field.key}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '5px',
        }}
      >

        {/* Action Type */}
        <Form.Item
          name={[field.name, 'action']}
          rules={[
            {
              required: true,
              message: 'Select an action',
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Select
            size='small'
            disabled={disabledFields}
            placeholder='Select Action'
            onChange={() => {
              resetFields(['field', 'value', 'boardId', 'columnId']);
            }}
          >
            <Select.OptGroup label='Task'>
              <Select.Option value='change'>Change</Select.Option>
              <Select.Option value='move'>Move to</Select.Option>
              {
                COLUMN_TYPE_IDS.done === columnTypeId && <Select.Option value='complete'>Complete</Select.Option>
              }
              <Select.Option value='archive'>Archive</Select.Option>
            </Select.OptGroup>
          </Select>
        </Form.Item>

        {/* Dynamic Fields Based on Action Type */}
        {action === 'change' && (
          <>
            {/* Field Type */}
            <Form.Item
              name={[field.name, 'field']}
              rules={[
                {
                  required: true,
                  message: 'Select a field',
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <ChangeFields
                fieldName={['rules', field.name, 'field']}
                disabledFields={disabledFields}
                form={form}
                onChange={() => {
                  resetFields(['value']);
                }}
              />
            </Form.Item>

            {/* Field Value */}
            {fieldType && (
              <Form.Item
                name={[field.name, 'value']}
                rules={[
                  {
                    required: true,
                    message: 'Select a value',
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <FieldsOptions
                  field={fieldType}
                  disabledFields={disabledFields}
                  form={form}
                  fieldName={['rules', field.name, 'value']}
                  onChange={(value: any) => {
                    form.setFieldValue(['rules', field.name, 'value'], value);
                  }}
                />
              </Form.Item>
            )}
          </>
        )}

        {action === 'move' && (
          <>
            {/* Board Selection */}
            <Form.Item
              name={[field.name, 'boardId']}
              rules={[
                {
                  required: true,
                  message: 'Select a board',
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <MoveToBoards
                form={form}
                fieldName={['rules', field.name, 'boardId']}
                disabledFields={disabledFields}
                onChange={() => {
                  resetFields(['columnId']);
                }}
              />
            </Form.Item>

            {/* Column Selection */}
            {boardId && (
              <Form.Item
                name={[field.name, 'columnId']}
                rules={[
                  {
                    required: true,
                    message: 'Select a column',
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <MoveToColumns
                  form={form}
                  fieldName={['rules', field.name, 'columnId']}
                  boardId={boardId}
                  disabledFields={disabledFields}
                  onChange={(value: any) => {
                    form.setFieldValue(['rules', field.name, 'columnId'], value);
                  }}
                />
              </Form.Item>
            )}
          </>
        )}

        {/* Remove Button */}
        {!disabledFields && (
          <Button type='link' danger onClick={() => remove(field.name)}>
            <DeleteOutlined />
          </Button>
        )}
      </div>
    </>
  );
}

function MoveToBoards({ onChange, form, fieldName, disabledFields }: any) {
  const params = useParams();
  const fetchBoards = async () => {
    const result = (
      await api.get(`/api/projects/${params.projectKey}/boards`)
    ).data.map((board: any) => ({
      value: board.id,
      label: board.label,
    }));

    return result;
  };

  const { isLoading, data } = useQuery({
    queryKey: ['boards-automation', params.projectKey],
    queryFn: fetchBoards,
  });

  return (
    <Select
      size='small'
      loading={isLoading}
      disabled={disabledFields}
      placeholder='Select Board'
      value={form.getFieldValue(fieldName)}
      showSearch
      filterOption={(input, option: any) => {
        return (
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      onChange={onChange}
    >
      {data?.map((opt: any) => (
        <Select.Option key={opt.value} value={opt.value}>
          {opt.label}
        </Select.Option>
      ))}
    </Select>
  );
}

function MoveToColumns({
  boardId,
  onChange,
  form,
  fieldName,
  disabledFields,
}: any) {
  const fetchColumns = async () => {
    const result = (await api.get(`/api/boards/${boardId}/columns`)).data.map(
      (column: any) => ({
        value: column.id,
        label: column.label,
      })
    );

    return result;
  };

  const { isLoading, data } = useQuery({
    queryKey: ['columns', boardId],
    queryFn: fetchColumns,
  });

  return (
    <Select
      size='small'
      disabled={disabledFields}
      placeholder='Select Column'
      loading={isLoading}
      value={form.getFieldValue(fieldName)}
      onChange={onChange}
      showSearch
      filterOption={(input, option: any) => {
        return (
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
    >
      {data?.map((opt: any) => (
        <Select.Option key={opt.value} value={opt.value}>
          {opt.label}
        </Select.Option>
      ))}
    </Select>
  );
}

function ChangeFields({ onChange, fieldName, form, disabledFields }: any) {
  return (
    <Select
      size='small'
      disabled={disabledFields}
      placeholder='Select Field'
      value={form.getFieldValue(fieldName)}
      onChange={onChange}
    >
      <Select.Option value='assigneeId'>Assignee</Select.Option>
      <Select.Option value='typeId'>Type</Select.Option>
      <Select.Option value='priorityId'>Priority</Select.Option>
      <Select.Option value='questId'>Quest</Select.Option>
    </Select>
  );
}

function FieldsOptions({
  field,
  onChange,
  form,
  fieldName,
  disabledFields,
}: any) {
  const [options, setOptions]: any[] = useState([]);

  if (!field) {
    return (
      <Select size='small' placeholder='Select Value' disabled>
        <Select.Option value=''>Select a field first</Select.Option>
      </Select>
    );
  }

  const params = useParams();

  const fetchPropertyOptions = async () => {
    if (['assigneeId', 'questId'].includes(field)) {
      if (field === 'assigneeId') {
        const result = (
          await api.get(`/api/projects/${params.projectKey}/users`)
        ).data.map((assignee: any) => ({
          value: assignee.id,
          label: assignee.fullName,
        }));
        return result;
      }
      if (field === 'questId') {
        const result = (
          await api.get(
            `/api/projects/${params.projectKey}/quests?filter=active`
          )
        ).data.map((quest: any) => ({
          value: quest.id,
          label: quest.label,
        }));
        return result;
      }
    } else {
      switch (field) {
        case 'typeId':
          return [
            { value: 'ttkefwc4qtaaogcxrkyu98x0', label: 'Task' },
            { value: 'tzz06j7svw51umjdd0ov5ey8', label: 'Bug' },
            { value: 'w38qzcuzxm81h4jarp4aog9g', label: 'Story' },
          ];

        case 'priorityId':
          return [
            { value: 'dvxt57fk0jdot8hap0e8ypx2', label: 'Lowest' },
            { value: 'a7szfdlji72zq2cxi5wyu31h', label: 'Low' },
            { value: 'tdkcw9in2xgbq1lrpd4phsmk', label: 'Normal' },
            { value: 'mdlejnloz445oinelehbnx90', label: 'High' },
            { value: 'xsuqk8z7qx3uoykyih8l2wzz', label: 'Highest' },
          ];
      }
    }
    return [];
  };

  const { isLoading, data } = useQuery({
    queryKey: ['propertyOptions', field],
    queryFn: fetchPropertyOptions,
  });

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  return (
    <Select
      size='small'
      disabled={disabledFields}
      value={form.getFieldValue(fieldName)}
      placeholder='Select Value'
      onChange={onChange}
      showSearch
      filterOption={(input, option: any) => {
        return (
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      loading={isLoading}
    >
      {options.map((opt: any) => (
        <Select.Option key={opt.value} value={opt.value}>
          {opt.label}
        </Select.Option>
      ))}
    </Select>
  );
}
