import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { App, Button, Card, Select, Tag } from 'antd';
import useApp from 'antd/es/app/useApp';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

export default function AddOrCreateTask({
  questId,
  refetch,
}: {
  questId: string;
  refetch: (questId: string) => void;
}) {
  const { message } = useApp();
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [showAddTask, setShowAddTask] = useState(false);
  const getAvailableTasks = async () => {
    const { data } = await api.get(`/api/quests/${questId}/available-tasks`);
    return data;
  };

  const {
    data,
    isLoading,
    refetch: refetchAvailableTasks,
  } = useQuery({
    queryKey: ['availableTasks', questId],
    queryFn: getAvailableTasks,
    enabled: showAddTask,
  });

  const attachTask = async (taskId: string) => {
    try {
      await api.put(`api/tasks/${taskId}`, {
        questId,
      });
      setSelectedTaskId(null);
      await refetchAvailableTasks();
      await refetch(questId);
      message.success('Task attached to quest');
    } catch (error) {
      setSelectedTaskId(null);
      console.error(error);
      if (error instanceof AxiosError) {
        if (error?.response?.data?.message) {
          message.error(error.response.data.message);
          return;
        }
      }
      message.error('Failed to attach task to quest');
    }
  };

  useEffect(() => {
    if (selectedTaskId) {
      attachTask(selectedTaskId);
    }
  }, [selectedTaskId]);

  return (
    <>
      <Button
        onClick={() => setShowAddTask(!showAddTask)}
        type='default'
        style={{ marginBottom: 16 }}
      >
        {showAddTask ? 'Hide' : 'Add Task'}
      </Button>
      {
        showAddTask && (
          <Select
            showSearch
            loading={isLoading}
            style={{ width: '100%' }}
            value={selectedTaskId}
            options={data?.map((task: any) => ({
              ...task,
              value: task.id,
              label: `${task.key} - ${task.label}`,
            }))}
            placeholder='Add task'
            optionRender={(task: any) => (
              <Card size='small' variant='borderless'>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}>
                  <div>{task.label}</div>
                  <div><Tag>{task.data.boardLabel}</Tag> <Tag> {task.data.columnLabel}</Tag></div>

                </div>
              </Card>
            )}
            optionFilterProp='children'
            onChange={(value) => setSelectedTaskId(value)}
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        )
      }
    </>
  );
}
