import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { AutoComplete, Avatar, Button, Spin, Tooltip } from 'antd';
import Text from 'antd/es/typography/Text';
import { useEffect, useRef, useState } from 'react';
import UserAvatar from '../UserAvatar';
import { CloseCircleOutlined } from '@ant-design/icons';

interface Assignee {
  id: string;
  fullName: string;
  avatar: string | null;
}

export default function QuickChangeAssignee({
  projectKey,
  taskId,
  currentAssigneeId,
  tooltipName,
  onChangeAssignee,
  avatarSize,
  showName,
  showToolTip,
  disabled,
}: {
  projectKey: string;
  taskId?: string;
  currentAssigneeId: string;
  tooltipName?: string;
  avatarSize?: number;
  showToolTip?: boolean;
  onChangeAssignee?: (assigneeId: {
    value: string;
    label: string;
    avatar: string;
  } | null) => void;
  showName?: boolean;
  disabled?: boolean;
}) {
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [currentAssigneeAvatar, setCurrentAssigneeAvatar] = useState(null);
  const [currentAssigneeName, setCurrentAsigneeName]: any = useState(null);
  const ref: any = useRef(null);

  const getProjectUsers = async (): Promise<Assignee[]> => {
    const responseUsers = await api.get(`/api/projects/${projectKey}/users`);

    responseUsers.data.unshift({
      id: null,
      fullName: 'Unassign',
      avatar: 'unassigned',
    });

    return responseUsers.data;
  };

  const {
    data,
    isLoading: isLoadingUsers,
    error,
  }: any = useQuery({
    queryKey: ['projectUsers', projectKey],
    queryFn: getProjectUsers,
  });

  useEffect(() => {
    if (data) {
      const currentAssignee = data.find(
        (user: Assignee) => user.id === currentAssigneeId
      );

      if (currentAssignee) {
        setCurrentAssigneeAvatar(currentAssignee.avatar);
        setCurrentAsigneeName(currentAssignee.fullName);
      }
    }
  }, [data, currentAssigneeId]);

  if (isLoadingUsers) return <Spin />;

  return (
    <>
      {!showAutocomplete && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            if (!disabled) {
              setShowAutocomplete(true);
            }
          }}
        >
          {!currentAssigneeId && (
            <Tooltip title={showToolTip ? 'Unassigned' : undefined}>
              <Avatar
                size={avatarSize || 25}
                style={{
                  backgroundColor: 'orange',
                }}
                icon={<CloseCircleOutlined />}
              />
              {showName && (
                <Text style={{ marginLeft: '.5rem' }}>Unassigned</Text>
              )}
            </Tooltip>
          )}
          {currentAssigneeId && (
            <UserAvatar
              token={currentAssigneeAvatar}
              size={avatarSize || 25}
              userName={tooltipName || currentAssigneeName}
              showName={showName}
              showToolTip={showToolTip}
            />
          )}
        </span>
      )}
      {showAutocomplete && (
        <div style={{ position: 'relative' }}>
          <AutoComplete
            ref={ref}
            style={{
              width: '300px',
              position: 'absolute',
              right: '0px',
              zIndex: 99999,
            }}
            autoFocus
            open
            size='small'
            optionRender={(user) => (
              <span style={{ display: 'flex', gap: '.5rem' }}>
                {user.data.avatar === 'unassigned' && (
                  <Avatar
                    size={25}
                    style={{
                      backgroundColor: 'orange',
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )}
                {user.data.avatar !== 'unassigned' && (
                  <UserAvatar token={user.data.avatar} size={25} />
                )}
                {user.label}
              </span>
            )}
            onClick={(e) => {
              e.stopPropagation();
              setShowAutocomplete(false);
            }}
            options={data.map((user: Assignee) => ({
              value: user.id,
              label: user.fullName,
              avatar: user.avatar,
            }))}
            onBlur={(e) => {
              setShowAutocomplete(false);
            }}
            placeholder='Assignee'
            filterOption={(inputValue, option) =>
              (option?.label as string)
                ?.toUpperCase()
                .indexOf((inputValue as string).toUpperCase()) !== -1
            }
            onSelect={async (value, option: any) => {
              if (value === currentAssigneeId) {
                return;
              }
              try {
                if (taskId) {
                  await api.put(`/api/tasks/${taskId}`, {
                    assigneeId: value,
                  });
                }
                setShowAutocomplete(false);
                if (onChangeAssignee) {
                  if (option) {
                    setCurrentAsigneeName(option.label);
                    setCurrentAssigneeAvatar(option.avatar);
                  }
                  onChangeAssignee(option);
                }
              } catch (error) {
                console.log(error);
              }
            }}
          />
        </div>
      )}
    </>
  );
}
