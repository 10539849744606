import React from 'react';
import { Tabs, TabsProps, Layout } from 'antd';
import Quests from '@/pages/quests';
import GanttChart from './GanttChart';
import { configureDayjs } from './dayjs.config';

/**
 * Main application:
 *  - Renders tabs: List & Timeline
 */
export default function Timeline() {

  configureDayjs();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'List',
      children: <Quests />,
    },
    {
      key: '2',
      label: 'Timeline',
      destroyInactiveTabPane: true,
      children: (
        <Layout>
          <GanttChart />
        </Layout>
      ),
    },
  ];

  return <Tabs defaultActiveKey='1' items={items} type='card' />;
}
