import { api } from '@/api';
import { useMyTheme } from '@/hooks/useTheme';
import { useUserStore } from '@/state/userState';
import { SmileOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import EmojiPicker, { EmojiStyle, Theme } from 'emoji-picker-react';
import { useEffect, useState } from 'react';

export default function Reactions({
  commentId,
  commentReactions,
}: {
  commentId: string;
  commentReactions: any;
}) {
  const [reactions, setReactions]: any = useState([]);
  const [theme] = useMyTheme();
  const [isReacting, setIsReacting] = useState(false);

  const userStore = useUserStore();

  useEffect(() => {
    if (commentReactions) {
      const reactions: any = [];
      for (const cr in commentReactions) {
        if (cr === 'no-key') {
          continue;
        }
        reactions.push({ emoji: cr, ...commentReactions[cr] });
      }
      setReactions(reactions);
    }
  }, [commentReactions]);

  const [open, setOpen] = useState(false);

  const onReactionAdd = async (emoji: any) => {
    setIsReacting(true);
    try {
      await api.post(`/api/comments/${commentId}/react`, { emoji: emoji });
      // const existingReaction = reactions.find((r: any) => r.emoji === emoji);
      // if (existingReaction) {
      //   setReactions((prev: any) =>
      //     prev.map((r: any) => {
      //       if (r.emoji === emoji) {
      //         return {
      //           ...r,
      //           count: r.count + 1,
      //           users: [...r.users, userStore?.user],
      //         };
      //       }
      //       return r;
      //     })
      //   );
      // } else {
      //   setReactions((prev: any) => [
      //     ...prev,
      //     {
      //       emoji,
      //       count: 1,
      //       users: [userStore?.user],
      //     },
      //   ]);
      // }
    } catch (error) {
      console.log(error, ' pi jebgiaa');
    }
    setIsReacting(false);
  };

  const onReactionRemove = async (emoji: any) => {
    setIsReacting(true);
    try {
      await api.put(`/api/comments/${commentId}/react`, {
        emoji: emoji,
      });
      // setReactions((prev: any) =>
      //   prev
      //     .map((r: any) => {
      //       if (r.emoji === emoji) {
      //         return {
      //           ...r,
      //           currentUserReacted: false,
      //           count: r.count - 1,
      //           users: r.users.filter((u: any) => u.id !== userStore?.user?.id),
      //         };
      //       }
      //       return r;
      //     })
      //     .filter((r: any) => r.count > 0)
      // );
    } catch (error) {
      console.log(error, ' pi jebgiaa');
    }
    setIsReacting(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex' }}>
        {
          <div style={{ display: 'flex', gap: '.3rem' }}>
            {reactions.map((r: any) => (
              <Popover
                content={() => {
                  return (
                    <div>
                      {r.users &&
                        r.users.map((u: any) => u.fullName).join(', ')}
                    </div>
                  );
                }}
                key={r.emoji}
                trigger='hover'
              >
                <Button
                  size='small'
                  disabled={isReacting}
                  type={
                    r.users.find((u: any) => u.id === userStore?.user?.id)
                      ? 'primary'
                      : 'text'
                  }
                  key={r.emoji}
                  onClick={() => {
                    if (
                      r.users.find((u: any) => u.id === userStore?.user?.id)
                    ) {
                      onReactionRemove(r.emoji);
                      return;
                    }
                    onReactionAdd(r.emoji);
                  }}
                >
                  {r.emoji} {r.count}
                </Button>
              </Popover>
            ))}
          </div>
        }
        <Popover content='Add reaction'>
          <Button
            style={{ marginLeft: '.3rem' }}
            size='small'
            type='text'
            disabled={isReacting}
            onClick={() => setOpen(!open)}
          >
            +<SmileOutlined />
          </Button>
        </Popover>
      </div>

      <EmojiPicker
        theme={theme === 'dark' ? Theme.DARK : Theme.LIGHT}
        emojiStyle={EmojiStyle.NATIVE}
        reactionsDefaultOpen={true}
        onReactionClick={async (reaction) => {
          await onReactionAdd(reaction.emoji);
          setOpen(false);
        }}
        onEmojiClick={async (reaction) => {
          await onReactionAdd(reaction.emoji);
          setOpen(false);
        }}
        open={open && !isReacting}
        style={{
          position: 'absolute',
          left: 25,
          zIndex: 9999000,
          borderRadius: '4px',
        }}
      />
    </div>
  );
}
