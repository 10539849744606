import { useEffect, useState } from 'react';
import { api } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Result,
  Skeleton,
} from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { AxiosError } from 'axios';

export function Invite() {
  const [response, setResponse]: any = useState(null);
  const [expired, setExpired] = useState(false);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { inviteId } = useParams();

  const getResponse = async () => {
    try {
      const response = await api.get(`/api/invite/${inviteId}`);
      setResponse(response.data);
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 404) {
        setExpired(true);
      }
    }
  };

  useEffect(() => {
    getResponse();
  }, []);

  if (expired) {
    return (
      <Card style={{ width: '30%', margin: '0 auto', marginTop: '1rem' }}
      variant='borderless'>
        <Result
          status='error'
          title='Invite Expired'
          subTitle='The invite you are trying to access has expired'
          extra={[
            <Button type='primary' key='console' onClick={() => window.close()}>
              Close
            </Button>,
          ]}
        />
      </Card>
    );
  }
  if (!response) return <Skeleton active />;

  const acceptInvite = async (values: any) => {
    setErrorMessage(null);
    setLoading(true);
    if (values.password !== values.confirmPassword) {
    }
    try {
      await api.post(`/api/invite/${inviteId}/accept`, values);
      setResponse({ joined: true });
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data.message);
      }
      console.log(error);
    }
    setLoading(false);
  };

  const validatePassword = (_: any, value: any) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(new Error('The two passwords do not match!'));
    }
    return Promise.resolve();
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#1677ff',
          borderRadius: 4,
        },
        components: {},
      }}
    >
      {response?.joined && (
        <Result
          status='success'
          title='Successfully Joined'
          subTitle='You have successfully joined the team'
          extra={[
            <Button
              type='primary'
              key='console'
              onClick={() => navigate('/login')}
            >
              Go to Login
            </Button>,
          ]}
        />
      )}
      {!response?.joined && response.requireMoreInfo && (
        <div style={{ width: '20vw', margin: '5rem auto' }}>
          <Card variant='borderless'>
            <Title style={{ textAlign: 'center' }} level={3}>
              Complete Registration
            </Title>
            <Form
              form={form}
              name='basic'
              autoComplete='off'
              layout='vertical'
              disabled={loading}
              onFinish={acceptInvite}
            >
              <Form.Item
                label='Full Name'
                name='fullName'
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label='Confirm Password'
                name='confirmPassword'
                dependencies={['password']}
                rules={[
                  { required: true, message: 'Please confirm your password!' },
                  { validator: validatePassword },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type='primary' htmlType='submit'
                  loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
            {
              errorMessage &&
              <Card style={{ margin: ".5rem 0" }} variant='borderless'>
                <Text type='danger'>{errorMessage}</Text>
              </Card>

            }
          </Card>
        </div>
      )}
    </ConfigProvider>
  );
}
