export const COLUMN_TYPES = [
  {
    id: "n3qgl340ouzenmw20ajjkdbo",
    label: "Backlog",
    key: "backlog",
    description:
      "Tasks that are planned for the future, but are not yet ready to be worked on.",
  },
  {
    id: "j82senyrwaaza0m8omtn6jgu",
    label: "To Do",
    key: "todo",
    description: "Tasks that are ready to be worked on.",
  },
  {
    id: "gt2i39u4ierremke4g926x0x",
    label: "In Progress",
    key: "in_progress",
    description: "Tasks that are currently being worked on.",
  },
  {
    id: "b7p0jiayd4tjnp5zkw5qo06w",
    label: "Done",
    key: "done",
    description: "Task that are completed and do not need any more work.",
  },
];

export const COLUMN_TYPE_IDS: {
  todo: string;
  in_progress: string;
  done: string;
  backlog: string;
} = COLUMN_TYPES.reduce((acc: any, columnType) => {
  acc[columnType.key] = columnType.id;
  return acc;
}, {});

export const COLUMN_TYPE_COLORS: {
  [key: string]: string;
} = {
  backlog: "blue",
  [COLUMN_TYPE_IDS.backlog]: "blue",
  [COLUMN_TYPE_IDS.todo]: "orange",
  [COLUMN_TYPE_IDS.in_progress]: "purple",
  [COLUMN_TYPE_IDS.done]: "green",
  todo: "orange",
  in_progress: "purple",
  done: "green",
};
