import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import weekOfYear from 'dayjs/plugin/weekOfYear';

// Configure dayjs with all required plugins
export function configureDayjs() {
  dayjs.extend(minMax);
  dayjs.extend(quarterOfYear);
  dayjs.extend(isBetween);
  dayjs.extend(isoWeek);
  dayjs.extend(isToday);
  dayjs.extend(weekOfYear);
}

// Call this function at application startup
// configureDayjs();

export default dayjs;
