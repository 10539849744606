import React, { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  MenuProps,
  Modal,
  Tag,
  theme,
  FloatButton,
  Progress,
  Drawer,
  Divider,
  Space,
  App,
  Card,
} from 'antd';
import { Avatar } from 'antd';
import BoardSelector from './BoardWrapper/BoardSelector';
import ProjectSelector from '../components/projectSelector';
import {
  AppstoreAddOutlined,
  ArrowLeftOutlined,
  CarryOutOutlined,
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FieldTimeOutlined,
  FontSizeOutlined,
  InfoCircleFilled,
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
  PlusOutlined,
  ProductOutlined,
  SettingOutlined,
  SunOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Title from 'antd/es/typography/Title';

import { api } from '../api';
import { useThemeModeStore } from '../state/themeState';
import { useQuery } from '@tanstack/react-query';
import TaskDetailsModal2 from '../modals/taskModal';
import AccountSettings from '../modals/AccountSettings';
import UserAvatar from '../components/UserAvatar';
import CreateTaskModalGlobal from '../modals/CreateTaskGlobal';
import { useProjectStore } from '@/state/projectState';
import NotificationDropdown from '@/components/NotificationsMenu';
import { useUserStore } from '@/state/userState';
import LevelMap from '@/components/LevelMap';
import { AliasToken } from 'antd/es/theme/internal';
import { getPermissions } from '@/permissions';
import TaskDetailsMain from '@/components/TaskDetailsMain';
import { useOptionStore, useShowUpgradeModal } from '@/state/options';
import { Icon } from '@iconify/react';
import { CustomIcon } from '@/components/Icon';
import SearchModal from '@/modals/Search';
import Text from 'antd/es/typography/Text';
import { sseClient } from '@/api/realtime';
import useIsMobile from '@/hooks/useMobile';

const { Header, Sider, Content } = Layout;

const Wrapper2: React.FC = () => {
  const navigate = useNavigate();
  const currentTheme = useThemeModeStore();
  const [subdomain, setSubdomain] = useState('');

  const { pathname } = useLocation();

  const { projectKey, taskId } = useParams();

  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const [shortcutLabel, setShortcutLabel] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const [imageKey, setImageKey] = useState(null);

  const [showCreateTaskGlobal, setShowCreateTaskGlobal] = useState(false);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false);
  const [userSettingsModalOpen, setUserSettingsModalOpen] = useState(false);
  const [taskKey, setTaskKey]: any = useState(null);

  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [showLevelInfoModal, setShowLevelInfoModal] = useState(false);

  const projectStore = useProjectStore();
  const userStore = useUserStore();

  useEffect(() => {
    const subdomain = window.location.host.split('.')[0];
    setSubdomain(subdomain);
  }, []);

  const getMe = async () => {
    try {
      const response = await api.get(`/api/me`);
      userStore.setUser(response.data);
      setImageKey(response.data.avatar);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch,
    error,
  } = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
  });

  useEffect(() => {
    if (searchParams.get('openTask')) {
      setTaskKey(searchParams.get('openTask'));
      if (optionStore.options.openTaskInDrawer) {
        optionStore.setTaskOpen(true);
      }
      setShowTaskDetailsModal(true);
    }
  }, [searchParams]);

  let themeToSelect = theme.defaultAlgorithm;

  switch (currentTheme.theme) {
    case 'dark': {
      themeToSelect = theme.darkAlgorithm;

      break;
    }
    default: {
      themeToSelect = theme.defaultAlgorithm;
    }
  }

  const handleLogout = async () => {
    // Logout logic
    await api.post('/api/logout', {}, { withCredentials: true });
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      // Check if "cmd/ctrl + /" keys are pressed together
      if (event.key === '/' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        // Add your logic here
        setSearchModalOpen(true);
      }
    };

    if (navigator.userAgent.includes('Mac')) {
      setShortcutLabel('⌘ + /');
    } else {
      setShortcutLabel('Ctrl + /');
    }

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!taskId && optionStore.options.openTaskInDrawer) {
      optionStore.setTaskOpen(false);
    }
  }, [taskId]);

  const optionStore = useOptionStore();

  useEffect(() => {
    if (localStorage.getItem('openTaskInDrawer')) {
      optionStore.setOption({
        ...optionStore.options,
        openTaskInDrawer: localStorage.getItem('openTaskInDrawer') === 'true',
      });
    }

    if (localStorage.getItem('fontSize')) {
      optionStore.setOption({
        ...optionStore.options,
        fontSize: parseInt(localStorage.getItem('fontSize') as string),
      });
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('siderCollapsed')) {
      setIsSiderCollapsed(localStorage.getItem('siderCollapsed') === 'true');
    }
  }, []);

  const items: MenuProps['items'] = [
    {
      label: `Account`,
      key: '0',
      icon: <UserOutlined />,
      onClick: () => {
        setUserSettingsModalOpen(true);
      },
    },
    {
      type: 'divider',
    },
    {
      label: `Theme: ${currentTheme.theme}`,
      key: 'theme',
      icon: <SunOutlined />,
      onClick: (e) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
        currentTheme.toggleThemeMode();
      },
    },
    // {
    //   label: `Compact: ${optionStore.options.compactMode ? 'On' : 'Off'}`,
    //   key: 'themeMode',
    //   icon: <SunOutlined />,
    //   onClick: (e) => {
    //     e.domEvent.preventDefault();
    //     e.domEvent.stopPropagation();
    //     localStorage.setItem(
    //       'compactMode',
    //       `${!optionStore.options.compactMode}`
    //     );
    //     optionStore.setOption({
    //       openTaskInDrawer: optionStore.options.openTaskInDrawer,
    //       fontSize: optionStore.options.fontSize,
    //       compactMode: !optionStore.options.compactMode,
    //     });
    //   },
    // },
    {
      label: `Open Task: ${optionStore.options.openTaskInDrawer ? 'Drawer' : 'Modal'
        }`,
      key: 'taskMode',
      icon: <SunOutlined />,
      onClick: (e) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
        localStorage.setItem(
          'openTaskInDrawer',
          `${!optionStore.options.openTaskInDrawer}`
        );
        optionStore.setOption({
          openTaskInDrawer: !optionStore.options.openTaskInDrawer,
          fontSize: optionStore.options.fontSize,
          compactMode: optionStore.options.compactMode,
        });
      },
    },
    // {
    //   label: (
    //     <div>
    //       Font Size: {optionStore.options.fontSize}{' '}
    //       <Button
    //         type='text'
    //         onClick={() => {
    //           if (optionStore.options.fontSize <= 10) return;
    //           localStorage.setItem(
    //             'fontSize',
    //             (optionStore.options.fontSize - 1).toString()
    //           );
    //           optionStore.setOption({
    //             ...optionStore.options,
    //             fontSize: optionStore.options.fontSize - 1,
    //           });
    //         }}
    //       // size='small'
    //       >
    //         -
    //       </Button>{' '}
    //       <Button
    //         type='text'
    //         onClick={() => {
    //           if (optionStore.options.fontSize >= 25) return;
    //           localStorage.setItem(
    //             'fontSize',
    //             (optionStore.options.fontSize + 1).toString()
    //           );
    //           optionStore.setOption({
    //             ...optionStore.options,
    //             fontSize: optionStore.options.fontSize + 1,
    //           });
    //         }}
    //       // size='small'
    //       >
    //         +
    //       </Button>{' '}
    //     </div>
    //   ),
    //   key: 'fontSize',
    //   icon: <FontSizeOutlined />,
    // },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: 'logout',
      onClick: handleLogout,
      icon: <LogoutOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: 'Suggest a feature',
      key: 'feature',
      icon: <AppstoreAddOutlined />,
      onClick: () => {
        window.open('https://questiro.features.vote/board', '_blank');
      },
    },
  ];

  const global: Partial<AliasToken> = {
    // colorPrimary: '#24a0ed',
    colorLink: '#24a0ed',
    colorLinkHover: '#1890ff',
    fontSize: 16,
    margin: 16,
    padding: 5,
    // fontFamily: 'monospace',
    colorSuccess: '#52c41a',
    colorWarning: '#faad14',
    colorError: '#f5222d',
    colorInfo: '#1890ff',
    motion: false,
    borderRadius: 4,
  };

  const darkThemeTokens = {
    colorBgBase: '#101012',
    ...global,
  };
  const lightThemeTokens: Partial<AliasToken> = {
    colorBgBase: '#FFF',
    ...global,
  };

  const sm = useShowUpgradeModal();

  const siderAndHeaderBackground =
    currentTheme.theme === 'dark' ? '#191920' : 'white';

  const currentThemeTokens =
    currentTheme.theme === 'dark' ? darkThemeTokens : lightThemeTokens;

  const permissions = getPermissions(userData?.tierKey);

  const isMobile = useIsMobile();

  const size = isMobile ? 'small' : 'middle';

  const compactOption = optionStore.options.compactMode
    ? [theme.compactAlgorithm]
    : [];

  return (
    <ConfigProvider
      theme={{
        algorithm: [themeToSelect, theme.compactAlgorithm],
        token: currentThemeTokens,
      }}
    >
      <App>
        <Layout
          style={{
            maxHeight: '100vh',
          }}
        >
          <Sider
            theme={currentTheme.theme}
            width={isSiderCollapsed ? 0 : isMobile ? 180 : 230}
            style={{
              minHeight: '100vh',
              backgroundColor: siderAndHeaderBackground,
            }}
          >
            <div style={{
              marginTop: '.8rem', marginLeft: '.3rem',
              display: isSiderCollapsed ? 'none' : 'block',
            }}>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  marginBottom: '.5rem',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Button
                    type='text'
                    size={size}
                    icon={<MenuOutlined />}
                    title={
                      isSiderCollapsed ? 'Expand sidebar' : 'Collapse sidebar'
                    }
                    onClick={() => {
                      localStorage.setItem(
                        'siderCollapsed',
                        `${!isSiderCollapsed}`
                      );
                      setIsSiderCollapsed(!isSiderCollapsed);
                    }}
                  />
                </div>
                <div>
                  <Text>{subdomain}</Text>
                </div>
              </div>
              {!pathname.includes('/me') &&
                !pathname.includes('/admin') &&
                !pathname.includes('/t/') && (
                  <div>
                    <ProjectSelector />
                    {projectKey && <Divider style={{ margin: '.5rem 0' }} />}
                    {projectKey && <BoardSelector />}
                  </div>
                )}
              {pathname.includes('/t/') && (
                <div>
                  <div>
                    <Button
                      style={{ width: '100%', justifyContent: 'left' }}
                      size={size}
                      type={'text'}
                      onClick={() => {
                        navigate('/');
                      }}
                    >
                      <ArrowLeftOutlined />
                      Back to workspace
                    </Button>
                  </div>
                  <br />
                  <div>
                    <Button
                      style={{ width: '100%', justifyContent: 'left' }}
                      type={'text'}
                      size={size}
                      onClick={() => {
                        window.close();
                      }}
                    >
                      <CloseOutlined />
                      Close tab
                    </Button>
                  </div>
                </div>
              )}
              {pathname.includes('/me') && (
                <div>
                  <Divider style={{ margin: '.5rem 0' }} />
                  <Space direction='vertical' style={{ width: '100%' }}>
                    <div>
                      <Button
                        style={{ width: '100%', justifyContent: 'left' }}
                        type={pathname.endsWith('/me') ? 'primary' : 'text'}
                        size={size}
                        onClick={() => {
                          navigate('/me');
                        }}
                      >
                        <UnorderedListOutlined />
                        Tasks
                      </Button>
                    </div>

                    {permissions?.timesheets && (
                      <div>
                        <Button
                          style={{ width: '100%', justifyContent: 'left' }}
                          size={size}
                          type={
                            pathname.endsWith('/me/timesheet')
                              ? 'primary'
                              : 'text'
                          }
                          onClick={() => {
                            navigate('/me/timesheet');
                          }}
                        >
                          <FieldTimeOutlined />
                          Timesheet
                        </Button>
                      </div>
                    )}
                  </Space>
                </div>
              )}
              {pathname.includes('/admin') && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {/* <Button
                  style={{ width: '100%', justifyContent: 'left' }}
                  size='middle'
                  type={'text'}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <ArrowLeftOutlined />
                  Back to workspace
                </Button> */}
                  <Divider style={{ margin: '.5rem 0' }} />
                  <Space direction='vertical' style={{ width: '100%' }}>
                    <Button
                      style={{ width: '100%', justifyContent: 'left' }}
                      size={size}
                      type={
                        pathname.endsWith('/admin/projects')
                          ? 'primary'
                          : 'text'
                      }
                      onClick={() => {
                        navigate('/admin/projects');
                      }}
                    >
                      <ProductOutlined />
                      Projects
                    </Button>

                    {permissions?.timesheets && (
                      <Button
                        style={{ width: '100%', justifyContent: 'left' }}
                        size={size}
                        type={
                          pathname.endsWith('/admin/timesheets')
                            ? 'primary'
                            : 'text'
                        }
                        onClick={() => {
                          navigate('/admin/timesheets');
                        }}
                      >
                        <FieldTimeOutlined />
                        Timesheets
                      </Button>
                    )}
                    {userData?.isAdmin && (
                      <Button
                        style={{ width: '100%', justifyContent: 'left' }}
                        size={size}
                        type={pathname.endsWith('/admin') ? 'primary' : 'text'}
                        onClick={() => {
                          navigate('/admin');
                        }}
                      >
                        <SettingOutlined />
                        Settings
                      </Button>
                    )}
                  </Space>
                </div>
              )}
            </div>
          </Sider>
          <Layout>
            <Header
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 5,
                backgroundColor: siderAndHeaderBackground,
                overflow: 'scroll',
              }}
            >
              {/* // left side */}
              <div
                style={{
                  display: 'flex',
                  gap: isMobile ? '.3rem' : '1rem',
                }}
              >
                {isSiderCollapsed && (
                  <div>
                    <Button
                      type='text'
                      size={size}
                      icon={<MenuOutlined />}
                      title={
                        isSiderCollapsed ? 'Expand sidebar' : 'Collapse sidebar'
                      }
                      onClick={() => {
                        localStorage.setItem(
                          'siderCollapsed',
                          `${!isSiderCollapsed}`
                        );
                        setIsSiderCollapsed(!isSiderCollapsed);
                      }}
                    />
                  </div>
                )}

                <div>
                  <Button
                    type='text'
                    size={size}
                    icon={<TableOutlined />}
                    style={{ width: '100%' }}
                    onClick={() => {
                      if (pathname.startsWith('/project')) {
                        return;
                      }
                      navigate('/');
                    }}
                  >
                    Workspace
                  </Button>
                </div>

                <div>
                  <Button
                    type='text'
                    style={{ width: '100%' }}
                    size={size}
                    onClick={() => {
                      navigate('/me');
                    }}
                  >
                    <CarryOutOutlined />
                    My Work
                  </Button>
                </div>

                {(userData?.isAdmin || userStore.user?.isManager) && (
                  <div>
                    <Button
                      type='text'
                      style={{ width: '100%' }}
                      size={size}
                      onClick={() => {
                        navigate('/admin/projects');
                      }}
                    >
                      <LockOutlined />
                      Admin
                    </Button>
                  </div>
                )}
              </div>
              {/* // right side */}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobile ? '.3rem' : '1rem',
                }}
              >
                <div>
                  {projectStore.hasProjects && (
                    <Button
                      onClick={() => {
                        setShowCreateTaskGlobal(true);
                      }}
                      type='primary'
                      size={size}
                    >
                      <PlusOutlined /> Task
                    </Button>
                  )}
                </div>
                <div>
                  {isMobile ? (
                    <Button
                      type='text'
                      size={size}
                      onClick={() => {
                        setSearchModalOpen(true);
                      }}
                    >
                      Search
                    </Button>
                  ) : (
                    <Input
                      placeholder={`Search ( ${shortcutLabel} )`}
                      onClick={() => {
                        setSearchModalOpen(true);
                      }}
                    />
                  )}
                </div>
                <NotificationDropdown
                  updatePoints={() => {
                    refetch();
                  }}
                />
                <Dropdown
                  open={dropdownOpen}
                  onOpenChange={(open, info) => {
                    if (info.source === 'trigger' || open) {
                      setDropdownOpen(open);
                    }
                  }}
                  trigger={['click']}
                  menu={{
                    items,
                    onClick: (e) => {
                      if (
                        [
                          'fontSize',
                          'increase',
                          'theme',
                          'taskMode',
                          'themeMode',
                        ].includes(e.key)
                      ) {
                        return;
                      }
                      setDropdownOpen(false);
                    },
                  }}
                >
                  {imageKey ? (
                    <div>
                      <UserAvatar token={imageKey} size={40} />
                    </div>
                  ) : (
                    <Avatar size={40} icon={<UserOutlined />} />
                  )}
                </Dropdown>
                {/* <Dropdown menu={{ items }} trigger={['click']}>
                <Avatar size={40} icon={<UserOutlined />} />
              </Dropdown> */}
              </div>
            </Header>
            <Content
              style={{
                padding: '1rem',
                overflow: 'auto',
              }}
            >
              <div
                style={
                  {
                    // display: "flex",
                  }
                }
              >
                <div
                  style={{
                    flex: 1,
                    width:
                      optionStore.options.openTaskInDrawer &&
                        optionStore.taskOpen
                        ? 'calc(100% + 900px)'
                        : '100%',
                  }}
                >
                  <Outlet />
                </div>
                {/* {
                optionStore.options.openTaskInDrawer && optionStore.taskOpen && (
                  <div
                    style={{
                      width: 810,
                      flexShrink: 0 // Ensures it doesn’t shrink below its width
                    }}
                  />
                )
              } */}
              </div>
            </Content>
          </Layout>
        </Layout>

        {showCreateTaskGlobal && (
          <CreateTaskModalGlobal
            defaultProjectKey={projectKey ?? ''}
            setShowNewTaskForm={setShowCreateTaskGlobal}
            showNewTaskForm={showCreateTaskGlobal}
            onCreateTask={() => { }}
          />
        )}

        {searchModalOpen && (
          <SearchModal
            searchModalOpen={searchModalOpen}
            setSearchModalOpen={setSearchModalOpen}
            setShowCreateTaskGlobal={setShowCreateTaskGlobal}
          />
        )}

        {optionStore.options.openTaskInDrawer && taskKey && (
          <Drawer
            width={'67vw'}
            zIndex={9999}
            open={showTaskDetailsModal}
            destroyOnClose
            style={{ zIndex: 9999 }}
            mask={false}
            getContainer={false}
            onClose={() => {
              setShowTaskDetailsModal(false);
              optionStore.setTaskOpen(false);
              setSearchParams();
            }}
          >
            <TaskDetailsMain taskKey={taskKey} onUpdateTask={() => { }} />
          </Drawer>
        )}

        {showTaskDetailsModal && !optionStore.options.openTaskInDrawer && (
          <TaskDetailsModal2
            taskKey={taskKey}
            showTaskDetailsModal={showTaskDetailsModal}
            setShowTaskDetailsModal={setShowTaskDetailsModal}
            onUpdateTask={async () => { }}
            onCancel={() => {
              setShowTaskDetailsModal(false);
              setSearchParams();
            }}
          />
        )}

        {userSettingsModalOpen && (
          <Modal
            style={{ top: 20 }}
            open={userSettingsModalOpen}
            onCancel={() => setUserSettingsModalOpen(false)}
            destroyOnClose
            width={isMobile ? '100vw' : '50vw'}
            footer={null}
          >
            <AccountSettings />
          </Modal>
        )}
        {showLevelInfoModal && (
          <Modal
            style={{ top: 20 }}
            open={showLevelInfoModal}
            onCancel={() => setShowLevelInfoModal(false)}
            destroyOnClose
            width={'50vw'}
            footer={null}
          >
            <LevelMap />
          </Modal>
        )}
        {
          <Modal
            open={sm.showModal}
            zIndex={9999}
            onCancel={() => {
              sm.setShowModal(false)
            }}
            onClose={() => {
              sm.setShowModal(false);
            }}
            onOk={() => {
              sm.setShowModal(false);
              navigate('/admin?tab=plan');
            }}
            okText="Yes I'm ready, Upgrade"
            cancelText="No, I'm not ready"
            cancelButtonProps={{ danger: true }}
          >
            <div>
              <h2>Upgrade Required - Feature limit reached</h2>

              {
                sm.message && <Card style={{ textAlign: 'center', marginBottom: ".5rem" }} variant='borderless'>

                  <Text type='warning'>
                    {sm.message}
                  </Text>


                </Card>
              }

              <Text>You are missing out on great features. Don't miss out.</Text>
              <br />
              <br />
              <Text>
                To continue using this feature, please upgrade to our Standard or Pro tier.
              </Text>
              <br />
              <br />
              <Text strong>Are your ready for some real work?</Text>
            </div>
          </Modal>
        }
      </App>
    </ConfigProvider>
  );
};

export default Wrapper2;
