import { api } from '@/api';
import Editor from '@/components/Editor/index3';
import { mutateContent } from '@/utils/uploadUtils';
import {
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Modal,
  Select,
  Slider,
  Table,
  message,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import dayjs from 'dayjs';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';

export default function CreateTaskModal({
  showNewTaskForm,
  setShowNewTaskForm,
  onCreateTask,
  defaultColumnId,
}: {
  showNewTaskForm: boolean;
  setShowNewTaskForm: (show: boolean) => void;
  onCreateTask: (task: any) => void;
  defaultColumnId: string;
}) {
  const [messageApi, contextHolder] = message.useMessage();

  const { projectKey, boardKey } = useParams<any>();

  const [loadingCreateTask, setLoadingCreateTask] = useState(false);

  const [newTaskColumnId, setNewTaskColumnId] = useState(defaultColumnId);
  const [newTaskLabel, setNewTaskLabel] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskAssignee, setNewTaskAssignee] = useState('');
  const [newTaskTypeId, setNewTaskTypeId] = useState('');
  const [newTaskPriorityId, setNewTaskPriorityId] = useState(
    'tdkcw9in2xgbq1lrpd4phsmk'
  );
  const [newTaskQuest, setNewTaskQuest] = useState('NONE');
  const [newTaskPoints, setNewTaskPoints] = useState(50);
  const [dueDate, setDueDate]: any = useState(null);

  const [createMultiple, setCreateMultiple] = useState(false);
  const [keepStatus, setKeepStatus] = useState(true);
  const [keepType, setKeepType] = useState(true);
  const [keepAssignee, setKeepAssignee] = useState(true);
  const [keepLabel, setKeepLabel] = useState(true);
  const [keepDescription, setKeepDescription] = useState(true);
  const [keepPriority, setKeepPriority] = useState(true);
  const [keepQuest, setKeepQuest] = useState(true);
  const [keepDueDate, setKeepDueDate] = useState(true);

  const [columns, setColumns] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [taskTypes, setTaskTypes] = useState<any[]>([]);
  const [priorities, setPriorities] = useState<any[]>([]);
  const [quests, setQuests] = useState<any[]>([]);

  const [recentlyCreatedTasks, setRecentlyCreatedTasks] = useState<any>([]);

  async function getPriorities() {
    const response = await api.get(`/api/priority`);
    setPriorities(response.data);
  }

  async function getColumns() {
    const response = await api.get(
      `/api/projects/${projectKey}/boards/${boardKey}/columns?withTasks=false`
    );

    if (!defaultColumnId) {
      setNewTaskColumnId(response.data[0].id);
    }

    setColumns([
      ...response.data.map((column: any) => ({
        id: column.id,
        label: column.label,
      })),
    ]);
  }

  async function getUsers() {
    const response = await api.get(`/api/projects/${projectKey}/users`);
    setNewTaskAssignee('no-user');
    setUsers([
      { id: 'no-user', label: 'No Assignee' },
      ...response.data.map((user: any) => ({
        id: user.id,
        label: user.fullName,
      })),
    ]);
  }

  async function getQuests() {
    const response = await api.get(
      `/api/projects/${projectKey}/quests?filter=all`
    );

    setQuests([
      { id: 'NONE', label: 'No Quest Selected' },
      ...response.data.map((quest: any) => ({
        id: quest.id,
        label: quest.label,
      })),
    ]);
  }

  async function getTaskTypes() {
    const response = await api.get(`/api/task-types`);
    setNewTaskTypeId(response.data[0].id);
    setTaskTypes([
      ...response.data.map((taskType: any) => ({
        id: taskType.id,
        label: taskType.label,
        description: taskType.description,
      })),
    ]);
  }

  useEffect(() => {
    getTaskTypes();
    getPriorities();
    getUsers();
    getColumns();
    getQuests();
  }, []);

  const labelRef: any = useRef(null);

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.focus();
    }
  }, []);

  const createTask = async () => {
    setLoadingCreateTask(true);
    try {
      const response = await api.post(`/api/columns/${newTaskColumnId}/tasks`, {
        label: newTaskLabel,
        description: await mutateContent(newTaskDescription),
        columnId: newTaskColumnId,
        assigneeId: newTaskAssignee === 'no-user' ? null : newTaskAssignee,
        taskTypeId: newTaskTypeId,
        priorityId: newTaskPriorityId,
        parentId: null,
        questId: newTaskQuest !== 'NONE' ? newTaskQuest : null,
        dueDate: dueDate,
      });

      if (createMultiple) {
        if (!keepStatus) setNewTaskColumnId('');
        if (!keepType) setNewTaskTypeId('');
        if (!keepAssignee) setNewTaskAssignee('');
        if (!keepLabel) setNewTaskLabel('');
        if (!keepDescription) setNewTaskDescription('');
        if (!keepPriority) setNewTaskPriorityId('tdkcw9in2xgbq1lrpd4phsmk');
        if (!keepQuest) setNewTaskQuest('NONE');
        if (!keepDueDate) setDueDate(null);

        const task = {
          key: response.data,
          label: newTaskLabel,
          status: columns.find((column) => column.id === newTaskColumnId).label,
          priority: priorities.find(
            (priority) => priority.id === newTaskPriorityId
          ).label,
          taskTypeLabel: taskTypes.find(
            (taskType) => taskType.id === newTaskTypeId
          ).label,
          assigneeFullName:
            newTaskAssignee === 'no-user'
              ? 'No Assignee'
              : users.find((user) => user.id === newTaskAssignee).label,
          dueDate,
        };
        setRecentlyCreatedTasks([task, ...recentlyCreatedTasks]);
      } else {
        setNewTaskLabel('');
        setNewTaskColumnId('');
        setNewTaskAssignee('');
        setNewTaskTypeId('');
        setNewTaskDescription('');
        setNewTaskQuest('NONE');
        setShowNewTaskForm(false);
      }
    } catch (error: any) {
      if (error.response.data.message) {
        messageApi.error(error.response.data.message);
        setLoadingCreateTask(false);
        return;
      }
      messageApi.error('Failed to create task');
      setLoadingCreateTask(false);
      return;
    }

    messageApi.success('Task Created');
    onCreateTask({});
    setLoadingCreateTask(false);
    if (createMultiple) {
      setTimeout(() => {
        labelRef.current.focus();
      }, 10);
    }
  };

  const inputVariant = 'filled';
  const size = 'small';

  return (
    <Modal
      open={showNewTaskForm}
      title='Create Task'
      width={'75vw'}
      style={{ top: 20 }}
      okButtonProps={{
        disabled: loadingCreateTask,
        loading: loadingCreateTask,
      }}
      onCancel={() => {
        setShowNewTaskForm(false);
      }}
      onOk={async () => {
        createTask();
      }}
      okText='Create'
    >
      <div>
        {contextHolder}
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div>
            <p>Status</p>

            <Select
              title='Status'
              placeholder='Status'
              style={{ width: '100%', minWidth: 150 }}
              size={size}
              variant={inputVariant}
              value={newTaskColumnId}
              onChange={(value) => setNewTaskColumnId(value)}
            >
              {columns.map((column) => (
                <Select.Option key={column.id} value={column.id}>
                  {column.label}
                </Select.Option>
              ))}
            </Select>
            <div>
              {createMultiple && (
                <Checkbox
                  title='Keep'
                  checked={keepStatus}
                  defaultChecked={keepStatus}
                  onChange={(e) => {
                    setKeepStatus(e.target.checked);
                  }}
                >
                  Keep for next task
                </Checkbox>
              )}
            </div>
          </div>

          <div>
            <p>Type</p>
            <Select
              placeholder='Type'
              style={{ width: '100%', minWidth: 150 }}
              variant={inputVariant}
              value={newTaskTypeId}
              size={size}
              onChange={(value) => setNewTaskTypeId(value)}
              labelRender={(option: any) => (
                <>
                  {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                  {option.label}
                </>
              )}
              optionRender={(option: any) => (
                <div style={{ display: 'flex', gap: '.3rem' }}>
                  {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                  {option.label}
                </div>
              )}
              options={taskTypes.map((taskType) => ({
                value: taskType.id,
                label: taskType.label,
                title: taskType.description,
              }))}
            />
            <div>
              {createMultiple && (
                <Checkbox
                  title='Keep'
                  checked={keepType}
                  defaultChecked={keepType}
                  onChange={(e) => {
                    setKeepType(e.target.checked);
                  }}
                >
                  Keep for next task
                </Checkbox>
              )}
            </div>
          </div>

          <div>
            <p>Priority</p>
            <Select
              placeholder='Priority'
              style={{ width: '100%', minWidth: 150 }}
              value={newTaskPriorityId}
              variant={inputVariant}
              size={size}
              onChange={(value) => setNewTaskPriorityId(value)}
              labelRender={(value: any) => {
                return (
                  <>
                    {getTaskPriorityIcon(value.label?.toLowerCase() as any)}{' '}
                    {value.label}
                  </>
                );
              }}
              optionRender={(option: any) => (
                <div style={{ display: 'flex', gap: '.3rem' }}>
                  {getTaskPriorityIcon(option.label?.toLowerCase() as any)}{' '}
                  {option.label}
                </div>
              )}
              options={priorities.map((priority) => ({
                value: priority.id,
                label: priority.label,
              }))}
            />
            <div>
              {createMultiple && (
                <Checkbox
                  title='Keep'
                  checked={keepPriority}
                  defaultChecked={keepPriority}
                  onChange={(e) => {
                    setKeepPriority(e.target.checked);
                  }}
                >
                  Keep for next task
                </Checkbox>
              )}
            </div>
          </div>

          <div>
            <p>Quest</p>
            <Select
              placeholder='Quest'
              style={{ width: '100%', minWidth: 150 }}
              value={newTaskQuest}
              onChange={(value) => setNewTaskQuest(value)}
              variant={inputVariant}
              size={size}
              showSearch
              filterOption={(input, option: any) => {
                return (
                  option?.label
                    .toLowerCase()
                    .indexOf(input.toLowerCase().trim()) >= 0
                );
              }}
              options={quests.map((quest) => ({
                value: quest.id,
                label: quest.label,
              }))}
            />
            <div>
              {createMultiple && (
                <Checkbox
                  title='Keep'
                  checked={keepQuest}
                  defaultChecked={keepQuest}
                  onChange={(e) => {
                    setKeepQuest(e.target.checked);
                  }}
                >
                  Keep for next task
                </Checkbox>
              )}
            </div>
          </div>

          <div>
            <p>Assignee</p>

            <Select
              placeholder=' Assignee'
              style={{ width: '100%', minWidth: 150 }}
              value={newTaskAssignee}
              variant={inputVariant}
              showSearch
              size={size}
              filterOption={(input, option: any) => {
                return (
                  option?.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase().trim()) >= 0
                );
              }}
              onChange={(value) => setNewTaskAssignee(value)}
            >
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.label}
                </Select.Option>
              ))}
            </Select>
            <div>
              {createMultiple && (
                <Checkbox
                  title='Keep'
                  checked={keepAssignee}
                  defaultChecked={keepAssignee}
                  onChange={(e) => {
                    setKeepAssignee(e.target.checked);
                  }}
                >
                  Keep for next task
                </Checkbox>
              )}
            </div>
          </div>

          <div>
            <p>Due Date</p>
            <div>
              <DatePicker
                style={{ width: '100%' }}
                variant={inputVariant}
                size={size}
                format={'DD MMM YYYY'}
                value={dueDate}
                onChange={(value) => setDueDate(value)}
                showNow={false}
                presets={[
                  {
                    label: 'Today',
                    value: dayjs(),
                  },
                  {
                    label: 'Tomorrow',
                    value: dayjs().add(1, 'day'),
                  },
                  {
                    label: 'Next week',
                    value: dayjs().add(1, 'week'),
                  },
                  {
                    label: '2 weeks',
                    value: dayjs().add(2, 'week'),
                  },
                  {
                    label: '1 month',
                    value: dayjs().add(1, 'month'),
                  },
                ]}
              />
              {dueDate && (
                // show relative time
                <Text type='secondary'>
                  Due in{' '}
                  {dayjs(dueDate.endOf('day')).diff(dayjs(), 'day') > 0
                    ? dayjs(dueDate.endOf('day')).diff(dayjs(), 'day') + ' days'
                    : 'Today'}
                </Text>
              )}
              <div>
                {createMultiple && (
                  <Checkbox
                    title='Keep'
                    checked={keepDueDate}
                    defaultChecked={keepDueDate}
                    onChange={(e) => {
                      setKeepDueDate(e.target.checked);
                    }}
                  >
                    Keep for next task
                  </Checkbox>
                )}
              </div>
            </div>
          </div>
        </div>

        <p>Label</p>

        <Input
          placeholder=' Label'
          ref={labelRef}
          value={newTaskLabel}
          variant={inputVariant}
          disabled={loadingCreateTask}
          onChange={(e) => setNewTaskLabel(e.target.value)}
          onPressEnter={async () => {
            createTask();
          }}
        />
        <div>
          {createMultiple && (
            <Checkbox
              title='Keep'
              checked={keepLabel}
              defaultChecked={keepLabel}
              onChange={(e) => {
                setKeepLabel(e.target.checked);
              }}
            >
              Keep for next task
            </Checkbox>
          )}
        </div>
        <br />
        {/* <div>
          <p>Complexity / Points</p>
          <div style={{ width: 700, marginLeft: '.8rem' }}>
            <Slider
              value={newTaskPoints}
              onChange={(value) => setNewTaskPoints(value)}
              max={150}
              step={10}
              marks={{
                0: 'None',
                20: 'Easy',
                50: 'Normal',
                100: 'Hard',
                150: 'Expert',
              }}
            />
          </div>
        </div> */}

        <Card
          variant='borderless'
        >
          <Text>Description</Text>
          <Divider
            style={{
              margin: '0.5rem 0',
            }}
          />
          {/* <EditorComponent
          defaultContent={newTaskDescription}
          onChange={setNewTaskDescription}
        /> */}

          {/* <Editor2 onChange={setNewTaskDescription} /> */}

          <Editor
            onChange={setNewTaskDescription}
            projectKey={projectKey}
            defaultContent={null}
          />

          <div>
            {createMultiple && (
              <Checkbox
                title='Keep'
                checked={keepDescription}
                defaultChecked={keepDescription}
                onChange={(e) => {
                  setKeepDescription(e.target.checked);
                }}
              >
                Keep for next task
              </Checkbox>
            )}
          </div>
        </Card>

        <p>Multiple</p>
        <div>
          <Checkbox
            checked={createMultiple}
            onChange={(e) => {
              setCreateMultiple(e.target.checked);
            }}
          >
            Create Multiple
          </Checkbox>
        </div>
        {createMultiple && (
          <Table
            dataSource={recentlyCreatedTasks}
            rowKey='id'
            pagination={false}
            style={{ marginTop: '1rem' }}
          >
            <Table.Column title='Key' dataIndex='key' />
            <Table.Column title='Label' dataIndex='label' />
            <Table.Column title='Status' dataIndex='status' />
            <Table.Column title='Type' dataIndex='taskTypeLabel' />
            <Table.Column title='Priority' dataIndex='priority' />
            <Table.Column title='Assignee' dataIndex='assigneeFullName' />
          </Table>
        )}
      </div>
    </Modal>
  );
}
