import { useEffect, useState } from 'react';
import DepartmentsTab from './Tabs/DepartmentsTab';
import InviteUsersTab from './Tabs/InviteUsersTab';
import PlanTab from './Tabs/Plan';
import SettingsTab from './Tabs/SettingsTab';
import UsersTab from './Tabs/UsersTab';
import { Tabs } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const availableTabs = ['users', 'departments', 'invites', 'settings', 'plan'];

export default function AdminPage() {
  const query = useLocation();

  const [selectedTab, setSelectedTab] = useState('users');

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(query.search);
    const tab = params.get('tab');

    if (tab && availableTabs.includes(tab)) {
      setSelectedTab(tab);
    }
  }, [query]);

  return (
    <Tabs
      style={{ margin: '.5rem' }}
      type="card"
      defaultActiveKey='1'
      destroyInactiveTabPane
      activeKey={selectedTab}
      onChange={(key) => {
        setSelectedTab(key);
        navigate(`?tab=${key}`);
      }}
      size={'middle'}
      items={[
        {
          label: `Users`,
          key: 'users',
          children: <UsersTab />,
        },
        {
          label: `Teams`,
          key: 'team',
          children: <DepartmentsTab />,
        },
        {
          label: `Invite Users`,
          key: 'invites',
          children: <InviteUsersTab />,
        },
        {
          label: `Settings`,
          key: 'settings',
          children: <SettingsTab />,
        },
        {
          label: `Plan`,
          key: 'plan',
          children: <PlanTab />,
        },
      ]}
    />
  );
}
