import { Button, Card, Input, Modal, Select, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useState } from 'react';
import { api } from '../../../../../../api';
import Automations from './automations';
import useMessage from 'antd/es/message/useMessage';
import { COLUMN_TYPES } from '@/constants/columnType';

export default function EditColumnModal(props: {
  column: any;
  onClose: any;
  showNewColumnForm: any;
  setShowNewColumnForm: any;
  refetch: any;
  showOnlyAutomations?: boolean;
}) {
  const {
    column,
    onClose,
    showNewColumnForm,
    setShowNewColumnForm,
    refetch,
    showOnlyAutomations,
  } = props;

  const [title, setTitle] = useState(column.label);
  const [columnType, setColumnType] = useState(column.typeId);

  const [message, contextHolder] = useMessage();

  return (
    <Modal
      open={showNewColumnForm}
      style={{ top: 20 }}
      width={'40vw'}
      onCancel={() => {
        setShowNewColumnForm(false);
      }}
      footer={null}
    // onOk={async () => {
    //   try {
    //     await api.put(`/api/columns/${column.id}`, {
    //       label: title,
    //       categoryId: columnType,
    //     });
    //     await refetch();
    //     setTitle('');
    //     setShowNewColumnForm(false);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }}
    // okText='Update'
    >
      {showOnlyAutomations ? null : (
        <>
          <div>
            {contextHolder}
            <Title level={4}>Update "{column.label}" Column</Title>
            <Text>Label</Text>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            <br />
            <br />
            <Text>Type</Text>
            <Select
              style={{ width: '100%' }}
              defaultValue={
                columnType === 'b7p0jiayd4tjnp5zkw5qo06w' ? 'Done' : columnType
              }
              options={
                COLUMN_TYPES.map((type) => ({
                  label: type.label,
                  description: type.description,
                  value: type.id,
                }))
              }
              placeholder='Select a category'
              optionRender={(option) => (
                <div>
                  <Text strong>{option.label}</Text>
                  <br />
                  <Text type='secondary'>{option.data.description}</Text>
                </div>
              )}
              onChange={(value) => {
                setColumnType(value);
              }}
            />
          </div>
          <br />
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button
                type='primary'
                onClick={async () => {
                  try {
                    await api.put(`/api/columns/${column.id}`, {
                      label: title,
                      categoryId: columnType,
                    });
                    await refetch();
                    message.success('Column updated');
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Update
              </Button>
            </Space>
          </div>
        </>
      )}
      <br />
      <Automations columnId={column.id} columnType={columnType} showOnlyAutomations={showOnlyAutomations} />
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={() => setShowNewColumnForm(false)}>Close</Button>
      </div>
    </Modal>
  );
}
