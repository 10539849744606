import { TimeUnit } from './types';

// Time unit width settings
export const TIME_UNIT_WIDTH: Record<TimeUnit, number> = {
  day: 25,
  week: 15,
  month: 5,
  quarter: 2,
  year: 1,
};

// Column widths
export const COLUMN_WIDTHS = {
  QUEST_NAME: 140,
  DUE_IN: 60,
  PROGRESS: 120,
  EXTRA_COLUMN_WIDTH: 180, // Additional width for table
};

// Colors
export const COLORS = {
  TODAY_MARKER: 'darkred',
  PROGRESS_BAR: '#1890ff',
  TASK_BAR_BACKGROUND: 'gray',
};

// UI/UX constants
export const UI = {
  ROW_HEIGHT: 40,
  TASK_BAR_HEIGHT: 25,
  VISIBLE_VIEWPORT_HEIGHT: '65vh',
  TOOLTIP_THRESHOLD_WIDTH: 30, // Minimum width to show text in task bar
};
